<template>
  <div class="articles">
    <div v-if="mode != 'createModif'" class="articles-liste">
      <div class="titreEcran">Liste des Articles</div>
      <div class="btn-liste">
        <!-- ***********************    bouton d'action de la liste des articles   *******************************  -->
        <div class="btn-horiz" @click="ajouterInfo">
          <img src="/assets/img/ajouter.png" alt="" /><br />
          <p>Créer une entrée</p>
        </div>
        <div class="btn-horiz" @click="ouvreRecherche">
          <img src="/assets/img/rechercher.png" alt="" /><br />
          <p>Recherche</p>
        </div>
        <div class="btn-horiz" @click="ChargeListAll">
          <img src="/assets/img/actualiser.gif" alt="" /><br />
          <p>Actualiser</p>
        </div>
        <!-- ***********************    div de recherche   *******************************  -->
        <div v-if="affRecherche == true" class="recherche">
          <table>
            <tr>
              <td>Raison sociale</td>
              <td><input type="text" name="rechRS" v-model="rechRS" /></td>
            </tr>
            <tr>
              <td>Code postal</td>
              <td><input type="text" name="rechCP" v-model="rechCP" /></td>
            </tr>
            <tr>
              <td>Activité</td>
              <td><input type="text" name="rechACT" v-model="rechACT" /></td>
            </tr>
            <tr>
              <td></td>
              <td
                style="text-align: right; cursor: pointer"
                @click="lanceRecherche"
              >
                <u>Rechercher</u>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="encharge" v-if="enCharge == true"></div>
      <!-- ***********************    Liste des articles   *******************************  -->
      <div
        v-for="article in lstarticles"
        :key="article.id"
        class="articles-card"
        v-bind="{ id: article.id }"
        @click="Chargearticles(article.id)"
      >
        <div class="articles-titre">
          <div style="width: 30%">{{ article.designation }}</div>
          <div class="d1" style="font-size: 1em; font-weight: 400; width: 45%">
            <table style="width: 98%">
              <tr>
                <td style="width: 34%">
                  Référence: <b>{{ article.reference }}</b> {{ article.id}}
                </td>

                <td style="width: 33%">
                  Code: <b>{{ article.code }}</b>
                </td>

                <td style="width: 33%">
                  Prix Unitaire: <b>{{ article.pu }}</b>
                </td>

                <!--<td style="color: blue; cursor:pointer;text-align:right"
                @click="agrandiListe(article.id)"
                >Plus d'informations...</td>-->
              </tr>
            </table>
          </div>
          <div class="articles-titre" style="width: 50px">
            <div class="tab_img" @click="modifArticle(article.id)">
              <img src="/assets/img/modifier.gif" alt="" />
            </div>
            <div class="tab_img">
              <img
                src="/assets/img/supprimer.png"
                @click="affSupp(article.id)"
                alt=""
              />
            </div>
          </div>
          <div class="tab_img" @click="agrandiListe(article.id)">
            <img src="/assets/img/fleches1.gif" alt="" />
          </div>
        </div>
        <div class="alert" v-if="article.id == delIndex">
          <span
            >Vous allez supprimer l'article sélectionné. Voulez vous continuer
            ?</span
          >
          <div>
            <button
              class="btnAlert"
              @click="
                () => {
                  delIndex = -1;
                }
              "
            >
              Annuler
            </button>
            <button class="btnAlert" @click="deleteArticle(article.id)">
              Confirmer
            </button>
          </div>
        </div>
        <div class="articles-card-detail">
          <div class="d2">
            <table style="width: 90%">
              <tr>
                <td>
                  Unité: <b>{{ article.unite }}</b>
                </td>

                <td>
                  Longueur: <b>{{ article.longueur }}</b>
                </td>

                <td>
                  Largeur: <b>{{ article.largeur }}</b>
                </td>

                <td>
                  Hauteur: <b>{{ article.hauteur }}</b>
                </td>

                <td>
                  Poids: <b>{{ article.poids }}</b>
                </td>
              </tr>
            </table>
          </div>
          <div class="d1">
            <table style="width: 90%">
              <tr>
                <td>Commentaire:</td>
              </tr>
              <tr>
                <td>
                  <div style="white-space: pre">
                    <b>{{ article.commentaire }}</b>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <!--
          <div class="d3">
            {{ client.commentaire }}
          </div>
          -->
        </div>
      </div>
    </div>

    <div v-if="mode == 'createModif'" class="articles-createmodif">
      <!-- ***********************    Div de saisie   *******************************  -->
      <div class="champtxt">
        <label for="catalogue">catalogue</label><br />

        <select
          name="catalogue"
          id="catalogue1"
          v-model="typeinfo"
          style="display: inline-block"
        >
          <option v-for="cata in lstCata" :key="cata.designation">
            {{ cata.designation }}
          </option>
        </select>
        <div class="petit-btn" @click="affCata">
          <img src="/assets/img/ajouter.png" alt="" /><br />
        </div>
      </div>
      <div class="cata1" v-if="createCata == true">
        <div class="champtxt">
          <label for="cata1">Nouveau catalogue </label>
          <input
            type="text"
            name="cata1"
            v-model="cataAjout"
            style="width: 70%"
          />
        </div>
        <div class="petit-btn" @click="ajouteCata">
          <img src="/assets/img/valider.png" alt="" />
        </div>
        <div class="petit-btn" @click="affCata">
          <img src="/assets/img/annuler.png" alt="" />
        </div>
      </div>
      <div class="champtxt">
        <label for="reference">Reference</label><br />
        <input type="text" name="reference" v-model="ref" />
      </div>
      <div class="champtxt">
        <label for="designation">Designation</label><br />
        <input type="text" name="designation" v-model="des" />
      </div>
      <div class="champtxt">
        <label for="code">Code</label><br />
        <input type="text" name="code" v-model="code" />
      </div>
      <div class="champtxt">
        <label for="pu">Prix Unitaire</label><br />
        <input type="text" name="pu" style="width: 30%" v-model="pu" />
      </div>
      <div class="champtxt">
        <label for="longueur">Unité</label><br />
        <input type="text" name="longueur" style="width: 30%" v-model="unite" />
      </div>
      <div class="champtxt">
        <label for="longueur">Longueur</label><br />
        <input type="text" name="longueur" style="width: 30%" v-model="lng" />
      </div>
      <div class="champtxt">
        <label for="largeur">Largeur</label><br />
        <input type="text" name="largeur" style="width: 30%" v-model="larg" />
      </div>
      <div class="champtxt">
        <label for="hauteur">Hauteur</label><br />
        <input type="text" name="hauteur" style="width: 30%" v-model="haut" />
      </div>
      <div class="champtxt">
        <label for="poids">Poids</label><br />
        <input type="text" name="poids" style="width: 30%" v-model="pds" />
      </div>

      <div class="champtxt">
        <label for="commentaire">Commentaire</label><br />
        <textarea name="commentaire" rows="7" v-model="commentaire"></textarea>
      </div>
      <div class="flex-align-horiz">
        <div class="btn-horiz" @click="createModifArticle">
          <img src="/assets/img/valider.png" alt="" /><br />
          <p>Enregistrer</p>
        </div>
        <div class="btn-horiz" @click="annuleModifArticle">
          <img src="/assets/img/annuler.png" alt="" /><br />
          <p>Annuler</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment, { now } from "moment";
import VueCookies from "vue-cookies";

export default {
  name: "articlesCom",
  data() {
    return {
      lstarticles: [],
      lstCata: [],
      des: "",
      ref: "",
      code: "",
      pu: 0,
      numcat: 0,
      unite: "",
      lng: 0,
      larg: 0,
      haut: 0,
      pds: 0,
      commentaire: "",
      mode: "lst",
      btnVisible: false,
      action: "ajout",
      createCata: false,
      cataAjout: "",
      idArticle: 0,
      codeEntreprise: "",
      msgAlert: "",
      action: {
        nom: "",
        id: 0,
      },
      delIndex: -1,
      enCharge:false,
    };
  },
  methods: {
    ChargeListAll() {
      // console.log(this.$store.state.server)
      console.log("CE: " + this.codeEntreprise);
      this.enCharge = true
      axios({
        method: "post",
        url: this.$store.state.server + "/api/articles/listAll",
        data: { codeEntreprise: this.codeEntreprise },
        headers: { authorization: VueCookies.get('jwtAtomGC')}
      })
        .then((reponse) => {
          console.log(reponse);
          this.lstarticles = reponse.data.articles;
          //this.lstCient = reponse.data.clients;
          this.lstCata = reponse.data.cata;
          //this.lstRech = reponse.data;
          console.log(this.lstarticles);
          console.log(this.lstTypeInfo);
          this.enCharge=false
        })
        .catch((erreur) => {
          console.log(erreur);
          this.enCharge=false
        });
    },
    FormatDate(d1) {
      if (d1 != "" && d1 != undefined) {
        let D = new Date(d1);
        D = D.toLocaleDateString();
        return D;
      } else {
        return "";
      }
    },
    FormatDateAmericain(d1) {
      if (d1 != "" && d1 != undefined) {
        let D = moment().format(d1, "YYYY/MM/DD");
        D = D.split("T")[0];
        return D;
      } else {
        return "";
      }
    },
    GereRetourChariot(txt, sens) {
      console.log(txt);
      if (txt == "" || txt == undefined) {
        return "";
      }
      if (sens == 1) {
        txt = txt.replace("<br>", /(\r\n)/g);
      } else {
        txt = txt.replace(/(\r\n|\n|\r)/g, "<br />");
      }
      console.log(txt);
      return txt;
    },
    Chargearticles(id) {
      console.log("charge art:" + id);
      if (id != 0 && id != undefined) {
        for (const info of this.lstarticles) {
          if (info.id == id) {
            this.idArticle = id;
            this.des = info.designation;
            this.ref = info.reference;
            this.code = info.code;
            this.pu = info.pu;
            this.numcat = info.numcat;
            this.unite = info.unite;
            this.lng = info.longueur;
            this.larg = info.largeur;
            this.haut = info.hauteur;
            this.pds = info.poids;
            this.commentaire = info.commentaire;
          }
        }
        this.btnVisible = true;
        //this.surbrillanceListe(id);
      }
    },
    surbrillanceListe(id) {
      let ListeDivarticles = document.querySelectorAll(".articles-card");
      //console.log(ListeDivClient)
      for (let info of ListeDivarticles) {
        const tmpId = info.attributes[1].nodeValue;

        if (id == tmpId) {
          info.style.border = "2px solid black";
          info.style.backgroundColor = "Yellow";
        } else {
          info.style.border = "1px solid black";
          info.style.backgroundColor = "Azure";
        }
      }
    },
    agrandiListe(id) {
      let ListeDivarticles = document.querySelectorAll(".articles-card");

      for (let art of ListeDivarticles) {
        const tmpId = art.attributes[1].nodeValue;

        if (id == tmpId) {
          const hauteur = getComputedStyle(art, null).maxHeight;

          if (hauteur == "70px" || this.delIndex != -1) {
            art.style.maxHeight = "1000px";
          } else {
            art.style.maxHeight = "70px";
          }
        }
      }
    },
    modifArticle(id) {
      this.Chargearticles(id);
      this.mode = "createModif";
      this.action = "modif";
      this.idArticle = id;
    },
    annuleModifArticle() {
      this.mode = "lst";
    },
    ajouterInfo() {
      this.des = "";
      this.ref = "";
      this.code = "";
      this.pu = 0;
      this.numcat = 0;
      this.lng = 0;
      this.larg = 0;
      this.haut = 0;
      this.pds = 0;
      this.commentaire = "";
      this.idArticle = 0;
      this.action = "ajout";
      this.mode = "createModif";
      this.btnVisible = true;
    },
    createModifArticle() {
      if (this.action == "ajout") {
        let di1 = this.FormatDateAmericain(new Date().now);
        console.log("daa  " + di1);
        axios({
          method: "post",
          url: this.$store.state.server + "/api/articles/addArticle",
          data: {
            des: this.des,
            ref: this.ref,
            code: this.code,
            pu: this.pu,
            numcat: this.numcat,
            unite: this.unite,
            lng: this.lng,
            larg: this.larg,
            haut: this.haut,
            pds: this.pds,
            comment: this.commentaire,
            codeEntreprise: this.codeEntreprise,
          },
          headers: { authorization: VueCookies.get('jwtAtomGC')}
        })
          .then((reponse) => {
            console.log(reponse);
            this.mode = "lst";
            this.btnVisible = false;
          })
          .catch((erreur) => {
            console.log(erreur);
          });
        this.mode = "lst";
      }
      if (this.action == "modif") {
        axios({
          method: "post",
          url: this.$store.state.server + "/api/articles/modifArticle",
          data: {
            des: this.des,
            ref: this.ref,
            code: this.code,
            pu: this.pu,
            numcat: this.numcat,
            unite: this.unite,
            lng: this.lng,
            larg: this.larg,
            haut: this.haut,
            pds: this.pds,
            comment: this.commentaire,
            id: this.idArticle,
            codeEntreprise: this.codeEntreprise,
          },
          headers: { authorization: VueCookies.get('jwtAtomGC')}
        })
          .then((reponse) => {
            console.log(reponse);
            let i = 0;
            for (let art of this.lstarticles) {
              if (art.id == this.idArticle) {
                this.lstarticles[i].designation = this.des;
                this.lstarticles[i].reference = this.ref;
                this.lstarticles[i].code = this.code;
                this.lstarticles[i].pu = this.pu;
                this.lstarticles[i].unite = this.unite;
                this.lstarticles[i].nomclient = this.numcat;
                this.lstarticles[i].longueur = this.lng;
                this.lstarticles[i].largeur = this.larg;
                this.lstarticles[i].hauteur = this.haut;
                this.lstarticles[i].poids = this.pds;
                this.lstarticles[i].commentaire = this.commentaire;
                this.mode = "lst";
                return;
              }
              i++;
            }
            this.mode = "lst";
          })
          .catch((erreur) => {
            console.log(erreur);
          });
      }
    },
    affSupp(id) {
      this.agrandiListe(id)
      this.delIndex = id
    },
    deleteArticle(id) {
      if (id == 0 || id == undefined) {
        this.action.id = "";
        this.msgAlert = "Vous devez sélectionner une fiche à supprimer.";
        return;
      }

      console.log(id);
        axios({
          method: "post",
          url: this.$store.state.server + "/api/articles/deleteArticle",
          data: { id: id },
          headers: { authorization: VueCookies.get('jwtAtomGC')}
        })
          .then((reponse) => {
            console.log(reponse);
            this.mode = "lst";
            this.btnVisible = false;
          })
          .catch((erreur) => {
            console.log(erreur);
          });
    },
    affCata() {
      this.createCata == true
        ? (this.createCata = false)
        : (this.createCata = true);
      console.log(this.createCata);
    },
    ajouteCata() {
      if (this.cataAjout == "" || this.cataAjout == undefined) {
        this.action.id = "";
        this.msgAlert = "Le catalogue ne peut pas être vide.";

        return;
      }
      axios({
        method: "post",
        url: this.$store.state.server + "/api/articles/ajouteCata",
        data: { cata: this.cataAjout, codeEntreprise: this.codeEntreprise },
        headers: { authorization: VueCookies.get('jwtAtomGC')}
      })
        .then((reponse) => {
          console.log(reponse);

          var select = document.getElementById("catalogue1");
          select.options[select.options.length] = new Option(
            this.cataAjout,
            this.cataAjout
          );
          this.createCata = false;
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
  },
  async mounted() {
    this.codeEntreprise = await VueCookies.get("atom_code_entreprise");
    this.ChargeListAll();
    this.login = this.$store.state.login;
    this.iduser = this.$store.state.loginId;
    console.log(this.iduser);
  },
};
</script>

<style scoped lang="scss">
.articles {
  margin: 110px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 0 5px;
  background: url("/assets/fond/fond11.jpg");
  background-size: cover;
  

  .btn-liste {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .articles-liste {
    flex-basis: 100%;
    border: lightgray 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2px;
    overflow-y: scroll;
    max-height: calc(100vh - 118px);
    min-height: calc(100vh - 118px);

    .articles-card {
      margin: 10px 0 0 0;
      width: 95%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border: lightgray 1px solid;

      padding: 5px;
      background-color: rgba(247, 247, 247);
      transition: 0.2s ease-in;
      max-height: 70px;
      overflow: hidden;

      &:hover {
        background: rgb(253, 255, 158);
         /*background: linear-gradient(
          40deg,
          rgba(253, 255, 158, 1) 0%,
          rgba(255, 255, 255, 1) 50%,
          rgba(253, 255, 158, 1) 100%
        ); */
        box-shadow: 3px 3px 5px 0px #808080;
        scale: 1.01;
      }

      .artLstLimit {
        max-height: 25px;
      }

      .articles-titre {
        margin: 5px 0;
        font-size: 1.2em;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .articles-card-detail {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .d1 {
          width: 98%;
          margin: 5px 0 0 0;
          padding: 3px;
          border: 1px solid lightgray;
        }
        .d2 {
          width: 98%;
          margin: 30px 0 0 0;
          padding: 3px;
          border: 1px solid lightgray;
          td {
            width: 20%;
          }
        }
        .d3 {
          flex-basis: 33%;
        }
      }
    }
  }
  .articles-detail {
    flex-basis: 1%;

    .articles-titre {
      margin: 5px 0;
      font-size: 1.2em;
      font-weight: bold;
      text-decoration: underline;
    }

    .tableau2 {
      border: 1px solid gray;
      min-width: 50%;

      td {
        border-bottom: 1px solid lightgray;
      }
    }
  }

  .articles-createmodif {
    position: absolute;
    width: 60%;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: lightgray 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 7px;
    overflow-y: scroll;
    background-color: rgba(37, 37, 37, 0.85);
    z-index: 10;
  }

  .flex-align-horiz {
    display: flex;
    flex-direction: row;
    justify-content: space-start;
    align-items: center;
  }
}

.cata1 {
  width: 90%;
  margin: 5px 0 5px 5px;
  padding: 4x;
  border: 1px solid gray;
  display: inline;
}

.petit-btn {
  width: 25px;
  height: 25px;
  border: gray 1px solid;
  border-radius: 3px;
  background-color: azure;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3px 8px 0 3px;

  &:hover {
    background-color: rgb(200, 252, 252);
  }

  &:active {
    background-color: rgb(150, 252, 252);
  }

  img {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 1055px) {
  .articles {
    flex-direction: column;
  }
}
</style>

<template>
  <div class="container">
    <div class="cde">
      <div class="titre">
        Passage en commande des lignes du devis n° {{ idDevCde }}
      </div>
      <div class="champtxt">
        <label for="numCde">N° de la commande</label>
        <input
          type="text"
          name="numCde"
          v-model="numCde"
          title="Saisissez le n° de commande fourni par votre client. Ce n° doit être unique pour chaque client"
        />
      </div>
      <div class="champtxt">
        <label for="dateLiv">Date de livraison prévue</label><br />
        <input
          type="date"
          style="width: 150px"
          name="dateLiv"
          v-model="dateLiv"
          title="Date de livraison prévue pour la commande. Celle ci pourra être modifiée pour chaque ligne quand la commande sera créée"
        />
      </div>
    </div>

    <div class="alert" v-if="msgErr != ''" style="max-width: 350px">
      <span
        >{{ msgErr }}</span
      >
    </div>

    <div class="ligne-cde">
      <table class="tab1">
        <tr class="trEntete">
          <td>Désignation</td>
          <td>Référence</td>
          <td>Code</td>
          <td>Quantité</td>
          <td>Unité</td>
          <td>P.U.H.T</td>
        </tr>

        <tr v-for="ligne in lstLigneDevis" :key="ligne.id">
          <td>{{ ligne.designation }}</td>
          <td>{{ ligne.reference }}</td>
          <td>{{ ligne.code }}</td>
          <td>
            <input
              type="text"
              v-model="ligne.quantite"
              title="Quantité à commander. Pour ne pas commander la ligne, il faut mettre 0"
            />
          </td>
          <td>{{ ligne.unite }}</td>
          <td>{{ ligne.pu }}</td>
        </tr>
      </table>
    </div>
    <div class="enLigne" style="width: 80%">
      <div class="btn-horiz" @click="() => { confirmCreate = true }">
        <img src="/assets/img/valider.png" alt="" /><br />
        <p>Enregistrer</p>
      </div>
      <div class="btn-horiz" @click="retourDevis">
        <img src="/assets/img/annuler.png" alt="" /><br />
        <p>Annuler</p>
      </div>
      <div class="alert" v-if="confirmCreate == true">
          <span
            >Vous allez créer une nouvelle commande. Voulez-vous continuer ?</span
          >
          <div>
            <button
              class="btnAlert"
              @click="
                () => {
                  confirmCreate = false;
                }
              "
            >
              Annuler
            </button>
            <button class="btnAlert" @click="creeCde()">
              Confirmer
            </button>
          </div>
        </div>
        <div class="encharge" v-if="enCharge == true"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueCookies from "vue-cookies";
import * as FCT from "../js/fonction.js";

export default {
  name: "devEnCde",
  props: ["idDevis"],
  data() {
    return {
      idDevCde: 0,
      lstLigneDevis: [],
      infosDevis: [],
      numCde: "",
      dateLiv: "",
      login: "",
      iduser: 0,
      codeEntreprise: "",
      msgErr: '',
      confirmCreate: false,
      enCharge: false,
    };
  },
  methods: {
    listeLigneDevis() {
      axios({
        method: "post",
        url: this.$store.state.server + "/api/Commandes/devisEnCde",
        data: { id: this.idDevCde },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.infosDevis = reponse.data.infosdevis;
          this.lstLigneDevis = reponse.data.listelignes;
          this.nbLignes = this.lstLigneDevis.length;
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    retourDevis() {
      this.$emit("change-userpage", ["devis", this.idDevCde]);
    },
    async creeCde() {
      this.msgErr = ''
      let qte = 0;
      this.confirmCreate = false
      this.enCharge = true
      for (let li1 in this.lstLigneDevis) {
        console.log(this.lstLigneDevis[li1].quantite);
        qte += parseInt(this.lstLigneDevis[li1].quantite);
      }
      if (qte <= 0) {
        this.msgErr = "Vous devez avoir au moins 1 ligne avec une quantité > 0"
        this.enCharge=false
        return;
      }


      if (this.numCde == "" || this.numCde == undefined) {
        this.msgErr = "Le n° de commande est obligatoire"
        this.enCharge=false
        return
      }

      let nbNumCde = parseInt(
        await FCT.SelUneDonnee(
          "SELECT count(*) as result FROM commandes WHERE numcde = '" +
            this.numCde +
            "' AND idclient = " +
            this.infosDevis[0].idclient
        )
      );

      if (nbNumCde > 0) {
        this.msgErr =  "Ce n° de commande est déjà utilisé pour le client " +
            this.infosDevis[0].nomclient +
            ", création impossible."
            this.enCharge=false
        return
      }

        axios({
          method: "post",
          url: this.$store.state.server + "/api/Commandes/createCde",
          data: {
            idclient: this.infosDevis[0].idclient,
            nomclient: this.infosDevis[0].nomclient,
            commentaire: this.infosDevis[0].commentaire,
            iddevis: this.idDevCde,
            dateliv: this.dateLiv,
            numcde: this.numCde,
            iduser: this.iduser,
            usercde: this.login,
            lstLigne: this.lstLigneDevis,
            nbligne: this.lstLigneDevis.length,
            codeEntreprise: this.codeEntreprise,
          },
          headers: { authorization: VueCookies.get("jwtAtomGC") },
        })
          .then((reponse) => {
            console.log(reponse);
            const idcde = reponse.data.idcde;
            setTimeout(() => {
              this.$emit("change-userpage", ["commande", idcde]);
              this.enCharge=false
            }, 1000);
            
            
          })
          .catch((erreur) => {
            console.log(erreur);
          });
      
    },
  },
  async mounted() {
    this.codeEntreprise = await VueCookies.get("atom_code_entreprise");
    this.login = VueCookies.get("atom_user_login");
    this.iduser = VueCookies.get("atom_user_id");
    this.idDevCde = this.idDevis;
    this.listeLigneDevis();
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: url("/assets/fond/fond11.jpg");
  background-size: cover;
  margin: 110px 0 0 0;
  max-height: calc(100vh - 118px);
  min-height: calc(100vh - 118px);

  .cde {
    border: 1px solid gray;
    width: 80%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    background-color: rgba(37, 37, 37, 0.85);
    text-align: center;

    input {
      text-align: center;
      font-weight: 600;
    }

    .titre {
      width: 100%;
      font-size: 1.2em;
      font-weight: bold;
      text-align: center;
      color: white;
      border-bottom: 1px gray solid;
    }
  }

  .ligne-cde {
    height: 55vh;
    width: 80%;
    overflow-y: scroll;
    border: lightgray 1px solid;
    padding: 5px;
    text-align: center;
    margin: 5px 0 0 0;
    background-color: rgba(37, 37, 37, 0.85);
  }
}

.tab1 {
  width: 98%;

  tr {
    background-color: aliceblue;
    &:nth-child(even) {
      background-color: rgb(230, 243, 255);
    }
    &:hover {
      /*background: linear-gradient(azure, rgb(206, 248, 248));*/
      background: rgb(253, 255, 158);
      background: linear-gradient(
        40deg,
        rgba(253, 255, 158, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(253, 255, 158, 1) 100%
      );
    }
    td {
      padding: 10px 3px 10px 3px;
      text-align: center;

      input {
        max-width: 80px;
        text-align: center;
        background-color: rgb(199, 253, 253);
        font-weight: bold;
      }
    }
  }

  .trEntete {
    border: gray 1px solid;
    background-color: rgb(109, 156, 172);

    td {
      padding: 5px 3px;
    }
  }
}
</style>

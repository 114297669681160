<template>
  <div class="container">
      <div class="param">
          <table>
              <tr>
                  <td>
                      N° devis mini.
                  </td>
                  <td>
                      <input type="text" name="devisMin" id="devisMin" v-model="devisMin">
                  </td>
              </tr>
              <tr>
                  <td>
                      N° affaire mini.
                  </td>
                  <td>
                      <input type="text" name="affMin" id="affMin" v-model="affMin">
                  </td>
              </tr>
              <tr>
                  <td>
                      N° facture mini.
                  </td>
                  <td>
                      <input type="text" name="factMin" id="factMin" v-model="factMin">
                  </td>
              </tr>
              <tr>
                  <td>
                      Gérer les dimensions article
                  </td>
                  <td>
                      <input type="checkbox" name="dimArticle" id="dimArticle" v-model="dimArticle">
                  </td>
              </tr>
          </table>
          <div class="enLigne-2" style="margin: 15px 0 10px 0">
              <div></div>
          <div class="petit-btn">
              <img src="/assets/img/valider.png" alt="">
          </div>
        </div>
      </div>
      

  </div>
</template>

<script>
export default {
    name: 'parametres',
    data () {
        return {
            devisMin: 0,
            affMin: 0,
            factMin: 0,
            dimArticle: true,
        }
    }
}
</script>

<style scoped lang="scss">

    .container{
        width: 100%;
        height: calc(100vh - 110px);
        background: url("/assets/fond/fond11.jpg");
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 110px 0 0 0;
        .param{

            border: 1px dimgray solid;
            background-color: rgba(0,0,0,0.8);
            
            color: white;

            table{
                width: 95%;
                tr {
                    line-height: 2em;
                }
            }

        }

        input{
            text-align: center;
            font-weight: 600;
        }
    }

</style>
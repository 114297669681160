<template>
  <div class="title">Statistiques de l'année {{ tmpAn }} {{ SuiteTitre }}</div>
  <div class="container">
    <div class="recherche">
      <!-- ********   Module de recherche et de liste  ********** -->
      <div class="titreGraph">Options de recherche</div>
      <!--    Recherche   -->
      <div class="champtxt">
        <label for="an">Année</label><br />
        <select name="an" id="an" v-model="tmpAn">
          <option value=""></option>
          <option value="2020">2020</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
        </select>
      </div>
      <div class="champtxt">
        <label for="mois">Mois</label><br />
        <select name="mois" id="mois" v-model="tmpMois">
          <option value=""></option>
          <option value="01">01</option>
          <option value="02">02</option>
          <option value="03">03</option>
          <option value="04">04</option>
          <option value="05">05</option>
          <option value="06">06</option>
          <option value="07">07</option>
          <option value="08">08</option>
          <option value="09">09</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
        </select>
      </div>
      <div class="champtxt">
        <label for="client">Client</label><br />
        <select name="client" id="client" v-model="tmpCL">
          <option value=""></option>
          <option
            v-for="cl in lstClient"
            :key="cl.id"
            :value="cl.raisonsociale"
            @click="recupClient(cl.id, cl.raisonsociale)"
          >
            {{ cl.raisonsociale }}
          </option>
        </select>
      </div>
      <div class="enLignePetit">
        <div class="btn-horiz" @click="recherche">
          <img src="/assets/img/rechercher.png" alt="" /><br />
          <p>Rechercher</p>
        </div>
        <div class="btn-horiz" @click="actualise">
          <img src="/assets/img/actualiser.gif" alt="" /><br />
          <p>RAZ</p>
        </div>
        <div>
          <div class="encharge" v-if="AffAttente == true"></div>
        </div>
      </div>

      <div class="titreGraph" style="margin: 40px 0 20px 0">
        Générateur de listes
      </div>
      <!--    Listes   -->
      <div class="btn-horiz" style="width: 90%" @click="ListeDevis">
        <img src="/assets/img/rechercher.png" alt="" /><br />
        <p>Liste des devis</p>
      </div>
      <div class="btn-horiz" style="width: 90%" @click="ListeCommande">
        <img src="/assets/img/rechercher.png" alt="" /><br />
        <p>Liste des commandes</p>
      </div>
      <div class="btn-horiz" style="width: 90%" @click="ListeFacture">
        <img src="/assets/img/rechercher.png" alt="" /><br />
        <p>Liste des factures</p>
      </div>
    </div>
    <div class="graph1">
      <div class="titreGraph">
        Comparaison montant Devis / Commande / Facture / Avoir
      </div>
      <canvas id="myChart2" class="chart"></canvas>
      <div class="titreGraph">
        Evolution montant Devis / Commande / Facture par mois
      </div>
      <canvas id="myChart6" class="chart"></canvas>
      <div class="titreGraph">Evolution cumulée Devis / Commande / Facture</div>
      <canvas id="myChart7" class="chart"></canvas>
      <div class="titreGraph">
        Comparaison montant devis / commande par client
      </div>
      <canvas id="myChart5" class="chart"></canvas>
    </div>
    <div class="graph2">
      <div class="titreGraph">Reste à commander | à facturer</div>
      <canvas id="myChart4" class="chart"></canvas>
      <div class="titreGraph">
        Comparaison nombre Devis / Commande / Facture
      </div>
      <canvas id="myChart" class="chart"></canvas>
      <div class="titreGraph">Répartition des informations commerciales</div>
      <canvas id="myChart3" class="chart"></canvas>
    </div>
    <div class="graph3"></div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

import VueCookies from "vue-cookies";
import axios from "axios";

export default {
  name: "acceuil",
  data() {
    return {
      lstClient: [],
      client: "",
      idclient: 0,
      codeEntreprise: "",
      lstDevis1: [],
      lstCde1: [],
      lstInfos: [],
      lstFact1: [],
      lstCL1: [],
      lstCL2: [],
      lstDevisMois: [],
      lstCdeMois: [],
      lstFactMois: [],
      annee: "",
      mois: "",
      tmpCL: "",
      tmpAn: "",
      tmpMois: "",
      SuiteTitre: "",
      AffAttente: false,
    };
  },
  methods: {
    recupClient(id, rs) {
      this.client = rs;
      this.idClient = id;
    },
    listeClient() {
      axios({
        method: "post",
        url: this.$store.state.server + "/api/client/listAll",
        data: { codeEntreprise: this.codeEntreprise },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.lstClient = reponse.data;
          this.lstRech = reponse.data;
          console.log(this.lstClient[0].id);
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    chargeDonnees() {
      this.AffAttente = true;
      console.log("Charge données");

      if (this.tmpAn == "" || this.tmpAn == undefined) {
        let DTemp = new Date();
        this.tmpAn = DTemp.getFullYear();
      }

      this.SuiteTitre = "";
      console.log(this.tmpMois);
      if (this.tmpMois != "" && this.tmpMois != undefined) {
        this.SuiteTitre = " - mois " + this.tmpMois;
      }

      if (this.tmpCL != "" && this.tmpCL != undefined) {
        this.SuiteTitre = " - client " + this.tmpCL;
      }

      axios({
        method: "post",
        //url: this.$store.state.server + "https://13.38.82.232:8080/api/graph/Recherche",
        url: this.$store.state.server + "/api/graph/Recherches",
        data: {
          codeEntreprise: this.codeEntreprise,
          Client: this.client,
          Annee: this.annee,
          Mois: this.mois,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.lstDevis1 = reponse.data.graphDevis;

          this.lstCde1 = reponse.data.graphCde;
          this.lstInfos = reponse.data.graphInfos;
          this.lstFact1 = reponse.data.graphFact;
          this.lstCL1 = reponse.data.graphCL1;
          this.lstCL2 = reponse.data.graphCL2;
          this.lstDevisMois = reponse.data.lstDevisMois;
          this.lstCdeMois = reponse.data.lstCdeMois;
          this.lstFactMois = reponse.data.lstFactMois;
          console.log("lstDevisMois: " + this.lstDevisMois);
          this.lanceCharge();
          this.AffAttente = false;
        })
        .catch((erreur) => {
          console.log(erreur);
          this.AffAttente = false;
        });
    },
    lanceCharge() {
      setTimeout(() => {
        this.creeGraphNb1();
        this.creeGraphMnt1();
        this.creeGraphInfos1();
        this.creeGraphMntReste1();
        this.creeGraphCL();
        this.creeGraphParMois();
      }, 250);
    },
    creeGraphNb1() {
      const var1 = parseFloat(this.lstDevis1[0].nbdevis);
      const var2 = parseFloat(this.lstCde1[0].nbcde);
      const var3 = parseFloat(this.lstFact1[0].nbfact);
      //console.log('creeGraphNb1: ' + var1, var2,this.lstDevis1);
      var ctx = document.getElementById("myChart");
      this.myChart1 = new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["Nb Devis", "Nb Commande", "Nb Facture"],
          datasets: [
            {
              label: "Commande / Devis / Facture",
              data: [var1, var2, var3],
              backgroundColor: ["Lightblue", "palegreen", "orange"],
              borderColor: ["blue", "green", "darkorange"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
    creeGraphMnt1() {
      const var1 = parseFloat(this.lstDevis1[0].mntdevis);
      const var2 = parseFloat(this.lstCde1[0].mntcde);
      const var3 = parseFloat(this.lstFact1[0].mntfact);
      const var4 = Math.abs(parseFloat(this.lstFact1[0].avoir));
      console.log("creeGraphMnt1: " + var1, var2);
      var ctx = document.getElementById("myChart2");
      this.myChart2 = new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["Mnt Devis", "Mnt Commande", "Mnt Facture", "Mnt Avoir"],
          datasets: [
            {
              label: "Commande / Devis / Facture / Avoir",
              data: [var1, var2, var3, var4],
              backgroundColor: ["Lightblue", "palegreen", "orange", "tomato"],
              borderColor: ["blue", "green", "darkorange", "brown"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          responsive: true,
          maintainAspectRatio: true,
        },
      });
    },
    creeGraphInfos1() {
      const var1 = [];
      const var2 = [];
      const var3 = [
        "Azure",
        "Palegreen",
        "Lightblue",
        "LightYellow",
        "PaleTurquoise",
        "LightSalmon",
        "LightSteelBlue",
      ];
      const var4 = [];

      let C1 = 5;
      let C2 = 10;
      let C3 = 15;
      let i = 1;
      for (let infos of this.lstInfos) {
        var1.push(infos.typeinfo);
        var2.push(infos.nbinfos);

        var4.push("Gray");
        i++;
      }

      console.log("creeGraphInfos1: " + var3, var4);

      var ctx = document.getElementById("myChart3");
      this.myChart3 = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: var1,
          datasets: [
            {
              label: "Répartition infos commerciales",
              data: var2,
              backgroundColor: var3,
              borderColor: var4,
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          responsive: true,
          maintainAspectRatio: true,
        },
      });
    },
    creeGraphMntReste1() {
      const var1 =
        parseFloat(this.lstDevis1[0].mntdevis) -
        parseFloat(this.lstCde1[0].mntcde);
      const var2 =
        parseFloat(this.lstCde1[0].mntcde) -
        parseFloat(this.lstFact1[0].mntfact);
      const var3 = parseFloat(this.lstDevis1[0].mntdevis);
      console.log(var1, var2);
      var ctx = document.getElementById("myChart4");
      this.myChart4 = new Chart(ctx, {
        data: {
          labels: ["Devis à commander", "Commande à facturer"],
          datasets: [
            {
              type: "bar",
              label: "Commande / Facture",
              data: [var1, var2],
              backgroundColor: ["palegreen", "orange"],
              borderColor: ["green", "darkorange"],
              borderWidth: 1,
            },
            {
              type: "line",
              label: "Devis",
              data: [var3, var3],
              backgroundColor: ["lightblue", "lightblue"],
              borderColor: ["blue", "blue"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          responsive: true,
          maintainAspectRatio: true,
        },
      });
    },
    creeGraphCL() {
      const var1 = [];
      const var2 = [];
      const var3 = [];
      let Trouve = false;

      for (let CL1 of this.lstCL1) {
        var1.push(CL1.nomclient);
        var2.push(parseFloat(CL1.mntdevis));
        Trouve = false;
        for (let CL2 of this.lstCL2) {
          if (CL2.nomclient == CL1.nomclient) {
            var3.push(parseFloat(CL2.mntcde));
            Trouve = true;
          }
        }
        if (Trouve == false) {
          var3.push(0);
        }
      }

      console.log(var1, var2);
      var ctx = document.getElementById("myChart5");
      this.myChart5 = new Chart(ctx, {
        data: {
          labels: var1,
          datasets: [
            {
              type: "line",
              label: "Devis",
              data: var2,
              backgroundColor: ["Lightblue"],
              borderColor: ["blue"],
              borderWidth: 1,
            },
            {
              type: "bar",
              label: "Commande",
              data: var3,
              backgroundColor: ["palegreen"],
              borderColor: ["green"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          responsive: true,
          maintainAspectRatio: true,
          tension: 0.4,
        },
      });
    },
    creeGraphParMois() {
      const var1 = [];
      const var2 = [];
      const var3 = [];
      const var4 = [];
      let Trouve = false;

      let cumulDevis = [];
      let cumulCde = [];
      let cumulFacture = [];

      let totDevis = 0;
      let totCde = 0;
      let totFacture = 0;

      for (let m = 1; m <= 12; m++) {
        let mo = "";
        if (m < 10) {
          mo = "0" + m;
        } else {
          mo = m;
        }

        var1.push(mo);

        //recherche des valeur devis pour le mois en cours
        Trouve = false;
        for (let dev of this.lstDevisMois) {
          if (dev.mois == m) {
            var2.push(parseFloat(dev.mntdevis));
            Trouve = true;
            totDevis += dev.mntdevis;
          }
        }
        if (Trouve == false) {
          var2.push(0);
        }
        cumulDevis.push(totDevis);
        //recherche des valeur commande pour le mois en cours
        Trouve = false;
        for (let cde of this.lstCdeMois) {
          if (cde.mois == m) {
            var3.push(parseFloat(cde.mntcde));
            Trouve = true;
            totCde += cde.mntcde;
          }
        }
        if (Trouve == false) {
          var3.push(0);
        }
        cumulCde.push(totCde);

        //recherche des valeur facture pour le mois en cours
        Trouve = false;
        for (let fa of this.lstFactMois) {
          if (fa.mois == m) {
            var4.push(parseFloat(fa.mntfact));
            Trouve = true;
            totFacture += fa.mntfact;
          }
        }
        if (Trouve == false) {
          var4.push(0);
        }
        cumulFacture.push(totFacture);
      }

      console.log(
        "liste par mois: " + var1,
        cumulDevis,
        cumulCde,
        cumulFacture
      );
      var ctx = document.getElementById("myChart6");
      this.myChart6 = new Chart(ctx, {
        data: {
          labels: var1,
          datasets: [
            {
              type: "line",
              label: "Devis",
              data: var2,
              backgroundColor: ["Lightblue"],
              borderColor: ["blue"],
              borderWidth: 1,
            },
            {
              type: "line",
              label: "Commande",
              data: var3,
              backgroundColor: ["lightgreen"],
              borderColor: ["green"],
              borderWidth: 1,
            },
            {
              type: "line",
              label: "Facture",
              data: var4,
              backgroundColor: ["orange"],
              borderColor: ["darkorange"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          responsive: true,
          maintainAspectRatio: true,
          tension: 0.4,
        },
      });

      //cumul par mois
      var ctx2 = document.getElementById("myChart7");
      this.myChart7 = new Chart(ctx2, {
        data: {
          labels: var1,
          datasets: [
            {
              type: "line",
              label: "Devis",
              data: cumulDevis,
              backgroundColor: ["Lightblue"],
              borderColor: ["blue"],
              borderWidth: 1,
            },
            {
              type: "line",
              label: "Commande",
              data: cumulCde,
              backgroundColor: ["lightgreen"],
              borderColor: ["green"],
              borderWidth: 1,
            },
            {
              type: "line",
              label: "Facture",
              data: cumulFacture,
              backgroundColor: ["orange"],
              borderColor: ["darkorange"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          responsive: true,
          maintainAspectRatio: true,
          tension: 0.4,
        },
      });
    },
    actualise() {
      // destruction des graph avant de les recréer
      this.destroyChart();

      this.client = "";
      this.annee = "";
      this.mois = "";

      this.chargeDonnees();
    },
    recherche() {
      this.destroyChart();
      this.client = this.tmpCL;
      this.annee = this.tmpAn;
      this.mois = this.tmpMois;

      this.chargeDonnees();
    },
    destroyChart() {
      /* var C1 = document.getElementById("myChart");
      var C2 = document.getElementById("myChart2");
      var C3 = document.getElementById("myChart3");
      var C4 = document.getElementById("myChart4");
      var C5 = document.getElementById("myChart5");
      var C6 = document.getElementById("myChart6");
      C1.destroy();
      C2.destroy();
      C3.destroy();
      C4.destroy();
      C5.destroy();
      C6.destroy(); */
      this.myChart1.destroy();
      this.myChart2.destroy();
      this.myChart3.destroy();
      this.myChart4.destroy();
      this.myChart5.destroy();
      this.myChart6.destroy();
      this.myChart7.destroy();
    },
    ListeDevis() {
      VueCookies.set("atom_liste_typ", "devis", "1h");
      let route = this.$router.resolve({ name: "listing" });
      //let route = this.$router.resolve('/link/to/page'); //This also works.
      window.open(route.href, "_blank");
    },
    ListeCommande() {
      VueCookies.set("atom_liste_typ", "commandes", "1h");
      let route = this.$router.resolve({ name: "listing" });
      //let route = this.$router.resolve('/link/to/page'); //This also works.
      window.open(route.href, "_blank");
    },
    ListeFacture() {
      VueCookies.set("atom_liste_typ", "factures", "1h");
      let route = this.$router.resolve({ name: "listing" });
      //let route = this.$router.resolve('/link/to/page'); //This also works.
      window.open(route.href, "_blank");
    },
  },
  async mounted() {
    this.codeEntreprise = await VueCookies.get("atom_code_entreprise");
    console.log(this.$store.state);
    this.listeClient();
    await this.chargeDonnees();
    setTimeout(() => {
      console.log("attente");
    }, 1500);
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 110px 0 5px 0;
}
.container {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
  background: linear-gradient(
    180deg,
    rgba(240, 240, 240, 1) 0%,
    rgba(80, 80, 80, 1) 2%,
    rgba(15, 15, 15, 1) 100%
  );

  .recherche {
    padding: 8px;
    flex-basis: 20%;
    border-right: lightgray 1px solid;
    height: 100%;
  }

  .titreGraph {
    font-size: 0.7em;
    font-weight: 600;
    color: white;
    text-align: center;
    margin: 15px 0 5px 0;
  }

  .graph1 {
    flex-basis: 38%;
    height: 100%;

    .chart {
      margin: 10px;
      width: 95%;
      color: white;
      border: rgb(242, 241, 241) 1px solid;
    }
  }

  .graph2 {
    flex-basis: 38%;
    height: 100%;

    .chart {
      margin: 10px;
      width: 95%;

      border: rgb(242, 241, 241) 1px solid;
    }
  }

  .graph3 {
    flex-basis: 3%;
  }
}

@media screen and (max-width: 500px) {
  .container {
    flex-direction: column;
  }
}
</style>

/*
function noBack(){
    window.history.forward()
}
noBack();
window.onload=noBack;
window.onpageshow=function(evt){
noBack()
}
window.onunload=function(){void(0)}
*/

import axios from "axios";
import store from '../store'
import VueCookies from "vue-cookies";

function formatDate(d1){
    console.log(d1)
    let D = new Date(d1);
    D=D.toLocaleDateString();
    
    return D;

}

function dateEN(d2){
    let D2 = new Date(d2);
    let m = D2.getMonth() + 1
    let mois
    if (m<10){
        mois = "0" + m
    }else{
        mois=m.toString()
    }

    let j = D2.getDate()
    let jour
    if (j<10){
        jour = "0" + j
    }else{
        jour=j.toString()
    }

    D2=D2.getFullYear() + '-' + mois + '-' + jour;
    console.log(D2);
    return D2;
}

function multipli(x,y){
    let m = x*y;
    m=m.toFixed(2);
    return m;
}

function miseEnForme(txt){
    let texte = ""
    if(txt==undefined){
        texte=""
    }else{
        texte=txt
    }
    texte=texte.replace(String.fromCharCode(13),'<br>')
    return texte;
}

export function GereRetourChariot(txt,sens){
    if (txt=="" || txt==undefined){
        return ""
    }
    if(sens==1){
        txt=txt.replace("<br>",/(\r\n)/g)
    }else{
        txt=txt.replace(/(\r\n|\n|\r)/g,"<br>")
    }
    
    
    return txt 
}

function imprimer_page(){
    window.print();
}

export async function verifExisteDansBase(table,champ,valeur,result) {
    console.log('dans verif')
    
    await axios({
        method: "post",
        url: store.state.server + "/api/utils/verifExist",
        data: { 
            table: table,
            champ: champ,
            valeur: valeur
        },
      })
        .then((reponse) => {
            console.log('verif: ' + reponse)
          if(reponse.data.nb>0) {
            
            result = true
          } else {
              console.log(false)
              result =  false
          }
          console.log(result , reponse.data.nb)
          
          
        })
        .catch((erreur) => {
          console.log(erreur);
          //on met vrai pour eviter de pouvoir supprimer si la requete c mal passée
          result = true
        });
        return result

}


export async function SelUneDonnee(req) {
    console.log('dans verif')
    let result
    await axios({
        method: "post",
        url: store.state.server + "/api/utils/SelUneDonnee",
        data: { 
            req: req,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
        
          
          result = reponse.data[0].result
          console.log('requete: ' + req,"result: " + result)
          
        })
        .catch((erreur) => {
          console.log(erreur);
          //on met vrai pour eviter de pouvoir supprimer si la requete c mal passée
          result = ''
        });
        console.log('result: ' + result)
        return result
}

//Permet de formater la date en jj/mm/aaaa
export async function FormatDate(d1) {
    if (d1 != "" && d1 != undefined) {
      let D = new Date(d1);
      D = D.toLocaleDateString();
      return D;
    } else {
      let D2 = new Date();
      D2 = D2.toLocaleString();
      return D2;
    }
}

//permet de transformer la date pour qu'elle soit lisible dans un input date
export async function dateLoad(d) {
    d = FormatDate(d);
    console.log(d);
    d = d.toString().split("T")[0];
    console.log(d);
    let tabD = d.split("/");

    d = tabD[2] + "-" + tabD[1] + "-" + tabD[0];
    console.log(d);
    return d;
  }
<template>
  <div class="imp">
    <div class="logo">
      <img src="/assets/logo/logo2.png" alt="" />
    </div>

    <div class="tempo" v-if="factureValide == ''">TEMPORAIRE</div>

    <div class="client">
      <b>{{ RS }}</b
      ><br />
      {{ adresse }}<br /><br />
      {{ cp }} {{ ville }}
    </div>
    <div v-if="type == 'devis'" class="infos">
      Devis n° <b>{{ numero }}</b> du {{ FormatDate(date1) }}
    </div>

    <div v-if="type == 'cde'" class="infos2">
      <p>
        AR de Commande n° <b>{{ numero }}</b>
      </p>
      <br />
      <table>
        <tr class="entete">
          <td>Date de la commande</td>
          <td>Date liv. prévue</td>
          <td>N° Affaire</td>
          <td>N° Devis</td>
        </tr>
        <tr>
          <td>{{ FormatDate(date1) }}</td>
          <td>{{ FormatDate(dateliv) }}</td>
          <td>{{ numAffaire }}</td>
          <td>{{ numDevis }}</td>
        </tr>
      </table>
    </div>

    <div v-if="type == 'fact'" class="infos2">
      <p>
        Facture n° <b>{{ numFacture }}</b>
      </p>
      <br />
      <table>
        <tr class="entete">
          <td>Date de la facture</td>
          <td>N° commande</td>
          <td>N° Affaire</td>
          <td>N° Devis</td>
        </tr>
        <tr>
          <td>{{ FormatDate(date1) }}</td>
          <td>{{ numero }}</td>
          <td>{{ numAffaire }}</td>
          <td v-if="numdevis > 0">{{ numdevis }}</td>
          <td v-else>_</td>
        </tr>
      </table>
    </div>

    <div v-if="type == 'avoir'" class="infos2">
      <p>
        Avoir n° <b>{{ numAvoir }}</b>
      </p>
      <br />
      <table>
        <tr class="entete">
          <td>Date de l'avoir</td>
          <td>Pour la Facture n°</td>
          <td>N° Affaire</td>
        </tr>
        <tr>
          <td>{{ FormatDate(date1) }}</td>
          <td>{{ numero }}</td>
          <td>{{ numAffaire }}</td>
        </tr>
      </table>
    </div>

    <div class="ligne">
      <table>
        <tr class="entete">
          <td v-if="type != 'avoir'">Référence</td>
          <td>Désignation</td>
          <td v-if="type != 'avoir'">Code</td>
          <td>Quantité</td>
          <td>Unité</td>
          <td>Prix U.H.T.</td>
          <td>Total H.T.</td>
        </tr>
        <tr style="vertical-align: top" v-for="li in Lignes" :key="li.id">
          <td v-if="type != 'avoir'">
            <p>{{ li.reference }}</p>
          </td>
          <td style="white-space: pre; text-align: left">
            <span>
              <p>
                <b>{{ li.designation }}</b>
              </p>
            </span>
            <div
              v-if="li.commentaire != '' && li.commentaire != undefined"
              style="font-size: 0.75em; margin: 0 0 0 1em"
            >
              {{ li.commentaire }}
            </div>
          </td>
          <td v-if="type != 'avoir'">
            <p>{{ li.code }}</p>
          </td>
          <td>
            <p>{{ li.quantite }}</p>
          </td>
          <td>
            <p>{{ li.unite }}</p>
          </td>
          <td>
            <p>{{ li.pu }}</p>
          </td>
          <td>
            <p>{{ li.total }}</p>
          </td>
        </tr>
      </table>
    </div>
    <div class="pied">
      <div class="siret">
        <p>ATOMDEV - 25 Bis rue de la Sablière 85230 SAINT URBAIN</p>
        <p>
          SIRET: 947917753 00024 - Tel: 06.22.14.29.20 - Email:
          contact@atom-dev.net - https://www.atom-dev.net
        </p>
      </div>
      <div class="total">
        <table>
          <tr>
            <td>Total H.T.</td>
            <td>{{ HT }}</td>
          </tr>
          <tr v-if="txTva > 0">
            <td>TVA ({{ txTva }}%)</td>
            <td>{{ TVA }}</td>
          </tr>
          <tr v-if="txTva > 0">
            <td>Total T.T.C.</td>
            <td>
              <b>{{ TTC }}</b>
            </td>
          </tr>
          <tr v-if="type == 'fact'">
            <td>Date échéance</td>
            <td>
              {{ FormatDate(dateEch) }}
            </td>
          </tr>
        </table>
        <span v-if="txTva <= 0" style="font-size: 0.8em"
          >TVA non applicable, article 293 B du CGI</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from "axios";
import infosVue from "../components/infos.vue";

export default {
  name: "impression",
  data() {
    return {
      Infos: [],
      Lignes: [],
      client: [],
      numero: "",
      date1: "",
      RS: "",
      adresse: "",
      cp: "",
      ville: "",
      HT: 0,
      TVA: 0,
      TTC: 0,
      numDevis: 0,
      numAffaire: 0,
      dateliv: "",
      numFacture: 0,
      type: "",
      codeEntreprise: "",
      numAvoir: 0,
      factureValide: "",
      dateEch: "",
      txTva: 20,
    };
  },
  methods: {
    chargeDevis(id) {
      axios({
        method: "post",
        url: this.$store.state.server + "/api/Imprime/ImpDevis",
        data: {
          id: id,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.type = "devis";
          this.Infos = reponse.data.lst;
          this.txTva = this.Infos[0].tauxTva;
          this.Lignes = reponse.data.lstLigne;
          this.client = reponse.data.lstClient;
          this.factureValide = "1";

          this.numero = this.Infos[0].id;
          this.date1 = this.Infos[0].datedevis;

          this.RS = this.client[0].raisonsociale;
          this.adresse = this.client[0].adresse;
          this.cp = this.client[0].cp;
          this.ville = this.client[0].ville;

          for (let li of this.Lignes) {
            this.HT += parseFloat(li.total);
          }

          this.TVA = parseFloat(this.HT) * (this.txTva / 100);

          this.TTC = parseFloat(this.HT) + parseFloat(this.TVA);

          this.HT = this.HT.toFixed(2);
          this.TVA = this.TVA.toFixed(2);
          this.TTC = this.TTC.toFixed(2);

          setTimeout(() => {
            window.print();
          }, 1500);
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    chargeCde(id, facture) {
      axios({
        method: "post",
        url: this.$store.state.server + "/api/Imprime/ImpCde",
        data: {
          id: id,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);

          this.type = "cde";

          this.Infos = reponse.data.lst;
          this.Lignes = reponse.data.lstLigne;
          this.client = reponse.data.lstClient;

          this.numero = this.Infos[0].numcde;
          this.date1 = this.Infos[0].datecommande;
          this.numDevis = this.Infos[0].iddevis;
          this.numAffaire = this.Infos[0].numaffaire;
          this.dateliv = this.Infos[0].dateliv;
          this.numFacture = this.Infos[0].numfacture;

          this.RS = this.client[0].raisonsociale;
          this.adresse = this.client[0].adresse;
          this.cp = this.client[0].cp;
          this.ville = this.client[0].ville;

          for (let li of this.Lignes) {
            this.HT += parseFloat(li.total);
          }

          this.TVA = parseFloat(this.HT) * (this.txTva / 100);

          this.TTC = parseFloat(this.HT) + parseFloat(this.TVA);

          this.HT = this.HT.toFixed(2);
          this.TVA = this.TVA.toFixed(2);
          this.TTC = this.TTC.toFixed(2);

          setTimeout(() => {
            window.print();
          }, 1500);
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    chargeFacture(id, facture) {
      axios({
        method: "get",
        url: this.$store.state.server + "/api/Imprime/ImpFacture",
        params: {
          numF: id,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.type = "fact";

          this.Infos = reponse.data.lst;
          this.Lignes = reponse.data.lstLigne;
          this.client = reponse.data.lstClient;

          this.numero = this.Infos[0].numcommande;
          this.date1 = this.Infos[0].datefacture;
          this.dateEch = this.Infos[0].dateecheance;
          this.numDevis = this.Infos[0].iddevis;

          this.numAffaire = this.Infos[0].numaffaire;
          this.numFacture = this.Infos[0].numfac;
          this.factureValide = this.Infos[0].definitive;
          this.txTva = this.Infos[0].txtva;
          console.log("facture valide : " + this.factureValide);

          this.RS = this.client[0].raisonsociale;
          this.adresse = this.client[0].adresse;
          this.cp = this.client[0].cp;
          this.ville = this.client[0].ville;

          for (let li of this.Lignes) {
            this.HT += parseFloat(li.total);
          }

          this.TVA = parseFloat(this.HT) * (this.txTva / 100);

          this.TTC = parseFloat(this.HT) + parseFloat(this.TVA);

          this.HT = this.HT.toFixed(2);
          this.TVA = this.TVA.toFixed(2);
          this.TTC = this.TTC.toFixed(2);

          setTimeout(() => {
            window.print();
          }, 1500);
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    chargeAvoir(id, facture) {
      axios({
        method: "get",
        url: this.$store.state.server + "/api/Imprime/ImpAvoir",
        params: {
          numA: id,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.type = "avoir";

          this.Infos = reponse.data.lst;
          this.Lignes = this.Infos;
          this.client = reponse.data.lstClient;

          this.numero = this.Infos[0].numfacture;
          this.date1 = this.Infos[0].dateavoir;
          this.numDevis = this.Infos[0].iddevis;
          this.numAffaire = this.Infos[0].numaffaire;
          this.numAvoir = id;

          this.RS = this.client[0].raisonsociale;
          this.adresse = this.client[0].adresse;
          this.cp = this.client[0].cp;
          this.ville = this.client[0].ville;

          for (let li of this.Lignes) {
            this.HT += parseFloat(li.total);
          }

          this.TVA = parseFloat(this.HT) * (this.txTva / 100);

          this.TTC = parseFloat(this.HT) + parseFloat(this.TVA);

          this.HT = this.HT.toFixed(2);
          this.TVA = this.TVA.toFixed(2);
          this.TTC = this.TTC.toFixed(2);

          setTimeout(() => {
            window.print();
          }, 1500);
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    FormatDate(d1) {
      if (d1 != "" && d1 != undefined) {
        let D = new Date(d1);
        D = D.toLocaleDateString();
        return D;
      } else {
        let D2 = new Date();
        D2 = D2.toLocaleString();
        return D2;
      }
    },
  },

  async mounted() {
    this.codeEntreprise = await VueCookies.get("atom_code_entreprise");
    const typ = VueCookies.get("atom_imprime_typ");
    const id = VueCookies.get("atom_imprime_id");

    switch (typ) {
      case "devis":
        this.chargeDevis(id);
        break;
      case "cde":
        this.chargeCde(id, false);
        break;
      case "facture":
        console.log("id fact: " + id);
        this.chargeFacture(id, true);
        break;
      case "avoir":
        this.chargeAvoir(id, true);
        break;
      default:
    }

    console.log(typ, id);
  },
};
</script>

<style scoped lang="scss">
.imp {
  width: 95%;
  max-width: 21cm;
  margin: 0 auto;
  min-height: 95vh;

  .logo {
    width: 250px;
    height: 125px;
    margin: 10px 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .infos {
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin: 150px 0 0 0;
  }

  .client {
    border: lightgray 1px solid;
    text-align: center;
    width: 30%;
    float: right;
    padding: 8px;
    margin: 0 10px 0 0;
  }

  .ligne {
    margin: 35px 10px 10px 10px;

    width: 100%;
    max-width: 20cm;

    table {
      width: 100%;
      max-width: 20cm;
      border: lightgray 1px solid;

      tr {
        &:nth-child(odd) {
          background-color: rgb(245, 255, 255);
        }
      }
      .entete {
        background-color: rgb(179, 207, 207);
        text-align: center;
      }
      td {
        padding: 0.5em;
        text-align: center;
      }
    }
  }

  .pied {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .total {
      width: 30%;
      border: 1px gray solid;
      margin: 2px 10px;
      float: right;

      table {
        width: 100%;

        tr {
          text-align: center;
          background-color: rgb(150, 241, 241);
        }
      }
    }

    .siret {
      margin: 0 0 0 20px;
      font-size: 0.7em;
      color: dimgray;
    }
  }

  .infos2 {
    margin: 120px 0 20px 10px;
    p {
      font-size: 2em;
    }

    table {
      border: gray 1px solid;

      tr {
        text-align: center;
      }
      .entete {
        background-color: rgb(150, 241, 241);
        text-align: center;
      }
      td {
        padding: 0.5em 2em;
        text-align: center;
      }
    }
  }
}

.tempo {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%) rotateZ(-15deg);
  font-size: 5em;
  color: rgb(182, 182, 182);
  z-index: -1;
}
</style>

<template>
  <div>
    <div v-if="tokenVerified">
      <entete @change-userpage="modifpage($event)"></entete>

      <div v-if="mode == 'acceuil'">
        <acceuil></acceuil>
      </div>
      <div v-if="mode == 'client'">
        <client @change-userpage="modifpage($event)"></client>
      </div>
      <div v-if="mode == 'articles'">
        <articles></articles>
      </div>
      <div v-if="mode == 'infos'">
        <div v-if="props != ''">
          <infosCom :rechClient="props"></infosCom>
        </div>
        <div v-else>
          <infosCom></infosCom>
        </div>
      </div>

      <div v-if="mode == 'devis'">
        <div v-if="props != ''">
          <devis @change-userpage="modifpage($event)" :clientDevis="props"></devis>
        </div>
        <div v-else>
          <devis @change-userpage="modifpage($event)"></devis>
        </div>
        
      </div>

      <div v-if="mode == 'devencde'">
        <div v-if="props != ''">
          <devEnCde @change-userpage="modifpage($event)" :idDevis="props"></devEnCde>
        </div>
        <div v-else>
          <devEnCde @change-userpage="modifpage($event)"></devEnCde>
        </div>
      </div>

      <div v-if="mode == 'commande'">
        <div v-if="props != '' || devisRechId != ''">
          <commande @change-userpage="modifpage($event)" :clientCde="props" :devisRechId="devisRechId"></commande>
        </div>
        <div v-else>
          <commande @change-userpage="modifpage($event)"></commande>
        </div>
        
      </div>
      <div v-if="mode == 'param'">
        <parametres></parametres>
      </div>
    </div>
    <div v-else>
      <errorauth></errorauth>
    </div>
  </div>
  
</template>

<script>
import axios from "axios";
import errorauth from "../components/ErrorAuth.vue";
import entete from "../components/entete.vue";
import acceuil from "../components/acceuil_user.vue";
import client from "../components/client.vue";
import articles from "../components/articles.vue";
import devis from "../components/devis.vue";
import infosCom from "../components/infos.vue";
import devEnCde from "../components/devEnCde.vue";
import commande from "../components/commande.vue";
import parametres from '../components/parametres.vue'
import VueCookies from "vue-cookies";

export default {
  data() {
    return {
      tokenVerified: false,
      mode: "acceuil",
      props: "",
      propDevEnCde: "",
      devisRechId: 0,
    };
  },
  components: {
    errorauth,
    entete,
    client,
    devis,
    acceuil,
    infosCom,
    articles,
    devEnCde,
    commande,
    parametres,
  },
  created() {
    axios({
      method: "get",
      url: this.$store.state.server + "/tokenVerif",
      withCredentials: true,
      headers: { authorization: VueCookies.get('jwtAtomGC')}
    })
      .then((reponse) => {
        console.log(reponse.data.AllowedUser);
        if (reponse.data.AllowedUser != "YES") {
          this.tokenVerified = false;
        } else {
          this.tokenVerified = true;
        }
      })
      .catch((erreur) => {
        console.log(erreur);
      });
  },
  methods: {
    modifpage(valeur) {
      let v1 = valeur[0];
      let v2 = valeur[1];
      let v3 = valeur[2];
      if (v2 == undefined) {
        v2 = "";
      }
      if (v3 == undefined) {
        v3 = "";
      }

      this.mode = v1;
      this.props = v2;
      this.devisRechId = v3;

      console.log('v1: ' + v1 + '  v2: ' + v2 + "  v3: " + v3);
    },
  },
};
</script>

<style></style>

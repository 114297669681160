<template>
  <div class="entete">
    <div class="enLignePetit">
      <div id="logo-img">
        <img src="/assets/logo/atom.png" alt="" id="logo-img" />
      </div>
      <div>
         <div class="titre1">ATOM-GC</div>
         <div class="titre2">{{ codeEntreprise }}</div>
      </div>
     
    </div>

    <div @click="modifMode('acceuil', $event)" class="btn-nav">
      <img src="/assets/img/acceuil.png" alt="" /><br />
      <p>Acceuil et statistiques</p>
    </div>
    <div @click="modifMode('client', $event)" class="btn-nav">
      <img src="/assets/img/client.png" alt="" /><br />
      <p>Clients</p>
    </div>
    <div @click="modifMode('articles', $event)" class="btn-nav">
      <img src="/assets/img/articles.png" alt="" /><br />
      <p>Articles</p>
    </div>
    <div @click="modifMode('infos', $event)" class="btn-nav">
      <img src="/assets/img/infos.png" alt="" /><br />
      <p>Commercial et rappels</p>
    </div>
    <div @click="modifMode('devis', $event)" class="btn-nav">
      <img src="/assets/img/devis.png" alt="" /><br />
      <p>Devis</p>
    </div>
    <div @click="modifMode('commande', $event)" class="btn-nav">
      <img src="/assets/img/commande.png" alt="" /><br />
      <p>Commandes et factures</p>
    </div>
    <div @click="modifMode('param', $event)" class="btn-nav">
      <img src="/assets/img/parametres.png" alt="" /><br />
      <p>Options</p>
    </div>
    <div class="deconnect">
      <div style="text-align: center">Login: {{ login }}</div>
      <div class="petit-btn" @click="deconnect">
        <img src="/assets/img/power-off.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueCookies from "vue-cookies";

axios.defaults.withCredentials = true;

export default {
  name: "entete",
  props: {
    mode: "",
  },
  data() {
    return {
      login: "",
      codeEntreprise: "",
    };
  },

  methods: {
    modifMode(valeur, event) {
      console.log(valeur);

      //mise en surbrillance
      let btns = document.querySelectorAll(".btn-nav");
      for (let btn of btns) {
        //btn.classList.remove('.btn-surb')
        btn.style.boxShadow = "box-shadow: 3px 3px 5px 0px #c7c7c7;";
      }

      event.target.classList.add(".btn-surb");

      console.log(event.target);

      let tabVal = [valeur, ""];

      //this.mode = valeur;
      this.$emit("change-userpage", tabVal);
    },
    deconnect() {
      axios({
        method: "get",
        url: this.$store.state.server + "/deconnect",
        withCredentials: true,
        headers: { authorization: VueCookies.get('jwtAtomGC')}
      })
        .then((reponse) => {
          console.log(reponse);
          this.$store.commit("SauveLogin", {
            idUser: 0,
            login: "",
          });
        })
        .catch((erreur) => {
          console.log(erreur);
        });
      this.$router.push({ path: "/" });
    },
  },
  async mounted() {
    this.login = VueCookies.get("atom_user_login");
    this.codeEntreprise = await VueCookies.get("atom_code_entreprise");
  },
};
</script>

<style scoped lang="scss">
.entete {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  z-index: 100;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 66%,
    rgba(0, 212, 255, 1) 95%,
    rgba(255, 255, 255, 1) 100%
  );
  color: rgb(226, 226, 226);
  .titre1 {
    font-size: 2.5em;
    font-weight: bold;
  }
  .titre2 {
    font-size: 1.2em;
    font-weight: bold;
    text-align:right;
  }

  .btn-nav {
    position: relative;
    width: 75px;
    height: 75px;
    border: lightgray 1px solid;
    border-radius: 5px;
    font-size: 0.6em;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 3px 3px 5px 0px #c7c7c7;
    transition: 0.2s ease;
    z-index: 1;
    overflow: hidden;
    /*&:hover{
                background-color: rgb(240, 255, 255);
                box-shadow: inset 2px 2px 5px 0px #9b9b9b;
                color: rgb(60, 60, 60);
            }*/
    &:hover {
      box-shadow: none;
      color: rgb(60, 60, 60);
    }

    &:active {
      background-color: rgb(224, 253, 253);
    }

    img {
      width: 30px;
      height: 30px;
    }

    &::before {
      z-index: -1;
      content: "";
      position: absolute;
      height: 0%;
      width: 0%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgb(240, 255, 255);
      border-radius: 50%;
      transition: 0.3s ease;
      box-shadow: inset 2px 2px 5px 0px #9b9b9b;
    }

    &:hover::before {
      height: 150%;
      width: 150%;
    }
  }

  .btn-surb {
    box-shadow: inset 2px 2px 5px 0px #c7c7c7;
  }

  .deconnect {
    font-size: 0.7em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 70px;
  }

  #logo-img {
    width: 60px;
    height: 60px;
    margin: 0 10px 0 0;
    animation: animelogo 10s infinite;
  }

  @keyframes animelogo {
    0% {
      transform: rotateZ(0deg);
      scale: 1;
    }
    50% {
      transform: rotateX(180deg) rotateZ(180deg);
      scale: 0.8;
    }
    100% {
      transform: rotateZ(360deg);
      scale: 1;
    }
  }
}

@media screen and (max-width: 800px) {
  .entete {
      .btn-nav {
      width: 30px;
      height: 50px;
      font-size: 0.28em;
    }
  }
  
}
</style>

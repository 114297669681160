<template>
  <div class="infos">
    <div class="container">
      <div
        v-if="mode != 'createModif' && CreeRappel == false"
        class="infos-liste"
      >
        <div class="titreEcran">Liste des Informations commerciales</div>
        <div class="btn-liste">
          <!-- ***********************    bouton d'action de la liste des infos   *******************************  -->
          <div class="btn-horiz" @click="ajouterInfo">
            <img src="/assets/img/ajouter.png" alt="" /><br />
            <p>Créer une entrée</p>
          </div>
          <div class="btn-horiz" @click="ouvreRecherche">
            <img src="/assets/img/rechercher.png" alt="" /><br />
            <p>Recherche</p>
          </div>
          <div class="btn-horiz" @click="ChargeListAll">
            <img src="/assets/img/actualiser.gif" alt="" /><br />
            <p>Actualiser</p>
          </div>
          <!-- ***********************    div de recherche   *******************************  -->
          <div v-if="affRecherche == true" class="recherche">
            <table>
              <tr>
                <td>Pour le client</td>
                <td><input type="text" name="rechRS" v-model="rechCL" /></td>
              </tr>
              <tr>
                <td>Type de fiche</td>
                <td><input type="text" name="rechCP" v-model="rechType" /></td>
              </tr>
              <tr>
                <td
                  style="text-align: right; cursor: pointer"
                  @click="ouvreRecherche"
                >
                  <u>Annuler</u>
                </td>
                <td
                  style="text-align: right; cursor: pointer"
                  @click="effectueRecherche"
                >
                  <u>Rechercher</u>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="encharge" v-if="enCharge == true"></div>
        <!-- ***********************    Liste des informations   *******************************  -->
        <div
          v-for="info in lstInfos"
          :key="info.id"
          class="infos-card maxHeightInfo"
          v-bind="{ id: info.id }"
          @click="ChargeInfos(info.id)"
        >
          <div class="infos-titre">
            <div>Information commerciale n° {{ info.id }}</div>
            <div class="infos-titre" style="width: 50px">
              <div class="tab_img" @click="modifInfo(info.id)">
                <img src="/assets/img/modifier.gif" alt="" />
              </div>
              <div class="tab_img" @click="affSuppInfo(info.id)">
                <img src="/assets/img/supprimer.png" alt="" />
              </div>
            </div>
            <div class="tab_img" @click="agrandiListe(info.id)">
              <img src="/assets/img/fleches1.gif" alt="" />
            </div>
          </div>
          <div class="alert" v-if="info.id == delInfo">
            <span
              >Vous allez supprimer l'information sélectionnée. Voulez vous
              continuer ?</span
            >
            <div>
              <button
                class="btnAlert"
                @click="
                  () => {
                    delInfo = -1;
                  }
                "
              >
                Annuler
              </button>
              <button class="btnAlert" @click="deleteInfo(info.id)">
                Confirmer
              </button>
            </div>
          </div>
          <div class="infos-card-detail">
            <div class="d1">
              <table>
                <tr>
                  <td style="width: 25%">Client:</td>
                  <td style="width: 25%">
                    <b>{{ info.nomclient }}</b>
                  </td>
                  <td style="width: 25%">date:</td>
                  <td style="width: 25%">
                    <b>{{ FormatDate(info.dateinfo) }}</b>
                  </td>
                </tr>
                <tr>
                  <td>type:</td>
                  <td style="width: 50%">
                    <b>{{ info.typeinfo }}</b>
                  </td>
                  <td style="width: 50%">Login:</td>
                  <td>
                    <b>{{ info.login }}</b>
                  </td>
                </tr>
              </table>
            </div>
            <!--<div class="d1">
              <table>
                <tr>
                  <td>type:</td>
                  <td>{{ info.typeinfo }}</td>
                  <td>client:</td>
                  <td>{{ info.nomclient }}</td>
                </tr>
                
              </table>
            </div>-->
            <div class="d1">
              <table>
                <tr>
                  <td>Détail</td>
                  <td>Commentaires</td>
                </tr>
                <tr>
                  <td>
                    <div style="white-space: pre">
                      <b>{{ info.detail }}</b>
                    </div>
                  </td>
                  <td>
                    <div style="white-space: pre">
                      <b>{{ info.commentaire }}</b>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <!--
            <div class="d3">
              {{ client.commentaire }}
            </div>-->
          </div>
        </div>

        <div class="btn-liste">
          <div v-if="page > 1" class="btn-horiz" @click="Precedent">
            <img src="/assets/img/precedent.gif" alt="" /><br />
            <p>Précédent</p>
          </div>
          <div
            v-if="nbEnreg + 1 > page * 30"
            class="btn-horiz"
            @click="Suivant"
          >
            <img src="/assets/img/suivant.gif" alt="" /><br />
            <p>Suivant</p>
          </div>
        </div>
      </div>

      <!--******** gestion des Rappels */ -->

      <div v-if="mode != 'createModif' && CreeRappel == false" class="rappel">
        <div class="titreEcran">Liste des Rappels</div>

        <div class="btn-liste">
          <!-- ***********************    bouton d'action de la liste des rappels   *******************************  -->
          <div class="btn-horiz" @click="ajouterRappel">
            <img src="/assets/img/ajouter.png" alt="" /><br />
            <p>Créer un rappel</p>
          </div>

          <div class="btn-horiz" @click="listRappel">
            <img src="/assets/img/actualiser.gif" alt="" /><br />
            <p>Actualiser</p>
          </div>
        </div>
        <div class="enChargeRappel" v-if="enCharge == true"></div>
        <div
          v-for="rappel in lstRappel"
          :key="rappel.id"
          class="infos-cardRappel"
          v-bind="{ id: rappel.id }"
          @click="ChargeRappel(rappel.id)"
        >
          <div class="infos-titre">
            <div>A rappeler le {{ FormatDate(rappel.daterappel) }}</div>
            <div class="infos-titre" style="width: 50px">
              <div class="tab_img" @click="modifRappel(rappel.id)">
                <img src="/assets/img/modifier.gif" alt="" />
              </div>
              <div class="tab_img" @click="affSuppRappel(rappel.id)">
                <img src="/assets/img/supprimer.png" alt="" />
              </div>
            </div>
            <div class="tab_img" @click="termineRappel(rappel.id)">
              <img src="/assets/img/coche.png" alt="" />
            </div>
          </div>
          <div class="alert" v-if="rappel.id == delRappel">
            <span>Supprimer ce rappel ?</span>
            <div>
              <button
                class="btnAlert"
                @click="
                  () => {
                    delRappel = -1;
                  }
                "
              >
                Annuler
              </button>
              <button class="btnAlert" @click="deleteRappel(rappel.id)">
                Confirmer
              </button>
            </div>
          </div>
          <div class="infos-card-detail">
            <div
              style="
                width: 90%;
                border: 1px solid lightgray;
                padding: 10px 5px;
                white-space: pre;
              "
            >
              {{ rappel.commentaire }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="mode == 'createModif'" class="infos-detail">
        <div v-if="mode == 'createModif'" class="info-createmodif">
          <!-- ***********************    Div de saisie   *******************************  -->

          <!-- infos -->
          <div class="enLigne">
            <div class="champtxt" style="width: 50%">
              <label for="typeinfo">Type de fiche information</label><br />
              <select
                name="typeinfo"
                id="selectTI"
                v-model="typeinfo"
                style="width: 95%"
              >
                <option v-for="typ in lstTypeInfo" :key="typ.etat">
                  {{ typ.typeinfo }}
                </option>
              </select>
            </div>
            <div class="petit-btn" @click="ajoutTypeInfo">
              <img src="/assets/img/ajouter.png" alt="" />
            </div>
          </div>
          <div class="champtxt">
            <label for="nomclient">Client</label><br />
            <select name="nomclient" v-model="nomclient">
              <option v-for="cl in lstCient" :key="cl.raisonsociale">
                {{ cl.raisonsociale }}
              </option>
            </select>
          </div>
          <div v-if="action == 'ajout'" class="champtxt">
            <label for="dateinfo">Date</label><br />
            <input type="date" name="dateinfo" v-model="dateameric" />
          </div>
          <div class="champtxt">
            <label for="detail">Compte rendu</label><br />
            <textarea name="detail" rows="7" v-model="detail"></textarea>
          </div>
          <div class="champtxt">
            <label for="commentaire">Commentaire</label><br />
            <textarea
              name="commentaire"
              rows="7"
              v-model="commentaire"
            ></textarea>
          </div>
          <div class="flex-align-horiz">
            <div class="btn-horiz" @click="createModifInfo(idRappel)">
              <img src="/assets/img/valider.png" alt="" /><br />
              <p>Enregistrer</p>
            </div>
            <div class="btn-horiz" @click="annuleModifInfo">
              <img src="/assets/img/annuler.png" alt="" /><br />
              <p>Annuler</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Rappels -->
      <div v-if="CreeRappel" class="infos-detail info-createmodif">
        <div class="champtxt">
          <label for="dateRappel">Date à être rappelé</label><br />
          <input type="date" name="dateRappel" v-model="dateRappel" />
        </div>
        <div class="champtxt">
          <label for="detailRappel">Détail</label><br />
          <textarea
            name="detailRappel"
            rows="7"
            v-model="detailRappel"
          ></textarea>
        </div>
        <div class="flex-align-horiz">
          <div class="btn-horiz" @click="createModifRappel(0)">
            <img src="/assets/img/valider.png" alt="" /><br />
            <p>Enregistrer</p>
          </div>
          <div class="btn-horiz" @click="annuleRappel">
            <img src="/assets/img/annuler.png" alt="" /><br />
            <p>Annuler</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment, { now } from "moment";
import { GereRetourChariot } from "../js/fonction.js";
import VueCookies from "vue-cookies";

export default {
  name: "infosCom",
  data() {
    return {
      lstInfos: [],
      lstCient: [],
      lstTypeInfo: [],
      lstRappel: [],
      idInfo: 0,
      numclient: 0,
      typeinfo: "",
      dateinfo: "",
      dateformat: "",
      dateameric: "",
      iduser: 0,
      login: "",
      detail: "",
      commentaire: "",
      nomclient: "",
      mode: "lst",
      btnVisible: false,
      action: "ajout",
      affRecherche: false,
      rechCL: "",
      rechType: "",
      nbEnreg: 0,
      page: 1,
      codeEntreprise: "",
      CreeRappel: false,
      dateRappel: "",
      detailRappel: "",
      idRappel: 0,
      delInfo: -1,
      delRappel: -1,
      enCharge: false,
      enChargeRappel: false,
    };
  },
  props: ["rechClient"],
  methods: {
    ChargeListAll() {
      this.enCharge = true
      axios({
        method: "post",
        url: this.$store.state.server + "/api/infos/listAll",
        data: { page: this.page, codeEntreprise: this.codeEntreprise },
        headers: { authorization: VueCookies.get('jwtAtomGC')}
      })
        .then((reponse) => {
          console.log(reponse);
          this.lstInfos = reponse.data.infos;
          this.nbEnreg = this.lstInfos.length;
          this.lstCient = reponse.data.clients;
          this.lstTypeInfo = reponse.data.type;
          //this.lstRech = reponse.data;
          //console.log(this.lstClient[0].id);
          console.log(this.lstTypeInfo);
          this.enCharge = false;
        })
        .catch((erreur) => {
          console.log(erreur);
          this.enCharge = false
        });
    },
    listRappel() {
      this.enChargeRappel = true
      axios({
        method: "post",
        url: this.$store.state.server + "/api/rappels/listAll",
        data: { iduser: this.iduser, codeEntreprise: this.codeEntreprise },
        headers: { authorization: VueCookies.get('jwtAtomGC')}
      })
        .then((reponse) => {
          console.log(reponse);
          this.lstRappel = reponse.data.lstRappels;
          this.enChargeRappel=false
        })
        .catch((erreur) => {
          console.log(erreur);
          this.enChargeRappel=false
        });
    },
    Suivant() {
      this.page++;
      this.ChargeListAll();
    },
    Precedent() {
      this.page--;
      if (this.page < 1) {
        this.page = 1;
      }
      this.ChargeListAll();
    },
    FormatDate(d1) {
      if (d1 != "" && d1 != undefined) {
        let D = new Date(d1);
        D = D.toLocaleDateString();
        return D;
      } else {
        return "";
      }
    },
    FormatDateAmericain(d1) {
      if (d1 != "" && d1 != undefined) {
        let D = moment().format(d1, "YYYY/MM/DD");
        D = D.split("T")[0];
        return D;
      } else {
        return "";
      }
    },
    gereRetour(txt, sens) {
      return GereRetourChariot(txt, sens);
    },
    ChargeInfos(id) {
      if (id != 0 && id != undefined) {
        for (const info of this.lstInfos) {
          if (info.id == id) {
            this.idInfo = id;
            this.numclient = info.idclient;
            this.typeinfo = info.typeinfo;
            this.dateinfo = info.dateinfo;
            //console.log(info.dateinfo);
            //console.log(this.FormatDate(info.dateinfo));
            //console.log(this.FormatDateAmericain(info.dateinfo));
            this.dateformat = this.FormatDate(info.dateinfo);
            this.iduser = info.iduser;
            this.login = info.login;
            this.detail = info.detail;
            this.commentaire = info.commentaire;
            this.nomclient = info.nomclient;
          }
        }
        this.btnVisible = true;
        //this.surbrillanceListe(id);
      }
    },
    surbrillanceListe(id) {
      let ListeDivInfos = document.querySelectorAll(".infos-card");
      //console.log(ListeDivClient)
      for (let info of ListeDivInfos) {
        const tmpId = info.attributes[1].nodeValue;

        if (id == tmpId) {
          info.style.border = "2px solid black";
          info.style.backgroundColor = "Yellow";
        } else {
          info.style.border = "1px solid black";
          info.style.backgroundColor = "Azure";
        }
      }
    },
    agrandiListe(id) {
      let ListeDivinfos = document.querySelectorAll(".infos-card");

      for (let info of ListeDivinfos) {
        const tmpId = info.attributes[1].nodeValue;

        if (id == tmpId) {
          const hauteur = getComputedStyle(info, null).maxHeight;
          console.log(hauteur);
          if (hauteur == "117px") {
            info.style.maxHeight = "1000px";
          } else {
            info.style.maxHeight = "117px";
          }
        }
      }
    },
    modifInfo(id) {
      this.idInfo = id;
      this.mode = "createModif";
      this.action = "modif";
    },
    annuleModifInfo() {
      this.mode = "lst";
    },
    ajouterInfo() {
      this.typeinfo = "";
      this.nomclient = "";
      this.detail = "";
      this.commentaire = "";
      this.action = "ajout";
      this.mode = "createModif";
      this.btnVisible = true;
    },
    ajouterRappel() {
      this.dateRappel = "";
      this.detailRappel = "";

      this.CreeRappel = true;
      this.idRappel = 0;
    },
    annuleRappel() {
      this.CreeRappel = false;
      this.mode = "lst";
    },
    modifRappel(id) {
      this.CreeRappel = true;
      this.idRappel = id;
    },
    dateLoad(d) {
      d = this.FormatDate(d);
      //console.log(d);
      d = d.split("T")[0];

      let tabD = d.split("/");

      d = tabD[2] + "-" + tabD[1] + "-" + tabD[0];
      console.log(d);
      return d;
    },
    ChargeRappel(id) {
      if (id != 0 && id != undefined) {
        for (const rap of this.lstRappel) {
          if (rap.id == id) {
            this.idRappel = id;
            this.dateRappel = this.dateLoad(rap.daterappel);
            this.detailRappel = rap.commentaire;
          }
        }
        this.btnVisible = true;
        //this.surbrillanceListe(id);
      }
    },
    createModifInfo() {
      if (this.action == "ajout") {
        console.log("dateinfo: " + this.dateinfo);
        let di1 = this.FormatDateAmericain(this.dateameric);
        console.log("daa  " + di1);
        axios({
          method: "post",
          url: this.$store.state.server + "/api/infos/CreateInfo",
          data: {
            login: this.login,
            iduser: this.iduser,
            typeinfo: this.typeinfo,
            nomclient: this.nomclient,
            dateinfo: this.dateameric,
            detail: this.detail,
            commentaire: this.commentaire,
            codeEntreprise: this.codeEntreprise,
          },
        })
          .then((reponse) => {
            console.log(reponse);
            this.mode = "lst";
            this.btnVisible = false;
          })
          .catch((erreur) => {
            console.log(erreur);
          });
      } else {
        axios({
          method: "post",
          url: this.$store.state.server + "/api/infos/ModifInfo",
          data: {
            typeinfo: this.typeinfo,
            nomclient: this.nomclient,
            detail: this.detail,
            commentaire: this.commentaire,
            id: this.idInfo,
          },
          headers: { authorization: VueCookies.get('jwtAtomGC')}
        })
          .then((reponse) => {
            console.log(reponse);
            let i = 0;
            for (let info of this.lstInfos) {
              if (info.id == this.idInfo) {
                this.lstInfos[i].typeinfo = this.typeinfo;
                this.lstInfos[i].nomclient = this.nomclient;
                this.lstInfos[i].detail = this.detail;
                this.lstInfos[i].commentaire = this.commentaire;
              }
              i++;
            }

            this.mode = "lst";
          })
          .catch((erreur) => {
            console.log(erreur);
          });
      }
    },
    affSuppInfo(id) {
      this.agrandiListe(id);
      this.delInfo = id;
    },
    deleteInfo(id) {
      if (id == 0 || id == undefined) {
        alert("Vous devez sélectionner une fiche à supprimer.");
        return;
      }

      axios({
        method: "post",
        url: this.$store.state.server + "/api/infos/deleteInfo",
        data: { id: id },
        headers: { authorization: VueCookies.get('jwtAtomGC')}
      })
        .then((reponse) => {
          console.log(reponse);
          this.mode = "lst";
          this.btnVisible = false;
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    ouvreRecherche() {
      if (this.affRecherche == true) {
        this.affRecherche = false;
      } else {
        this.affRecherche = true;
      }
    },
    ajoutTypeInfo() {
      let infoTmp = prompt("Saisissez le nouveau type d'information", "");
      if (infoTmp != "" && infoTmp != undefined) {
        var select = document.getElementById("selectTI");
        select.options[select.options.length] = new Option(infoTmp, infoTmp);
      }
    },
    effectueRecherche() {
      axios({
        method: "post",
        url: this.$store.state.server + "/api/infos/rechercheInfo",
        data: {
          rechCL: this.rechCL,
          rechTyp: this.rechType,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get('jwtAtomGC')}
      })
        .then((reponse) => {
          //console.log(reponse);
          this.lstInfos = reponse.data.infos;
          //this.lstCient = reponse.data.clients;
          this.lstTypeInfo = reponse.data.type;
          //console.log(this.lstTypeInfo);
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    createModifRappel(id) {
      let url = "";
      if (this.idRappel == 0 || this.idRappel == undefined) {
        url = this.$store.state.server + "/api/rappels/createRappel";
      } else {
        url = this.$store.state.server + "/api/rappels/modifRappel";
      }
      axios({
        method: "post",
        url: url,
        data: {
          id: this.idRappel,
          login: this.login,
          iduser: this.iduser,
          daterappel: this.dateRappel,
          commentaire: this.detailRappel,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get('jwtAtomGC')}
      })
        .then((reponse) => {
          console.log(reponse);
          this.mode = "lst";
          this.btnVisible = false;
        })
        .catch((erreur) => {
          console.log(erreur);
        });
      this.annuleRappel();
    },
    affSuppRappel(id) {
      //this.agrandiListe(id);
      this.delRappel = id;
    },
    deleteRappel(id) {
      if (id == 0 || id == undefined) {
        alert("Vous devez sélectionner un rappel à supprimer.");
        return;
      }

      axios({
        method: "post",
        url: this.$store.state.server + "/api/rappels/deleteRappel",
        data: { id: id },
        headers: { authorization: VueCookies.get('jwtAtomGC')}
      })
        .then((reponse) => {
          console.log(reponse);
          this.mode = "lst";
          this.btnVisible = false;
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    termineRappel(id) {
      if (id == 0 || id == undefined) {
        alert("Vous devez sélectionner un rappel à terminer.");
        return;
      }

      if (
        confirm(
          "Vous allez terminer le rappel sélectionné. Voulez-vous continuer ?"
        ) == true
      ) {
        axios({
          method: "post",
          url: this.$store.state.server + "/api/rappels/termineRappel",
          data: { id: id },
          headers: { authorization: VueCookies.get('jwtAtomGC')}
        })
          .then((reponse) => {
            console.log(reponse);
            this.mode = "lst";
            this.btnVisible = false;
          })
          .catch((erreur) => {
            console.log(erreur);
          });
      }
    },
  },
  async mounted() {
    this.codeEntreprise = await VueCookies.get("atom_code_entreprise");
    this.login = VueCookies.get("atom_user_login");
    this.iduser = VueCookies.get("atom_user_id");
    console.log(this.iduser);

    console.log(this.rechClient);

    if (this.rechClient != "" && this.rechClient != undefined) {
      this.rechCL = this.rechClient;
      this.affRecherche = true;
      this.effectueRecherche();
    } else {
      this.ChargeListAll();
    }
    this.listRappel();
  },
};
</script>

<style scoped lang="scss">
.infos {
  margin: 110px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: url("/assets/fond/fond11.jpg");
  background-size: cover;
  max-height: calc(100vh - 118px);
  min-height: calc(100vh - 118px);
  width: 100%;

  .btn-liste {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;

    .infos-liste {
      flex-basis: 70%;

      border: lightgray 1px solid;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 2px;
      overflow-y: scroll;
      min-height: calc(100vh - 124px);
    }
    .rappel {
      flex-basis: 30%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      border: 1px lightgray solid;
      min-height: calc(100vh - 120px);
      overflow-y: scroll;
    }
  }

  .infos-detail {
    min-width: 60%;

    .infos-titre {
      margin: 5px 0;
      font-size: 1.2em;
      font-weight: bold;
      text-decoration: underline;
    }

    .tableau2 {
      border: 1px solid gray;
      min-width: 50%;

      td {
        border-bottom: 1px solid lightgray;
      }
    }
  }

  .info-createmodif {
    border: lightgray 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 7px;
    background-color: rgba(37, 37, 37, 0.85);
  }

  .flex-align-horiz {
    display: flex;
    flex-direction: row;
    justify-content: space-start;
    align-items: center;
  }
}

@media screen and (max-width: 1055px) {
  .infos {
    flex-direction: column;
  }
}

.maxHeightInfo {
  max-height: 117px;
}

.infos-card,
.infos-cardRappel {
  margin: 10px 0 0 0;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: lightgray 1px solid;

  padding: 5px;
  background-color: rgb(247, 247, 247);
  transition: 0.2s ease-in;

  &:hover {
    background: rgb(253, 255, 158);
    /* background: linear-gradient(
      40deg,
      rgba(253, 255, 158, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(253, 255, 158, 1) 100%
    ); */
    box-shadow: 3px 3px 5px 0px #808080;
    scale: 1.01;
  }

  .infos-titre {
    margin: 5px 0;
    font-size: 1.2em;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .infos-card-detail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    .d1 {
      border: 1px solid lightgray;
      width: 98%;
      margin: 3px;
      padding: 2px 4px;

      table {
        width: 100%;

        td {
          padding: 4px;
        }
      }
    }
    .d2 {
      flex-basis: 49%;
    }
    .d3 {
      flex-basis: 99%;
    }
  }
}
</style>

<template>
  <h1>Espace Admin</h1>

  <div class="container">
    <div class="erreur" v-if="msgErr != ''">
      {{ msgErr }}
    </div>
    <div class="user-container">
      <div class="user-create">
        creation d'un utilisateur
        <div class="user-form">
          <form class="user-form">
            <input
              v-model="codeEntreprise"
              type="text"
              name="codeEntreprise"
              placeholder="code Entreprise"
              class="champ"
              required
            /><br />
            <input
              v-model="txtLogin"
              type="text"
              name="login"
              placeholder="Login"
              class="champ"
              required
            /><br />
            <input
              v-model="txtMdp"
              type="text"
              name="mdp"
              placeholder="Mot de passe"
              class="champ"
              required
            /><br />
            <input
              v-model="txtMail"
              type="text"
              name="mail"
              placeholder="E-mail"
              class="champ"
            /><br />
            <label for="chkuser">Est un utilisateur avec pouvoir</label>
            <input
              v-model="chkAdmin"
              type="checkbox"
              name="chkuser"
              id="chkuser"
            />
            <input
              @click="createUser"
              type="submit"
              value="Créer l'utilisateur"
              class="user-btn"
            />
          </form>
        </div>
      </div>

      <div class="user-list">
        <div class="user-card-container">
          <div v-for="user in lstUser" :key="user.id" class="user-card">
            <div>
              Entreprise: <b>{{ user.codeentreprise }}</b>
            </div>
            <div>
              login: <b>{{ user.login }}</b>
            </div>
            <!--<div>mdp: <b>{{ user.mdp }}</b></div>-->
            <div>
              mail: <b>{{ user.mail }}</b>
            </div>
            <div>
              <input @click="suppUser(user.id)" type="button" value=" X " />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="numerotation">Numérotation et paramètres généraux</div> -->
  </div>
</template>

<script>
import axios from "axios";
import vueCookies from "vue-cookies";

export default {
  data() {
    return {
      lstUser: [],
      txtLogin: "",
      txtMdp: "",
      txtMail: "",
      chkAdmin: false,
      msgErr: "",
      codeEntreprise: "",
    };
  },
  async mounted() {
    console.log("entering admin");
    this.lstUsers();
  },
  methods: {
    lstUsers() {
      axios({
        method: "get",
        url: this.$store.state.server + "/user/lstUser",
        withCredentials: true,
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.lstUser = reponse.data;
          console.log("lst   " + this.lstUser);
        })
        .catch((erreur) => {
          console.log(erreur);
          this.$router.push("/");
        });
    },
    suppUser(id) {
      console.log(id);
      if (
        confirm("Vous allez supprimer cet utilisateur, voulez-vous continuer ?")
      ) {
        axios({
          method: "post",
          url: this.$store.state.server + "/user/suppUser",
          data: { id: id },
          headers: { authorization: VueCookies.get("jwtAtomGC") },
        })
          .then((reponse) => {
            console.log(reponse);
            this.lstUser = reponse.data;
          })
          .catch((erreur) => {
            console.log(erreur);
          });
      }
    },
    createUser(e) {
      e.preventDefault();
      this.msgErr = "";

      if (
        this.txtLogin == "" ||
        this.txtMdp == "" ||
        this.codeEntreprise == ""
      ) {
        this.msgErr =
          "Le code entreprise, le login et le mot de passe sont obligatoire";
        return;
      }

      axios({
        method: "post",
        url: this.$store.state.server + "/user/createUser",
        data: {
          codeEntreprise: this.codeEntreprise,
          login: this.txtLogin,
          mdp: this.txtMdp,
          mail: this.txtMail,
          admin: this.chkAdmin,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          //this.lstUser=reponse.data

          this.$router.go();
        })
        .catch((erreur) => {
          console.log(erreur);
          this.msgErr =
            "Une erreur est survenue durant la création, " + erreur.data.verif;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 90vh;
  margin: auto;
  justify-content: space-evenly;

  .user-container {
    display: flex;
    flex-direction: column;
    border: black 2px solid;
    border-radius: 10px;
    flex-basis: 100%;

    .user-create {
      flex-basis: 30%;
      padding: 10px;
      border-bottom: gray 1px solid;
      .user-form {
        display: block;

        .champ {
          padding: 0.8em;
          width: 90%;
          margin: 5px 0;
        }

        .user-btn {
          width: 90%;
          background-color: rgba(158, 227, 250, 0.6);
          border: blue 1px solid;
          border-radius: 5px;
          margin: 10px 0 0 0;
          padding: 1em;
          &:hover {
            background-color: rgba(158, 227, 250, 0.8);
          }
          &:active {
            background-color: rgba(158, 227, 250, 1);
          }
        }
      }
    }

    .user-list {
      flex-basis: 70%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      overflow: hidden;

      .user-card-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .user-card {
          display: flex;
          justify-content: space-between;
          align-content: center;
          align-items: center;
          border: rgb(194, 194, 253) 1px solid;
          background-color: rgb(242, 253, 253);
          border-radius: 5px;
          width: 95%;
          height: 40px;
          margin: 3px 0;

          div {
            padding: 4px;
          }
        }
      }
    }
  }

  .numerotation {
    display: flex;
    flex-direction: column;
    border: black 2px solid;
    border-radius: 10px;
    flex-basis: 48%;
    padding: 10px;
  }
}
</style>
<template>
  <div class="devis">
    <!--********* Partie gauche: liste des devis **********-->
    <div class="lst-devis">
      <div class="titreEcran">Liste des Devis</div>
      <div class="btn-liste">
        <div class="btn-horiz" @click="ajouterDevis">
          <img src="/assets/img/ajouter.png" alt="" /><br />
          <p>Créer un devis</p>
        </div>
        <div class="btn-horiz" @click="ouvreRecherche">
          <img src="/assets/img/rechercher.png" alt="" /><br />
          <p>Recherche</p>
        </div>
        <div class="btn-horiz" @click="ChargeListAll('', 0, '')">
          <img src="/assets/img/actualiser.gif" alt="" /><br />
          <p>Actualiser</p>
        </div>
        <div v-if="affRech == true" class="recherche">
          <table>
            <tr>
              <td>N° devis</td>
              <td><input type="text" name="rechNum" v-model="rechNum" /></td>
            </tr>
            <tr>
              <td>N° affaire</td>
              <td>
                <input type="text" name="rechAffaire" v-model="rechAffaire" />
              </td>
            </tr>
            <tr>
              <td>Raison sociale</td>
              <td><input type="text" name="rechRS" v-model="rechRS" /></td>
            </tr>

            <tr>
              <td>Etat</td>
              <td><input type="text" name="rechEtat" v-model="rechEtat" /></td>
            </tr>
            <tr>
              <td
                style="text-align: right; cursor: pointer"
                @click="ouvreRecherche"
              >
                <u>Annuler</u>
              </td>
              <td
                style="text-align: right; cursor: pointer"
                @click="lanceRecherche"
              >
                <u>Rechercher</u>
              </td>
            </tr>
          </table>
        </div>
        <div class="encharge" v-if="enCharge == true"></div>
      </div>

      <div v-if="devis_mode == 'lst'" class="lstDevis_container">
        <div class="alert" v-if="msgErrDevis != ''">
          {{ msgErrDevis }}
        </div>
        <div class="devis-liste">
          <!--********* **********************  liste des devis *************************************-->
          <div
            v-for="devis in lstRech"
            :key="devis"
            class="devis-card"
            v-bind="{ id: devis.id }"
            @click="ChargeLigneDevis(devis.id)"
          >
            <div class="enLigne-2">
              <div class="devis-titre">
                <b>Devis n° {{ devis.id }}</b> pour le client
                <b>{{ devis.nomclient }}</b> | Affaire n°
                <b>{{ devis.numaffaire }}</b>
                <br />
              </div>
              <div class="enLignePetit">
                <div class="tab_img" @click="chargeDevis(devis.id)">
                  <img src="/assets/img/modifier.gif" alt="" />
                </div>
                <div class="tab_img" @click="affSuppDevis(devis.id)">
                  <img src="/assets/img/supprimer.png" alt="" />
                </div>
              </div>
            </div>
            <div class="alert" v-if="devis.id == delDevis">
              <span
                >Vous allez supprimer le devis sélectionné. Voulez vous
                continuer ?</span
              >
              <div>
                <button
                  class="btnAlert"
                  @click="
                    () => {
                      delDevis = -1;
                    }
                  "
                >
                  Annuler
                </button>
                <button class="btnAlert" @click="deleteDevis(devis.id)">
                  Confirmer
                </button>
              </div>
            </div>
            <div class="devis-card-detail">
              <div class="d1">
                <table>
                  <tr>
                    <td>Désignation:</td>
                    <td>
                      <b>{{ devis.designation }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Interlocuteur:</td>
                    <td>{{ devis.interlocuteur }}</td>
                  </tr>
                  <tr>
                    <td>Date devis:</td>
                    <td>{{ FormatDate(devis.datedevis) }}</td>
                  </tr>
                  <tr>
                    <td>Etat:</td>
                    <td>{{ devis.etat }}</td>
                  </tr>
                </table>
              </div>
              <div class="d2">
                <table>
                  <tr>
                    <td>Montant:</td>
                    <td>
                      <b>{{ FormatNombre(devis.montant, 2) }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Référence:</td>
                    <td>
                      <b>{{ devis.reference }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Délai:</td>
                    <td>{{ FormatDate(devis.delai_realisation) }}</td>
                  </tr>
                  <tr>
                    <td>Validité:</td>
                    <td>{{ FormatDate(devis.delaivalide) }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="devis-detail-commentaire">
              <table>
                <tr>
                  <td>Commentaire devis:</td>
                  <td>
                    <div style="white-space: pre">{{ devis.commentaire }}</div>
                  </td>
                </tr>
                <tr>
                  <td>Commentaire client:</td>
                  <td>
                    <div style="white-space: pre">
                      {{ devis.commentaireclient }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="btn-liste">
          <div v-if="page > 1" class="btn-horiz" @click="Precedent">
            <img src="/assets/img/precedent.gif" alt="" /><br />
            <p>Précédent</p>
          </div>
          <div
            v-if="nbEnreg + 1 > page * 10"
            class="btn-horiz"
            @click="Suivant"
          >
            <img src="/assets/img/suivant.gif" alt="" /><br />
            <p>Suivant</p>
          </div>
        </div>
      </div>

      <!--************************* creation d'un devis ********************************* -->

      <div v-else class="devis-createmodif" style="width: 90%">
        <label for="d_client">Client</label>
        <select name="d_client" id="d_client" v-model="d_client_modif">
          <option
            v-for="cl in lstClient"
            :key="cl.id"
            :value="cl.id"
            @click="recupClient(cl.id, cl.raisonsociale)"
          >
            {{ cl.raisonsociale }}
          </option>
        </select>
        <label for="d_designation">Désignation</label>
        <input
          type="text"
          name="d_designation"
          v-model="d_designation"
          class="champtxt"
        />
        <label for="d_interlocuteur">Interlocuteur</label>
        <input type="text" name="d_interlocuteur" v-model="d_interlocuteur" />
        <label for="d_date">Date du devis</label>
        <input type="date" name="d_date" v-model="d_date" />

        <label for="d_etat">Etat</label>
        <div class="enLigne">
          <select name="d_etat" id="d_etat" v-model="d_etat">
            <option value="En cours">En cours</option>
            <option value="En attente">En attente</option>
            <option value="Informations manquantes">
              Informations manquantes
            </option>
            <option value="Décliné">Décliné</option>
            <option value="Perdu">Perdu</option>
            <option value="En commande">En commande</option>
            <option
              v-for="etat in lstEtat"
              :key="etat.etat"
              :value="etat.etat"
              @click="recupEtat(etat.etat)"
            >
              {{ etat.etat }}
            </option>
          </select>
          <div class="petit-btn" style="margin: 0 0 0 15px" @click="ajEtat">
            <img src="/assets/img/ajouter.png" alt="" /><br />
          </div>
        </div>

        <label for="d_ref">Référence</label>
        <input type="text" name="d_ref" v-model="d_ref" />
        <label for="d_tva">Taux de TVA</label>
        <input type="text" name="d_tva" v-model="d_tva" class="champtxt" />
        <label for="d_delaiR">Délai de réalisation</label>
        <input type="date" name="d_delaiR" v-model="d_delaiR" />
        <label for="d_delaiV">Délai de validité</label>
        <input type="date" name="d_delaiV" v-model="d_delaiV" />
        <label for="d_affaire">N° affaire (N° auto si non renseigné)</label>
        <select
          name="d_affaire"
          id="d_affaire"
          v-model="d_affaire"
          style="text-align: center"
        >
          <option value=""></option>
          <option
            v-for="affaire in lstAffaire"
            :key="affaire.numaffaire"
            :value="affaire.numaffaire"
          >
            {{ affaire.numaffaire }}
          </option>
        </select>
        <div class="champtxt">
          <label for="commentaire">Commentaire</label><br />
          <textarea
            name="commentaire"
            rows="4"
            v-model="d_commentaire"
          ></textarea>
        </div>
        <div class="champtxt">
          <label for="commentaireclient">Commentaire client</label><br />
          <textarea
            name="commentaireclient"
            rows="4"
            v-model="d_commentaireclient"
          ></textarea>
        </div>
        <div class="btn-liste">
          <div class="btn-horiz" @click="EnregDevis">
            <img src="/assets/img/valider.png" alt="" /><br />
            <p>Enregistrer</p>
          </div>
          <div class="btn-horiz" @click="annuleDevis">
            <img src="/assets/img/annuler.png" alt="" /><br />
            <p>Annuler</p>
          </div>
        </div>
      </div>
    </div>

    <!-- ********************************************  lignes du devis ****************************************************** -->

    <div v-if="lignesVisible == true" class="lignes-devis">
      <div v-if="ligneMode != 'modif'" style="width: 98%">
        <div class="enLigne-2">
          <div class="enLignePetit">
            <div class="btn-horiz" @click="ajouterLigneDevis">
              <img src="/assets/img/ajouter.png" alt="" /><br />
              <p>Créer une ligne</p>
            </div>
            <div class="btn-horiz" @click="imprimedevis">
              <img src="/assets/img/imprime.png" alt="" /><br />
              <p>Imprimer le devis</p>
            </div>
          </div>
          <div class="devis-titre-ligne">
            {{ infos_devis }}
          </div>
          <div class="enLignePetit" v-if="nbLignes > 0">
            <div
              class="btn-horiz"
              @click="voirCde"
              style="margin: 0 0 0 10px"
              v-if="existeCde == true"
            >
              <img src="/assets/img/commande.png" alt="" /><br />
              <p>Voir commande(s)</p>
            </div>
            <div
              class="btn-horiz"
              @click="
                () => {
                  if (existeCde == true) {
                    alerteCdeExiste = true;
                  } else {
                    passeEnCommande();
                  }
                }
              "
              style="margin: 0 0 0 10px"
            >
              <img src="/assets/img/commande.png" alt="" /><br />
              <p>Passer en commande</p>
            </div>
          </div>
        </div>
        <div class="alert" v-if="alerteCdeExiste == true">
          <span
            >Ce devis a déjà été passé en commande. Voulez vous créer une
            nouvelle commande pour ce devis ?</span
          >
          <div>
            <button
              class="btnAlert"
              @click="
                () => {
                  alerteCdeExiste = false;
                }
              "
            >
              Annuler
            </button>
            <button class="btnAlert" @click="passeEnCommande()">
              Confirmer
            </button>
          </div>
        </div>
        <div
          v-for="ligne in lstLigneDevis"
          :key="ligne.id"
          class="detail-lignes"
        >
          <table class="table-liste-ligne">
            <tr class="entete-tr">
              <td style="width: 20%">Désignation</td>
              <td style="width: 15%">Référence</td>
              <td style="width: 10%">Code</td>
              <td>Quantité</td>
              <td>Unité</td>
              <td>P.U.H.T</td>
              <td>Total H.T.</td>
            </tr>

            <tr>
              <td>{{ ligne.designation }}</td>
              <td>{{ ligne.reference }}</td>
              <td>{{ ligne.code }}</td>
              <td>{{ ligne.quantite }}</td>
              <td>{{ ligne.unite }}</td>
              <td>{{ ligne.pu }}</td>
              <td>{{ ligne.total }}</td>
            </tr>

            <tr v-if="ligne.commentaire != ''">
              <td style="font-size: 0.8em; padding: 12px 0 0 10px" colspan="7">
                <div style="white-space: pre">{{ ligne.commentaire }}</div>
              </td>
            </tr>
          </table>

          <div class="btn-liste">
            <div class="petit-btn" @click="ModifLigne(ligne.id)">
              <img src="/assets/img/modifier.gif" alt="" /><br />
            </div>
            <div class="petit-btn" @click="AffSupprLigne(ligne.id)">
              <img src="/assets/img/supprimer.png" alt="" /><br />
            </div>
          </div>

          <div class="alert" v-if="ligne.id == delLigne">
            <span
              >Vous allez supprimer la ligne sélectionnée. Voulez vous continuer
              ?</span
            >
            <div>
              <button
                class="btnAlert"
                @click="
                  () => {
                    delLigne = -1;
                  }
                "
              >
                Annuler
              </button>
              <button class="btnAlert" @click="SupprLigne(ligne.id)">
                Confirmer
              </button>
            </div>
          </div>
        </div>

        <div v-if="totalLignes > 0" style="float: right; padding: 0 20px 0 0">
          <table class="tabTotal">
            <tr>
              <td>Total</td>
              <td>{{ totalLignes }}</td>
            </tr>
          </table>
        </div>
      </div>

      <!--  *******************************************************  creation des lignes de devis **************************************************************  -->

      <div v-else style="border: none" class="devis-createmodif">
        <div class="enLigne" style="width: 100%">
          <div
            class="btn-horiz"
            @click="
              () => {
                depuisArt = true;
              }
            "
          >
            <img src="/assets/img/articles.png" alt="" /><br />
            <p>Depuis un article</p>
          </div>
          <div v-show="depuisArt == true">
            <div class="champtxt">
              <label for="ligne_des">Sélectionnez un article</label><br />
              <select
                style="width=70%"
                name="lig_art"
                id="lig_art"
                v-model="lig_art"
              >
                <option
                  v-for="art in lstArticle"
                  :key="art.id"
                  @click="AjArticle(art.id)"
                >
                  {{ art.designation }} ({{ art.reference }})
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="champtxt">
          <label for="ligne_des">Désignation</label><br />
          <input type="text" name="ligne_des" v-model="ligne_des" />
        </div>
        <div class="champtxt">
          <label for="ligne_ref">Référence</label><br />
          <input type="text" name="ligne_ref" v-model="ligne_ref" />
        </div>
        <div class="champtxt">
          <label for="ligne_code">Code</label><br />
          <input type="text" name="ligne_code" v-model="ligne_code" />
        </div>
        <div class="champtxt">
          <label for="ligne_commentaire">Commentaire</label><br />
          <textarea
            name="ligne_commentaire"
            rows="7"
            v-model="ligne_commentaire"
          ></textarea>
        </div>
        <div class="champtxt">
          <label for="ligne_qte">Quantité</label><br />
          <input
            type="text"
            name="ligne_qte"
            style="width: 30%"
            v-model="ligne_qte"
            @change="CalculTotal"
          />
        </div>
        <div class="champtxt">
          <label for="ligne_unite">Unité</label><br />
          <input
            type="text"
            name="ligne_unite"
            style="width: 30%"
            v-model="ligne_unite"
          />
        </div>
        <div class="champtxt">
          <label for="ligne_pu">Prix Unitaire HT</label><br />
          <input
            type="text"
            name="ligne_pu"
            style="width: 30%"
            v-model="ligne_pu"
            @change="CalculTotal"
          />
        </div>
        <div class="champtxt">
          <label for="ligne_total">Total HT</label><br />
          <input
            type="text"
            name="ligne_total"
            style="width: 30%"
            v-model="ligne_total"
          />
        </div>
        <div class="btn-liste">
          <div class="btn-horiz" @click="EnregLigne">
            <img src="/assets/img/valider.png" alt="" /><br />
            <p>Enregistrer</p>
          </div>
          <div class="btn-horiz" @click="AnnuleLigne">
            <img src="/assets/img/annuler.png" alt="" /><br />
            <p>Annuler</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueCookies from "vue-cookies";
import * as FCT from "../js/fonction.js";

export default {
  name: "devis",
  data() {
    return {
      lstDevis: [],
      lstRech: [],
      lstLigneDevis: [],
      lstClient: [],
      lstEtat: [],
      lstArticle: [],
      lstAffaire: [],
      affRech: false,
      page: 1,
      nbEnreg: 0,
      lignePremId: 0,
      ligneMode: "lst",
      ligneAction: "ajout",
      idDevis: 0,
      //variable des lignes de devis
      ligne_des: "",
      ligne_ref: "",
      ligne_code: "",
      ligne_qte: 0,
      ligne_unite: "",
      ligne_pu: 0,
      ligne_total: 0,
      ligne_id: 0,
      ligne_commentaire: "",
      infos_devis: "",
      devis_mode: "lst",
      devis_action: "ajout",
      d_client: "",
      d_client_modif: "",
      d_idClient: 0,
      d_designation: "",
      d_interlocuteur: "",
      d_date: "",
      d_etat: "",
      d_etat_modif: "",
      d_ref: "",
      d_tva: 20,
      d_delaiR: "",
      d_delaiV: "",
      d_affaire: 0,
      d_commentaire: "",
      d_commentaireclient: "",
      depuisArt: false,
      login: "",
      iduser: 0,
      nbLignes: 0,
      rechRS: "",
      rechNum: 0,
      rechEtat: "",
      totalLignes: 0,
      lignesVisible: false,
      codeEntreprise: "",
      existeCde: false,
      rechAffaire: 0,
      delDevis: -1,
      delLigne: -1,
      msgErrDevis: "",
      enCharge: false,
      alerteCdeExiste: false,
    };
  },
  props: ["clientDevis"],
  methods: {
    ChargeListAll(RS, num, etat, affaire) {
      this.lignesVisible = false;
      console.log("charge");
      num = parseInt(num);
      console.log(num);
      this.enCharge = true;
      if (Number.isInteger(num) == false) {
        num = 0;
      }
      axios({
        method: "post",
        url: this.$store.state.server + "/api/Devis/listAll",
        data: {
          page: this.page,
          RS: RS,
          num: num,
          etat: etat,
          affaire: affaire,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.lstDevis = reponse.data.lst;
          this.lstRech = reponse.data.lst;
          this.lstArticle = reponse.data.lstArticle;
          this.lstEtat = reponse.data.lstEtat;
          this.lstClient = reponse.data.lstClient;
          this.lstAffaire = reponse.data.lstAffaire;
          this.nbEnreg = this.lstRech.length;
          this.enCharge = false;
        })
        .catch((erreur) => {
          console.log(erreur);
          this.enCharge = false;
        });
    },
    FormatDate(d1) {
      if (d1 != "" && d1 != undefined) {
        let D = new Date(d1);
        D = D.toLocaleDateString();
        return D;
      } else {
        let D2 = new Date();
        D2 = D2.toLocaleString();
        return D2;
      }
    },
    FormatNombre(nb, decimales) {
      let nb1 = 0;
      nb1 = parseFloat(nb);
      nb1 = nb1.toFixed(decimales);
      return nb1;
    },
    surbrillanceListe(id) {
      let ListeDivDevis = document.querySelectorAll(".devis-card");
      console.log(ListeDivDevis);
      for (let dev of ListeDivDevis) {
        const tmpId = dev.attributes[1].nodeValue;
        //console.log(cl.__vueParentComponent.data)
        console.log("id: " + id, "tmpid:" + tmpId);
        if (id == tmpId) {
          dev.style.border = "2px solid black";
          dev.style.backgroundColor = "#fbf895";
          console.log("ok");
        } else {
          dev.style.border = "1px solid black";
          dev.style.backgroundColor = "rgb(247, 247, 247)";
          console.log("pas ok");
        }
      }
    },
    Suivant() {
      this.page++;
      this.ChargeListAll(
        this.rechRS,
        this.rechNum,
        this.rechEtat,
        this.rechAffaire
      );
      this.iddevis = 0;
    },
    Precedent() {
      this.page--;
      if (this.page < 1) {
        this.page = 1;
      }
      this.ChargeListAll(
        this.rechRS,
        this.rechNum,
        this.rechEtat,
        this.rechAffaire
      );
      this.iddevis = 0;
    },
    ajouterDevis() {
      this.devis_mode = "create";
      this.iddevis = 0;
      this.lignesVisible = false;
    },
    annuleDevis() {
      this.devis_mode = "lst";
    },
    recupClient(id, rs) {
      this.d_client = rs;
      this.d_idClient = id;
    },
    recupEtat(etat) {
      this.d_etat = etat;
      console.log(this.d_etat);
    },
    ouvreRecherche() {
      this.affRech =
        this.affRech == true ? (this.affRech = false) : (this.affRech = true);
      console.log(this.affRech);
    },
    EnregDevis() {
      console.log(this.d_etat);

      axios({
        method: "post",
        url: this.$store.state.server + "/api/devis/createDevis",
        data: {
          id_modif: this.iddevis,
          des: this.d_designation,
          ref: this.d_ref,
          idclient: this.d_idClient,
          nomclient: this.d_client,
          iduser: this.iduser,
          userdevis: this.login,
          datedevis: this.d_date,
          montant: 0,
          commentaire: this.d_commentaire,
          commentaireclient: this.d_commentaireclient,
          etat: this.d_etat,
          delaivalide: this.d_delaiV,
          delairealisation: this.d_delaiR,
          interlocuteur: this.d_interlocuteur,
          codeEntreprise: this.codeEntreprise,
          numaffaire: this.d_affaire,
          tauxTva: this.d_tva,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.lignesVisible = false;

          if (this.iddevis != 0 && this.iddevis != undefined) {
            let i = 0;
            for (let dev of this.lstDevis) {
              if (dev.id == this.iddevis) {
                this.lstDevis[i].designation = this.d_designation;
                this.lstDevis[i].reference = this.d_ref;
                this.lstDevis[i].idclient = this.d_idClient;
                this.lstDevis[i].nomclient = this.d_client;
                this.lstDevis[i].datedevis = this.d_date;
                this.lstDevis[i].commentaire = this.d_commentaire;
                this.lstDevis[i].commentaireclient = this.d_commentaireclient;
                this.lstDevis[i].etat = this.d_etat;
                this.lstDevis[i].delaivalide = this.d_delaiV;
                this.lstDevis[i].delai_realisation = this.d_delaiR;
                this.lstDevis[i].interlocuteur = this.d_interlocuteur;
                this.lstDevis[i].tauxTva = this.d_tva;
                return;
              }
              i++;
            }
          }
          this.devis_mode = "lst";
          this.btnVisible = false;
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    async chargeDevis(id) {
      for (let dev of this.lstDevis) {
        if (dev.id == id) {
          this.iddevis = id;
          let Da = new Date();
          //console.log(dev.nomclient, this.dateLoad(dev.datedevis));
          this.d_client = dev.nomclient;
          this.d_client_modif = this.d_client;
          this.d_idClient = dev.idclient;
          this.d_designation = dev.designation;
          this.d_interlocuteur = dev.interlocuteur;
          Da = new Date(dev.datedevis.split("T")[0]).toLocaleDateString();
          //console.log(Da);
          this.d_date = this.dateLoad(dev.datedevis);
          this.d_etat = dev.etat;
          this.d_ref = dev.reference;
          this.d_delaiR = this.dateLoad(dev.delai_realisation);
          this.d_delaiV = this.dateLoad(dev.delaivalide);
          this.d_commentaire = dev.commentaire;
          this.d_commentaireclient = dev.commentaireclient;
          this.d_tva = dev.tauxTva;
          this.devis_mode = "create";
          //console.log(dev.nomclient, dev.datedevis);
        }
      }
    },
    dateLoad(d) {
      d = this.FormatDate(d);
      //console.log(d);
      d = d.split("T")[0];

      let tabD = d.split("/");

      d = tabD[2] + "-" + tabD[1] + "-" + tabD[0];
      console.log(d);
      return d;
    },
    affSuppDevis(id) {
      //this.agrandiListe(id);
      this.delDevis = id;
    },
    affErreur(msg) {
      this.msgErrDevis = msg;
      setTimeout(() => {
        this.msgErrDevis = "";
      }, 3500);
    },
    async deleteDevis(id) {
      let nb = parseInt(
        await FCT.SelUneDonnee(
          "SELECT count(*) as result FROM commandes WHERE iddevis = " + id
        )
      );

      if (nb > 0) {
        this.affErreur(
          "Ce devis est passé en commande, vous ne pouvez pas le supprimer"
        );
        this.delDevis = -1;
        return;
      }

      axios({
        method: "post",
        url: this.$store.state.server + "/api/Devis/deleteDevis",
        data: { id: id },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.lignesVisible = false;
          const l = this.lstDevis.findIndex((li) => li.id == id);
          if (l != -1) {
            this.lstDevis.splice(l, 1);
          }
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    async ChargeLigneDevis(id) {
      //console.log("charge");
      this.enCharge = true;
      this.idDevis = id;
      this.ligneMode = "lst";
      this.totalLignes = 0;
      this.lignesVisible = true;
      this.surbrillanceListe(id);

      //verif existance commande
      this.existeCde = await FCT.verifExisteDansBase(
        ["commandes"],
        "iddevis",
        this.idDevis,
        false
      );

      for (const devis of this.lstRech) {
        //console.log(devis)
        if (devis.id == id) {
          this.infos_devis = "Lignes du Devis n° " + id;
        }
      }

      axios({
        method: "post",
        url: this.$store.state.server + "/api/Devis/ligneDevis",
        data: { id: id, codeEntreprise: this.codeEntreprise },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.lstLigneDevis = reponse.data;
          this.nbLignes = this.lstLigneDevis.length;
          if (this.nbLignes > 0) {
            this.lignePremId = this.lstLigneDevis[0].id;
            for (let li of this.lstLigneDevis) {
              this.totalLignes += parseFloat(li.total);
            }
            this.totalLignes.toFixed(2);
          }
          this.enCharge = false;
        })
        .catch((erreur) => {
          console.log(erreur);
          this.enCharge = false;
        });
      this.enCharge = false;
    },
    ModifLigne(id) {
      console.log(id);

      if (id != 0 && id != undefined) {
        this.ligne_id = id;

        for (const lig of this.lstLigneDevis) {
          if (id == lig.id) {
            this.ligne_des = lig.designation;
            this.ligne_ref = lig.reference;
            this.ligne_code = lig.code;
            this.ligne_qte = lig.quantite;
            this.ligne_unite = lig.unite;
            this.ligne_pu = lig.pu;
            this.ligne_total = lig.total;
            this.ligne_commentaire = lig.commentaire;
          }
        }
        this.ligneMode = "modif";
        this.ligneAction = "modif";
      }
    },
    AffSupprLigne(id) {
      this.delLigne = id;
    },
    SupprLigne(id) {
      console.log(id);

      axios({
        method: "post",
        url: this.$store.state.server + "/api/Devis/SuppLigneDevis",
        data: { id: id },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    ajouterLigneDevis() {
      if (this.idDevis != 0) {
        this.depuisArt = false;
        this.ligneMode = "modif";
        this.ligneAction = "ajout";
        this.ligne_id = -1;
        this.ligne_des = "";
        this.ligne_ref = "";
        this.ligne_code = "";
        this.ligne_qte = 0;
        this.ligne_unite = "";
        this.ligne_pu = 0;
        this.ligne_total = 0;
        this.ligne_commentaire = "";
      } else {
        alert(
          "Vous devez sélectionner un devis pour ajouter de nouvelles lignes"
        );
      }
    },
    AnnuleLigne() {
      this.ligneMode = "lst";
      this.ligneAction = "ajout";
    },
    CalculTotal() {
      let qte = parseFloat(this.ligne_qte);
      let pu = parseFloat(this.ligne_pu);
      let pt = qte * pu;
      pt = pt.toFixed(2);
      this.ligne_total = pt;
    },
    EnregLigne() {
      //let url = ""
      let id = this.ligne_id;
      let login = this.login;
      if (login == "" || login == undefined) {
        login = this.$store.state.login;
      }
      let da1 = new Date();
      da1 = this.FormatDate(da1);
      console.log(da1);
      if (id == 0 || id == undefined || id == -1) {
        //en ajout de ligne
        //url = this.$store.state.server + "/api/Devis/AddModifLigneDevis"
        id = -1;
      } else {
        // en modification de ligne
        //url = this.$store.state.server + "/api/Devis/ModifLigneDevis"
      }
      axios({
        method: "post",
        url: this.$store.state.server + "/api/Devis/AddModifLigneDevis",
        data: {
          id: id,
          des: this.ligne_des,
          ref: this.ligne_ref,
          code: this.ligne_code,
          qte: this.ligne_qte,
          unite: this.ligne_unite,
          pu: this.ligne_pu,
          total: this.ligne_total,
          commentaire: this.ligne_commentaire,
          idDevis: this.idDevis,
          login: login,
          dateL: da1,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);

          if (
            reponse.data.reqState == "ligne added OK" ||
            reponse.data.reqState == "ligne modified OK"
          ) {
            this.ChargeLigneDevis(this.idDevis);

            this.ligneMode = "lst";
            this.ligneAction = "ajout";
          }
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    AjArticle(id) {
      if (id != 0 && id != undefined) {
        for (let art of this.lstArticle) {
          if (id == art.id) {
            this.ligne_des = art.designation;
            this.ligne_ref = art.reference;
            this.ligne_code = art.code;
            this.ligne_commentaire = art.commentaire;
            this.ligne_pu = art.pu;
            this.ligne_unite = art.unite;
          }
        }
      }
      this.depuisArt = false;
    },
    ajEtat() {
      let etat = prompt("Saisissez le nouvel état à ajouter dans la liste", "");
      if (etat != "" && etat != undefined) {
        var select = document.getElementById("d_etat");
        select.options[select.options.length] = new Option(etat, etat);
        this.recupEtat(etat);
        this.lstEtat.push(etat);
      }
    },

    async passeEnCommande() {
      /*let existe = await FCT.verifExisteDansBase(
        ["commandes"],
        "iddevis",
        this.idDevis,
        false
      );*/
      //console.log(existe);
      /*       let creer = false;
      if (this.existeCde == true) {
        if (
          confirm(
            "Ce devis a déjà été passé en commande. Voulez vous créer une nouvelle commande pour ce devis ?"
          ) == true
        ) {
          creer = true;
        }
      } else {
        creer = true;
      } */
      //if (creer == true) {
      this.$emit("change-userpage", ["devencde", this.idDevis, 0]);
      //}
    },
    voirCde() {
      this.$emit("change-userpage", ["commande", "", this.idDevis]);
    },
    lanceRecherche() {
      console.log(this.rechNum);
      this.ChargeListAll(
        this.rechRS,
        this.rechNum,
        this.rechEtat,
        this.rechAffaire
      );
    },
    imprimedevis() {
      if (this.idDevis == 0) {
        alert("Vous devez sélectionner un devis à imprimer.");
        return;
      }

      VueCookies.set("atom_imprime_typ", "devis", "20s");
      VueCookies.set("atom_imprime_id", this.idDevis, "20s");

      let route = this.$router.resolve({ name: "impression" });
      //let route = this.$router.resolve('/link/to/page'); //This also works.
      window.open(route.href, "_blank");
    },
  },

  async mounted() {
    this.codeEntreprise = await VueCookies.get("atom_code_entreprise");
    this.login = VueCookies.get("atom_user_login");
    this.iduser = VueCookies.get("atom_user_id");
    //console.log("id:" + this.iduser, "user: " + this.login);

    if (this.clientDevis != "" && this.clientDevis != undefined) {
      this.rechRS = this.clientDevis;
    }

    this.ChargeListAll(this.rechRS, 0, "", 0);
    this.idDevis = 0;
    this.surbrillanceListe(-1);
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.devis {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background: url("/assets/fond/fond11.jpg");
  background-size: cover;
  margin: 110px 0 0 0;

  .lst-devis {
    flex-basis: 45%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: calc(100vh - 118px);
    min-height: calc(100vh - 118px);
    border: gray 1px solid;
    border-radius: 5px;
    margin: 5px 0 0 5px;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.2);

    .lstDevis_container {
      width: 98%;

      .devis-liste {
        width: 98%;
        border: lightgray 1px solid;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 2px;

        .devis-card {
          margin: 5px 0 0 0;
          width: 95%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border: lightgray 1px solid;

          padding: 5px;
          background-color: rgb(247, 247, 247);
          transition: 0.2s ease-in;

          &:hover {
            /*background: linear-gradient(azure, rgb(206, 248, 248));*/
            background: rgb(253, 255, 158);
            /* background: linear-gradient(
              40deg,
              rgba(253, 255, 158, 1) 0%,
              rgba(255, 255, 255, 1) 50%,
              rgba(253, 255, 158, 1) 100%
            ); */
            box-shadow: 3px 3px 5px 0px #808080;
            scale: 1.01;
          }

          .devis-titre {
            margin: 5px 0;
            font-size: 1.2em;
          }

          .devis-card-detail {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 0.8em;

            .d1 {
              margin: 0 5px 0 0;
              flex-basis: 48%;
              padding: 4px;
              overflow: hidden;
              border: lightgray 1px solid;
              border-radius: 2px;
              td {
                padding: 4px;
              }
            }
            .d2 {
              flex-basis: 48%;
              padding: 4px;
              overflow: hidden;
              border: lightgray 1px solid;
              border-radius: 2px;

              td {
                padding: 4px;
              }
            }
            .d3 {
              flex-basis: 33%;
            }
          }

          .devis-detail-commentaire {
            font-size: 0.7em;
            margin: 5px 0;
          }
        }
      }
    }
  }

  .lignes-devis {
    flex-basis: 54%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: calc(100vh - 110px);
    border: gray 1px solid;
    border-radius: 5px;
    overflow-y: scroll;
    margin: 5px 5px 0 0;
    position: relative;
    background-color: rgba(0, 0, 0, 0.2);

    .detail-lignes {
      width: 98%;
      border: lightgray 1px solid;
      margin: 5px 0 5px 0;
      padding: 2px;
      transition: 0.1s ease;
      background-color: rgb(242, 242, 242);

      &:hover {
        /*background: linear-gradient(azure, rgb(206, 248, 248));*/
        background: rgb(253, 255, 158);
        /* background: linear-gradient(
          40deg,
          rgba(253, 255, 158, 1) 0%,
          rgba(255, 255, 255, 1) 50%,
          rgba(253, 255, 158, 1) 100%
        ); */
        box-shadow: 3px 3px 5px 0px #808080;
        scale: 1.01;
      }
    }

    .devis-titre-ligne {
      padding: 5px 15px;
      vertical-align: center;
      font-size: 1.2em;
      font-weight: bold;
      text-shadow: #a0a0a0 1px 1px 1px;
      letter-spacing: 1.2px;
      border: 1px solid #b0b0b0;
      background-color: rgba(255, 255, 255, 0.3);
    }

    .tabTotal {
      border: 1px solid lightgray;
      text-align: center;

      tr {
        border-bottom: 1px solid lightgray;
        background-color: rgb(242, 242, 242);

        td {
          padding: 2px 15px;
        }
      }
    }
  }
}

.btn-liste {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 3px 0;
}

.table-liste-ligne {
  width: 98%;
  font-size: 0.8em;
  .entete-tr {
    color: rgb(155, 155, 155);
  }
}

.petit-btn {
  width: 25px;
  height: 25px;
  border: gray 1px solid;
  border-radius: 3px;
  background-color: azure;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3px 8px 0 3px;

  &:hover {
    background-color: rgb(200, 252, 252);
  }

  &:active {
    background-color: rgb(150, 252, 252);
  }

  img {
    width: 22px;
    height: 22px;
  }
}

.devis-createmodif {
  width: 95%;
  border: lightgray 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 7px;
  margin: 0 5px 0 0;
  background-color: rgba(37, 37, 37, 0.85);

  label {
    color: white;
    margin: 12px 0 2px 0;
  }

  input,
  select,
  textarea {
    padding: 0.3em;
  }
}
</style>

<template>
  <div class="erreur">
      <h2>Vous n'êtes pas identifier. </h2>
      <h4>Vous devez revenir à la page d'identification, et vous connecter</h4>
      <input @click="revenir" type="button" value="Retour à l'identification" class="btn">
  </div>
</template>

<script>
export default {
    name: 'errorauth',

    methods: {
        revenir() {
            this.$router.push({ path: '/' })
        }
    }
}
</script>

<style scoped lang="scss">
    .erreur{
        text-align: center;
        width: 400px;
        height: 180px;
        border: red 1px solid;
        border-radius: 10px;
        background-color: lightsalmon;
        color:red;
        margin: 250px auto;
        .btn{
            border: black 1px solid;
            background-color: rgba(255, 111, 59,0.6);
            border-radius: 5px;

            &:hover{
                background-color: rgba(255, 111, 59,0.8);
            }

            &:active{
                background-color: rgba(255, 111, 59,1);
            }
        }
    }
</style>
<template>
  <div class="client">
    <div class="client-liste">
      <div class="titreEcran">Liste des Clients</div>
      <div class="btn-liste">
        <!-- ***********************    bouton de la liste des clients   *******************************  -->
        <div class="btn-horiz" @click="ajouterClient">
          <img src="/assets/img/ajouter.png" alt="" /><br />
          <p>Créer un client</p>
        </div>
        <div class="btn-horiz" @click="ouvreRecherche">
          <img src="/assets/img/rechercher.png" alt="" /><br />
          <p>Recherche</p>
        </div>
        <div class="btn-horiz" @click="ChargeListAll">
          <img src="/assets/img/actualiser.gif" alt="" /><br />
          <p>Actualiser</p>
        </div>
        <!-- ***********************    Div de recherche   *******************************  -->
        <div v-if="affRecherche == true" class="recherche">
          <table>
            <tr>
              <td>Raison sociale</td>
              <td><input type="text" name="rechRS" v-model="rechRS" /></td>
            </tr>
            <tr>
              <td>Code postal</td>
              <td><input type="text" name="rechCP" v-model="rechCP" /></td>
            </tr>
            <tr>
              <td>Activité</td>
              <td><input type="text" name="rechACT" v-model="rechACT" /></td>
            </tr>
            <tr>
              <td style="text-align: right; cursor: pointer" @click="ouvreRecherche">
                <u>Annuler</u>
              </td>
              <td style="text-align: right; cursor: pointer" @click="lanceRecherche">
                <u>Rechercher</u>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="encharge" v-if="enCharge == true"></div>
      <!-- ***********************    liste des clients   *******************************  -->
      <div
        v-for="client in lstRech"
        :key="client.id"
        class="client-card"
        v-bind="{ id: client.id }"
        @click="ChargeClient(client.id)"
      >
        <div class="client-titre">
          {{ client.raisonsociale }}
        </div>
        <div class="client-card-detail">
          <div class="d1">
            <table>
              <tr>
                <td>Tel:</td>
                <td>{{ client.tel }}</td>
              </tr>
              <tr>
                <td>Mail:</td>
                <td>{{ client.mail }}</td>
              </tr>
            </table>
          </div>
          <div class="d2">
            {{ client.adresse }}<br />
            {{ client.cp }} {{ client.ville }}
          </div>
          <!--
          <div class="d3">
            {{ client.commentaire }}
          </div>-->
        </div>
      </div>
    </div>
    <!-- ***********************    Div de détail   *******************************  -->
    <div v-if="btnVisible == true" class="client-detail">
      <div v-if="mode != 'createModif'">
        <div class="flex-align-horiz">
          <div class="btn-horiz" @click="modifClient">
            <img src="/assets/img/modifier.gif" alt="" /><br />
            <p>Modifier le client</p>
          </div>
          <div class="btn-horiz" @click="DeleteClient">
            <img src="/assets/img/supprimer.png" alt="" /><br />
            <p>Supprimer le client</p>
          </div>
          <div class="btn-horiz" @click="infoComm">
            <img src="/assets/img/commercial.png" alt="" /><br />
            <p>Infos commerciales</p>
          </div>
          <div class="btn-horiz" @click="devisCL">
            <img src="/assets/img/devis.png" alt="" /><br />
            <p>Devis</p>
          </div>
          <div class="btn-horiz" @click="commandeCL">
            <img src="/assets/img/commande.png" alt="" /><br />
            <p>Commandes</p>
          </div>
        </div>
        <br />
        <div class="client_nom">
          <b
            ><p class="client-titre">{{ raisonsociale }}</p></b
          >

          <br />
          {{ adresse }}<br />
          {{ cp }} {{ ville }} <br />
        </div>

        <br />
        <table class="tableau2">
          <tr>
            <td style="width: 30%">Téléphone</td>
            <td class="border1">
              <b>{{ tel }}</b>
            </td>
          </tr>
          <tr>
            <td>Fax</td>
            <td>
              <b>{{ fax }}</b>
            </td>
          </tr>
          <tr>
            <td>E-mail</td>
            <td>
              <b>{{ mail }}</b>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Commentaire</td>
            <td class="border1">
              <b>{{ commentaire }}</b>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Activité</td>
            <td>
              <b>{{ activite }}</b>
            </td>
          </tr>
          <tr>
            <td>Qualité</td>
            <td>
              <b>{{ qualite }}</b>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>RIB</td>
            <td>
              <b>{{ rib }}</b>
            </td>
          </tr>
          <tr>
            <td>TVA intra-comm.</td>
            <td>
              <b>{{ tva }}</b>
            </td>
          </tr>
        </table>
      </div>
      <!-- ***********************    Div de modification/création   *******************************  -->
      <div v-if="mode == 'createModif'" class="client-createmodif">
        <div class="champtxt">
          <label for="raisonsociale">Raison sociale</label><br />
          <input type="text" name="raisonsociale" v-model="raisonsociale" />
        </div>
        <div class="champtxt">
          <label for="adresse">Adresse</label><br />
          <textarea name="adresse" rows="5" v-model="adresse"></textarea>
        </div>
        <div class="champtxt">
          <label for="cp">Code postal</label><br />
          <input type="text" name="cp" style="width: 30%" v-model="cp" />
        </div>
        <div class="champtxt">
          <label for="adresse">Ville</label><br />
          <input type="text" name="ville" v-model="ville" />
        </div>
        <div class="champtxt">
          <label for="tel">Telephone</label><br />
          <input type="text" name="tel" style="width: 50%" v-model="tel" />
        </div>
        <div class="champtxt">
          <label for="fax">Fax</label><br />
          <input type="text" name="fax" style="width: 50%" v-model="fax" />
        </div>
        <div class="champtxt">
          <label for="mail">Mail</label><br />
          <input type="text" name="mail" v-model="mail" />
        </div>
        <div class="champtxt">
          <label for="commentaire">Commentaire</label><br />
          <textarea name="commentaire" rows="7" v-model="commentaire"></textarea>
        </div>
        <div class="champtxt">
          <label for="activite">Activité</label><br />
          <input type="text" name="activite" v-model="activite" />
        </div>
        <div class="champtxt">
          <label for="qualite">Qualité</label><br />
          <input type="text" name="qualite" v-model="qualite" />
        </div>
        <div class="champtxt">
          <label for="rib">RIB</label><br />
          <input type="text" name="rib" v-model="rib" />
        </div>
        <div class="champtxt">
          <label for="tvaIntra">TVA intra-communautaire</label><br />
          <input type="text" name="tvaIntra" v-model="tvaIntra" />
        </div>
        <input type="hidden" v-model="idClient" />
        <div class="flex-align-horiz">
          <div class="btn-horiz" @click="createModifClient">
            <img src="/assets/img/valider.png" alt="" /><br />
            <p>Enregistrer</p>
          </div>
          <div class="btn-horiz" @click="annuleModifClient">
            <img src="/assets/img/annuler.png" alt="" /><br />
            <p>Annuler</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as UTILS from "../js/fonction";
import VueCookies from "vue-cookies";

export default {
  name: "client",
  data() {
    return {
      lstClient: [],
      lstRech: [],
      recherche: [],
      mode: "lst",
      idClient: 0,
      raisonsociale: "",
      adresse: "",
      cp: "",
      ville: "",
      tel: "",
      fax: "",
      mail: "",
      commentaire: "",
      rib: "",
      activite: "",
      qualite: "",
      tvaIntra: "",
      btnVisible: false,
      affRecherche: false,
      rechRS: "",
      rechCP: "",
      rechACT: "",
      action: "",
      codeEntreprise: "",
      enCharge: false,
    };
  },
  methods: {
    ChargeListAll() {
      this.btnVisible = false;
      this.enCharge=true
      axios({
        method: "post",
        url: this.$store.state.server + "/api/client/listAll",
        data: { codeEntreprise: this.codeEntreprise },
        headers: { authorization: VueCookies.get('jwtAtomGC')}
      })
        .then((reponse) => {
          console.log(reponse);
          this.lstClient = reponse.data;
          this.lstRech = reponse.data;
          console.log(this.lstClient[0].id);
          this.enCharge = false
        })
        .catch((erreur) => {
          console.log(erreur);
          this.enCharge = false
        });
    },
    RempliTabRecherche(id, valeur) {
      this.recherche.push({ id: id, client: valeur });
    },
    ChargeClient(id) {
      if (id != 0 && id != undefined) {
        for (const client of this.lstClient) {
          if (client.id == id) {
            this.raisonsociale = client.raisonsociale;
            this.adresse = client.adresse;
            this.cp = client.cp;
            this.ville = client.ville;
            this.tel = client.tel;
            this.fax = client.fax;
            this.mail = client.mail;
            this.commentaire = client.commentaire;
            this.rib = client.rib;
            this.activite = client.activite;
            this.qualite = client.qualite;
            this.tvaIntra = client.tva;
            this.idClient = id;
          }
        }
        this.btnVisible = true;
        this.mode = "lst";
        this.surbrillanceListe(id);
      }
    },
    surbrillanceListe(id) {
      let ListeDivClient = document.querySelectorAll(".client-card");
      console.log(ListeDivClient);
      for (let cl of ListeDivClient) {
        const tmpId = cl.attributes[1].nodeValue;
        //console.log(cl.__vueParentComponent.data)
        console.log("id: " + id, "tmpid:" + tmpId);
        if (id == tmpId) {
          cl.style.border = "2px solid black";
          cl.style.backgroundColor = "#fbf895";
          console.log("ok");
        } else {
          cl.style.border = "1px solid black";
          cl.style.backgroundColor = "rgb(247, 247, 247)";
          console.log("pas ok");
        }
      }
    },
    modifClient() {
      this.mode = "createModif";
      this.action = "modif";
    },
    annuleModifClient() {
      this.mode = "lst";
    },
    ajouterClient() {
      this.btnVisible = false;
      this.raisonsociale = "";
      this.adresse = "";
      this.cp = "";
      this.ville = "";
      this.tel = "";
      this.fax = "";
      this.mail = "";
      this.commentaire = "";
      this.rib = "";
      this.activite = "";
      this.qualite = "";
      this.tvaIntra = "";
      this.mode = "createModif";
      this.action = "ajout";
      this.btnVisible = true;
    },
    ouvreRecherche() {
      console.log("rrrr");
      if (this.affRecherche == false) {
        this.affRecherche = true;
      } else {
        this.affRecherche = false;
      }
    },
    lanceRecherche() {
      axios({
        method: "post",
        url: this.$store.state.server + "/api/client/rechClient",
        data: {
          rs: this.rechRS,
          cp: this.rechCP,
          act: this.rechACT,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get('jwtAtomGC')}
      })
        .then((reponse) => {
          this.lstRech = reponse.data;
          console.log(this.lstRech);
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    createModifClient() {
      if (this.raisonsociale == "" || this.raisonsociale == undefined) {
        alert("La raison sociale est obigatoire");
        return;
      }

      let url = "";

      if (this.action == "ajout") {
        console.log("ajout");
        url = this.$store.state.server + "/api/client/addClient";
      }

      if (this.action == "modif") {
        console.log("modif " + this.idClient);
        url = this.$store.state.server + "/api/client/modifClient";
      }
      console.log('TVA: ' + this.tvaIntra)
      if (this.tvaIntra == undefined) {this.tvaIntra = ''}
      axios({
        method: "post",
        url: url,
        data: {
          rs: this.raisonsociale,
          adresse: this.adresse,
          cp: this.cp,
          ville: this.ville,
          tel: this.tel,
          fax: this.fax,
          mail: this.mail,
          comment: this.commentaire,
          rib: this.rib,
          tva: this.tvaIntra,
          activite: this.activite,
          qualite: this.qualite,
          id: this.idClient,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get('jwtAtomGC')}
      })
        .then((reponse) => {
          this.lstRech = reponse.data;
          console.log(this.lstRech);
        })
        .catch((erreur) => {
          console.log(erreur);
        });

      this.mode = "lst";
    },
    async DeleteClient() {
      //verif si le client est utilisé

      let existe = await UTILS.verifExisteDansBase(
        ["devis", "infos"],
        "idclient",
        this.idClient,
        false
      );
      console.log(existe);

      if (existe == false) {
        console.log(existe, "faux");
        let supp = confirm(
          "Vous allez supprimer le client " +
            this.raisonsociale +
            "', voulez vous continuer ?"
        );
        if (supp == true) {
          axios({
            method: "post",
            url: this.$store.state.server + "/api/client/deleteClient",
            data: { idCL: this.idClient },
            headers: { authorization: VueCookies.get('jwtAtomGC')}
          })
            .then((reponse) => {
              if (reponse.data == "client deleted") {
                this.ChargeListAll();
              }
            })
            .catch((erreur) => {
              console.log(erreur);
            });
        }
      } else {
        console.log(existe, "true");
        alert("Ce client est utilisé. Suppression impossible.");
      }
    },
    infoComm() {
      console.log("emit");
      this.$emit("change-userpage", ["infos", this.raisonsociale,0]);
    },
    devisCL() {
      console.log("emit");
      this.$emit("change-userpage", ["devis", this.raisonsociale,0]);
    },
    commandeCL() {
      console.log("emit");
      this.$emit("change-userpage", ["commande", this.raisonsociale,0]);
    },
  },

  async mounted() {
    this.codeEntreprise = await VueCookies.get("atom_code_entreprise");
    this.ChargeListAll();
  },
};
</script>

<style scoped lang="scss">
.client {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background: url("/assets/fond/fond11.jpg");
  background-size: cover;
  margin: 110px 0 0 0;

  .btn-liste {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .client-liste {
    flex-basis: 45%;
    border: lightgray 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2px;
    overflow-y: scroll;
    max-height: calc(100vh - 118px);
    min-height: calc(100vh - 118px);
    margin: 0 0 0 5px;
    backdrop-filter: blur(1px);

    .client-card {
      margin: 5px 0 0 0;
      width: 95%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border: lightgray 1px solid;
      cursor: pointer;

      padding: 5px;
      background-color: rgb(247, 247, 247);
      /*background-color: var(--fond_card);
      color: var(--text_card);*/
      transition: all 0.2s;

      &:hover {
        /*background: linear-gradient(azure, rgb(206, 248, 248));*/
        background: rgb(253, 255, 158);
        /* background: linear-gradient(
          40deg,
          rgba(253, 255, 158, 1) 0%,
          rgba(255, 255, 255, 1) 50%,
          rgba(253, 255, 158, 1) 100%
        ); */
        box-shadow: 3px 3px 5px 0px #808080;
        scale: 1.01;
        color: black;
      }

      .client-titre {
        margin: 5px 0;
        font-size: 1.2em;
        font-weight: bold;
      }

      .client-card-detail {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        .d1 {
          flex-basis: 50%;
        }
        .d2 {
          flex-basis: 49%;
        }
        .d3 {
          flex-basis: 33%;
        }
      }
    }
  }
  .client-detail {
    flex-basis: 53%;

    .client_nom {
      text-align: center;
      background-color: rgb(242, 242, 242);
      width: 80%;
      max-width: 600px;
      border: 1px solid gray;
      margin: 0 auto;
      padding: 10px 0;

      .client-titre {
        margin: 5px 0;
        font-size: 1.2em;
        font-weight: bold;
        text-decoration: underline;
        text-align: center;
      }
    }

    .tableau2 {
      border: 1px solid gray;
     width: 80%;
      max-width: 600px;
      margin: 0 auto;
      background-color: rgb(242, 242, 242);
      opacity: 0.8;

      td {
        border-bottom: 1px solid lightgray;
        padding: 1em;
        text-align: left;
      }
    }
  }

  .client-createmodif {
    border: lightgray 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 7px;
    background-color: rgba(37, 37, 37, 0.85);
  }

  .flex-align-horiz {
    display: flex;
    flex-direction: row;
    justify-content: space-start;
    align-items: center;
  }
}

@media screen and (max-width: 1055px) {
  .client {
    flex-direction: column;
  }
}
</style>

<template>
  <div class="container">
    <h1 class="titre">Listing des {{ type }}</h1>

    <div class="enLigneCenter no-print">
      <div class="btn-horiz" @click="imprime">
        <img src="/assets/img/imprime.png" alt="" /><br />
        <p>Imprimer</p>
      </div>
      <div
        class="btn-horiz"
        @click="
          () => {
            affRech = true;
          }
        "
      >
        <img src="/assets/img/rechercher.png" alt="" /><br />
        <p>Recherche</p>
      </div>
      <div class="btn-horiz" @click="actualise">
        <img src="/assets/img/actualiser.gif" alt="" /><br />
        <p>Actualiser</p>
      </div>
    </div>

    <div v-if="affRech == true" class="recherche no-print">
      <table style="min-width: 300px">
        <tr v-if="type == 'commandes' || type == 'factures'">
          <td>N° Commande</td>
          <td><input type="text" name="rechNumC" v-model="rechNumC" /></td>
        </tr>
        <tr>
          <td>N° Affaire</td>
          <td><input type="text" name="rechNumA" v-model="rechNumA" /></td>
        </tr>
        <tr>
          <td>Client</td>
          <td><input type="text" name="rechRS" v-model="rechRS" /></td>
        </tr>
        <tr>
          <td>N° devis</td>
          <td><input type="text" name="rechNumD" v-model="rechNumD" /></td>
        </tr>
        <tr v-if="type == 'factures'">
          <td>N° Facture</td>
          <td><input type="text" name="rechNumF" v-model="rechNumF" /></td>
        </tr>
        <tr>
          <td>
            Par date
          </td>
          <td>
            <input type="checkbox" name="chkDate" v-model="chkDate" />
          </td>
        </tr>
        <tr v-if="chkDate">
          <td>Date de début</td>
          <td><input type="date" v-model="dateDebut" /></td>
        </tr>
        <tr v-if="chkDate">
          <td>Date de fin</td>
          <td><input type="date" v-model="dateFin" /></td>
        </tr>
        <tr>
          <td
            style="text-align: right; cursor: pointer"
            @click="
              () => {
                affRech = false;
              }
            "
          >
            <u>Fermer</u>
          </td>
          <td
            style="text-align: right; cursor: pointer"
            @click="recherche"
          >
            <u>Rechercher</u>
          </td>
        </tr>
      </table>
    </div>

    <table class="tableau">
      <tr class="T_entete">
        <td>N° {{ type }}</td>
        <td>N° Affaire</td>
        <td v-if="type != 'devis'">N° devis</td>
        <td v-if="type == 'factures'">N° commande</td>
        <td v-if="type == 'devis'">Désignation / Référence</td>
        <td>Client</td>
        <td>Date</td>
        <td>Montant</td>
        <td v-if="type == 'commandes'">Facturé ?</td>
        <td v-if="type == 'factures'">Montant Réglé</td>
        <td v-if="type == 'factures'" id="reste">Reste à Régler</td>
        <td v-if="type == 'factures'">Date échéance</td>
        <td></td>
      </tr>
      <tr class="T_ligne" v-for="ligne of liste" :key="ligne.id">
        <!-- gestion du n° en fonction du type -->
        <td v-if="type == 'devis'"><b>{{ ligne.id }}</b></td>
        <td v-if="type == 'commandes'"><b>{{ ligne.numcde }}</b></td>
        <td v-if="type == 'factures'"><b>{{ ligne.numfac }}</b></td>
        <td>{{ ligne.numaffaire }}</td>
        <td v-if="type != 'devis'">{{ ligne.iddevis }}</td>
        <td v-if="type == 'factures'">{{ ligne.numcommande }}</td>
        <td v-if="type == 'devis'">{{ ligne.designation }}</td>
        <td>{{ ligne.nomclient }}</td>
        <td v-if="type == 'devis'">{{ FormatDate(ligne.datedevis) }}</td>
        <td v-if="type == 'commandes'">{{ FormatDate(ligne.datecommande) }}</td>
        <td v-if="type == 'factures'">{{ FormatDate(ligne.datefacture) }}</td>
        <td v-if="type == 'devis'">{{ ligne.montant }}</td>
        <td v-if="type != 'devis'">{{ ligne.total }}</td>
        <td v-if="type == 'commandes'">{{ VerifFacture(ligne.numfacture) }}</td>
        <td v-if="type == 'factures'">{{ ligne.mntregle }}</td>
        <td v-if="type == 'factures'">
          <b>{{ ResteARegler(ligne.total, ligne.mntregle) }}</b>
        </td>
        <td v-if="type == 'factures'">{{ FormatDate(ligne.dateecheance) }}</td>
        
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import VueCookies from "vue-cookies";
import * as FCT from "../js/fonction";
export default {
  data() {
    return {
      type: "",
      codeEntreprise: "",
      liste: [],
      affRech: false,
      chkDate: false,
      dateDebut: new Date(),
      dateFin: new Date(),
      rechNumA: "",
      rechNumC: "",
      rechNumD: "",
      rechNumF: "",
      rechRS: "",
    };
  },
  async mounted() {
    this.type = VueCookies.get("atom_liste_typ");
    this.codeEntreprise = await VueCookies.get("atom_code_entreprise");
    console.log(this.type);

    switch (this.type) {
      case "devis":
        this.ChargeListDevis();
        break;
      case "commandes":
        this.ChargeListCde();
        break;
      case "factures":
        this.ChargeListFact();
        break;
    }
  },
  methods: {
    ChargeListDevis() {
      console.log("CE: " + this.codeEntreprise);
      axios({
        method: "post",
        url: this.$store.state.server + "/api/Listing/listDevis",
        data: { 
          codeEntreprise: this.codeEntreprise,
          numD: this.rechNumD,
          numA: this.rechNumA,
          RS: this.rechRS,
          parDate: this.chkDate,
          dateDebut: this.dateDebut,
          dateFin: this.dateFin,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.liste = reponse.data.devis;
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    ChargeListCde() {
      console.log("CE1: " + this.codeEntreprise);
      var m1 = "";
      //if (mode == 'facture') { m1 = 'Oui'}
      axios({
        method: "post",
        url: this.$store.state.server + "/api/Listing/listCde",
        data: { 
          codeEntreprise: this.codeEntreprise,
          numC: this.rechNumC,
          numD: this.rechNumD,
          numA: this.rechNumA,
          RS: this.rechRS,
          parDate: this.chkDate,
          dateDebut: this.dateDebut,
          dateFin: this.dateFin,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.liste = reponse.data.lst;
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    ChargeListFact() {
      console.log("CE1: " + this.codeEntreprise);
      var m1 = "";
      //if (mode == 'facture') { m1 = 'Oui'}
      axios({
        method: "post",
        url: this.$store.state.server + "/api/Listing/listFact",
        data: { 
          codeEntreprise: this.codeEntreprise,
          numF: this.rechNumF,
          numC: this.rechNumC,
          numD: this.rechNumD,
          numA: this.rechNumA,
          RS: this.rechRS,
          parDate: this.chkDate,
          dateDebut: this.dateDebut,
          dateFin: this.dateFin,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.liste = reponse.data.lst;
          const infoCde = reponse.data.infoCde;

          this.liste.forEach((el) => {
            let id = el.idcde;
            for (let i = 0; i < infoCde.length; i++) {
              console.log("id: " + id, infoCde[i]);
              if ((id = infoCde[i].id)) {
                el.numcde = infoCde[i].numcde;
              }
            }
          });
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    actualise() {
      location.reload();
    },
    imprime() {
      window.print();
    },
    recherche() {
      switch (this.type) {
        case "devis":
          this.ChargeListDevis();
          break;
        case "commandes":
          this.ChargeListCde();
          break;
        case "factures":
          this.ChargeListFact();
          break;
      }
    },
    FormatDate(d1) {
      if (d1 != "" && d1 != undefined) {
        let D = new Date(d1);
        D = D.toLocaleDateString();
        return D;
      } else {
        let D2 = new Date();
        D2 = D2.toLocaleString();
        return D2;
      }
    },
    VerifFacture(num) {
      if (num > 0) {
        return num;
      } else {
        return "_";
      }
    },
    ResteARegler(mnt, regle) {
      return parseFloat(mnt) - parseFloat(regle);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: "calibri", "Arial";
  .titre {
    font-size: 2em;
    font-weight: 600;
    margin: 5px 0 50px 0;
  }

  .tableau {
    width: 95%;
    border: 1px solid gray;
    .T_entete {
      background-color: lightblue;
      text-align: center;

      td {
        padding: 0.7em 1em;
        font-weight: 500;
        border-bottom: 1px solid gray;
      }
    }

    .T_ligne {
      td {
        padding: 0.4em 1em;
        text-align: center;
        border-left: 1px solid lightgray;
      }
      &:nth-child(even) {
        background-color: rgb(231, 249, 255);
      }
    }
  }
}
</style>

<template>
  <div class="cde">
    <div class="avoir" id="avoir">
      <!-- Panel de création d'un avoir -->
      <label for="c_numcde">montant de l'avoir</label>
      <input type="text" v-model="mntavoir" class="champtxt" />
      <label for="d_date">Date de l'avoir</label>
      <input type="date" name="d_date" v-model="dateavoir" />

      <div class="champtxt">
        <label for="commentaire">Texte de l'avoir</label><br />
        <textarea name="commentaire" rows="4" v-model="txtavoir"></textarea>
      </div>
      <div class="btn-liste">
        <div
          class="btn-horiz"
          @click="
            () => {
              confirmAvoir = true;
            }
          "
        >
          <img src="/assets/img/valider.png" alt="" /><br />
          <p>Enregistrer</p>
        </div>
        <div class="btn-horiz" @click="affAvoir(false)">
          <img src="/assets/img/annuler.png" alt="" /><br />
          <p>Annuler</p>
        </div>
      </div>
      <div class="alert" v-if="confirmAvoir == true">
        <span
          >Vous allez créer un avoir. Cette action est irréverssible. Voulez
          vous continuer ?</span
        >
        <div>
          <button
            class="btnAlert"
            @click="
              () => {
                confirmAvoir = false;
              }
            "
          >
            Annuler
          </button>
          <button class="btnAlert" @click="genereAvoir()">Confirmer</button>
        </div>
      </div>
    </div>

    <!--********* Partie gauche: liste des cde **********-->

    <div class="lst-cde">
      <div>
        <button class="btn_texte" @click="change_modeListe('C')">
          Liste des commandes
        </button>
        <button class="btn_texte" @click="change_modeListe('F')">
          Liste des Factures
        </button>
      </div>
      <div class="titreEcran">Liste des {{ modeListe }}</div>
      <div class="btn-liste">
        <div
          class="btn-horiz"
          @click="createCdeFact"
          v-if="modeListe != 'commandes'"
        >
          <img src="/assets/img/ajouter.png" alt="" /><br />
          <p>Créer</p>
        </div>
        <div class="btn-horiz" @click="ouvreRecherche">
          <img src="/assets/img/rechercher.png" alt="" /><br />
          <p>Recherche</p>
        </div>
        <div class="btn-horiz" @click="ChargeListAll('', '', 0, 0)">
          <img src="/assets/img/actualiser.gif" alt="" /><br />
          <p>Actualiser</p>
        </div>
        <div v-if="affRech == true" class="recherche">
          <table>
            <tr>
              <td>N° Commande</td>
              <td><input type="text" name="rechNumC" v-model="rechNumC" /></td>
            </tr>
            <tr>
              <td>N° Affaire</td>
              <td><input type="text" name="rechNumA" v-model="rechNumA" /></td>
            </tr>
            <tr>
              <td>Raison sociale</td>
              <td><input type="text" name="rechRS" v-model="rechRS" /></td>
            </tr>
            <tr>
              <td>N° devis initial</td>
              <td><input type="text" name="rechNumD" v-model="rechNumD" /></td>
            </tr>
            <tr>
              <td>N° Facture</td>
              <td><input type="text" name="rechNumF" v-model="rechNumF" /></td>
            </tr>

            <tr>
              <td
                style="text-align: right; cursor: pointer"
                @click="ouvreRecherche"
              >
                <u>Annuler</u>
              </td>
              <td
                style="text-align: right; cursor: pointer"
                @click="lanceRecherche"
              >
                <u>Rechercher</u>
              </td>
            </tr>
          </table>
        </div>
        <div class="encharge" v-if="enCharge == true"></div>
      </div>

      <div v-if="cde_mode == 'lst'" class="lstcde_container">
        <div class="cde-liste">
          <!--********* liste des cde **********-->
          <div class="alert" v-if="msgErrCde != ''">
            {{ msgErrCde }}
          </div>
          <div
            v-for="cde in lstRech"
            :key="cde"
            class="cde-card"
            v-bind="{ id: cde.id }"
          >
            <div
              class="enLigne-2"
              @click="
                () => {
                  modeListe == 'commandes'
                    ? ChargeLignecde(cde.id)
                    : ChargeLignefacture(cde.numfac);
                }
              "
              style=""
            >
              <div class="cde-titre">
                <b v-if="modeListe == 'commandes'"
                  >Commande n° {{ cde.numcde }}</b
                ><b v-else>Facture n° {{ cde.numfac }}</b> pour le client
                <b>{{ cde.nomclient }}</b>
                <br />
                <span v-if="modeListe == 'commandes'" style="font-size: 0.8em"
                  >Du: {{ FormatDate(cde.datecommande) }}</span
                ><span v-else style="font-size: 0.8em"
                  >Du: {{ FormatDate(cde.datefacture) }}</span
                >
                <div style="font-size: 0.75em; margin: 5px 0">
                  N° affaire: <b>{{ cde.numaffaire }}</b> |
                  <span v-if="modeListe == 'commandes'"
                    >issue du devis n° <b>{{ cde.iddevis }}</b></span
                  >
                  <span v-else-if="cde.numcommande != '_'"
                    >Commande client n° <b>{{ cde.numcommande }}</b></span
                  >
                  <p v-if="cde.idcde == -1" style="color: #b0b0b0">
                    Facture manuelle
                  </p>
                </div>
              </div>
              <div class="enLignePetit" v-if="modeListe == 'commandes'">
                <div class="tab_img" @click="chargecde(cde.id)">
                  <img
                    src="/assets/img/modifier.gif"
                    alt=""
                    title="Modifier la commande"
                  />
                </div>
                <div class="tab_img" @click="Affdeletecde(cde.id)">
                  <img
                    src="/assets/img/supprimer.png"
                    alt=""
                    title="Supprimer la commande"
                  />
                </div>
              </div>
              <div v-else class="enLignePetit">
                <div
                  v-if="cde.definitive == ''"
                  class="tab_img"
                  @click="chargeFacture(cde.id)"
                >
                  <img
                    src="/assets/img/modifier.gif"
                    alt=""
                    title="Modifier la Facture"
                  />
                </div>
                <div v-else class="tab_img" style="cursor: none; scale: 1.5">
                  <img
                    src="/assets/img/valide2.png"
                    alt=""
                    title="La facture est validée, elle ne peut plus être modifiée"
                  />
                </div>
                <div
                  v-if="cde.numavoir > 0"
                  class="tab_img"
                  style="cursor: none; scale: 1.5"
                >
                  <img
                    src="/assets/img/avoir.png"
                    alt=""
                    title="La facture est validée, elle ne peut plus être modifiée"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="cde.numfacture > 0"
              class="detail_fact"
              @click="ChargeLignefacture(cde.numfacture)"
            >
              - FACTURE N° {{ cde.numfacture }}
            </div>
            <div class="alert" v-if="cde.id == delComm">
              <span
                >Vous allez supprimer la commande sélectionnée. Voulez vous
                continuer ?</span
              >
              <div>
                <button
                  class="btnAlert"
                  @click="
                    () => {
                      delComm = -1;
                    }
                  "
                >
                  Annuler
                </button>
                <button class="btnAlert" @click="deletecde(cde.id)">
                  Confirmer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-liste">
          <div v-if="page > 1" class="btn-horiz" @click="Precedent">
            <img src="/assets/img/precedent.gif" alt="" /><br />
            <p>Précédent</p>
          </div>
          <div
            v-if="nbEnreg + 1 > page * 10"
            class="btn-horiz"
            @click="Suivant"
          >
            <img src="/assets/img/suivant.gif" alt="" /><br />
            <p>Suivant</p>
          </div>
        </div>
      </div>

      <!--***** creation d'une facture manuelle **** -->

      <div v-else class="cde-createmodif" style="width: 90%">
        <div
          style="display: flex; flex-direction: column; width: 100%"
          v-if="modeCrateModif == 'create'"
        >
          <label for="d_client">Client</label>
          <select
            name="d_client"
            id="d_client"
            v-model="factManu_RS"
            style="width: 70%"
          >
            <option
              v-for="cl in lstClient"
              :key="cl.id"
              :value="cl.id"
              @click="recupClient(cl.id, cl.raisonsociale)"
            >
              {{ cl.raisonsociale }}
            </option>
          </select>
        </div>

        <label for="c_numcde">N° commande</label>
        <input
          type="text"
          name="c_numcde"
          v-model="factManu_numcde"
          class="champtxt"
        />
        <label for="c_numcde">N° Affaire</label>
        <input
          type="text"
          name="c_numcde"
          v-model="factManu_numaffaire"
          class="champtxt"
        />
        <label for="d_date">Date de la facture</label>
        <input type="date" name="d_date" v-model="factManu_date" />
        <label for="c_txtva">Taux de TVA</label>
        <input
          type="text"
          name="c_txtva"
          v-model="factManu_txtva"
          class="champtxt"
          style="width: 80px; text-align: center"
        />
        <div class="champtxt">
          <label for="commentaire">Commentaire</label><br />
          <textarea
            name="commentaire"
            rows="4"
            v-model="factManu_comment"
          ></textarea>
        </div>

        <div class="btn-liste">
          <div class="btn-horiz" @click="ModifEnteteFacture()">
            <img src="/assets/img/valider.png" alt="" /><br />
            <p>Enregistrer</p>
          </div>
          <div
            class="btn-horiz"
            @click="
              () => {
                cde_mode = 'lst';
                factManu_valide = false;
                msgErrFact = '';
              }
            "
          >
            <img src="/assets/img/annuler.png" alt="" /><br />
            <p>Annuler</p>
          </div>
        </div>
        <div class="alert" v-if="factManu_valide == true">
          <span
            >Vous allez créer une facture. Cette action est irréverssible.
            Voulez vous continuer ?</span
          >
          <div>
            <button
              class="btnAlert"
              @click="
                () => {
                  factManu_valide = false;
                  msgErrFact = '';
                }
              "
            >
              Annuler
            </button>
            <button class="btnAlert" @click="CreateFactureManuelle()">
              Confirmer
            </button>
          </div>
        </div>
        <div class="alert" v-if="msgErrFact != ''">
          {{ msgErrFact }}
        </div>
      </div>
    </div>

    <!-- ***************  lignes de la commande ou de la facture ***************** -->

    <div v-if="lignesVisible == true" class="lignes-cde">
      <div v-if="ligneMode != 'modif'" style="width: 98%">
        <div class="enLigne-2">
          <div class="enLignePetit">
            <div
              class="btn-horiz"
              @click="ajouterLignecde"
              v-if="
                numFacture == 0 || (modeAff == 'fact' && infosFactValide == '')
              "
            >
              <img src="/assets/img/ajouter.png" alt="" /><br />
              <p>Créer une ligne</p>
            </div>
            <div class="btn-horiz" @click="imprimeCde" v-if="modeAff == 'cde'">
              <img src="/assets/img/imprime.png" alt="" /><br />
              <p>Imprimer la commande</p>
            </div>
            <div
              class="btn-horiz"
              @click="imprimeFacture"
              v-if="modeAff == 'fact'"
            >
              <img src="/assets/img/imprime.png" alt="" /><br />
              <p>Imprimer la facture</p>
            </div>
            <div
              class="btn-horiz"
              @click="affAvoir(true)"
              v-if="modeAff == 'fact' && fact_mntavoir == 0"
            >
              <img src="/assets/img/avoir.png" alt="" /><br />
              <p>Avoir sur la facture</p>
            </div>
            <div
              class="petit-btn"
              @click="infosFacture"
              style="margin: 0 0 0 10px"
              v-if="modeAff == 'fact'"
            >
              <img src="/assets/img/modifier.gif" alt="" /><br />
            </div>
          </div>

          <div class="cde-titre-ligne">
            {{ infos_cde }}
          </div>
          <div v-if="nbLignes > 0">
            <div
              v-if="numFacture == 0"
              class="btn-horiz"
              @click="
                () => {
                  txtConfirm =
                    'Vous allez générer une facture pour la commande en cours. Cette action est irréverssible. Voulez vous continuer ?';
                  confirmGenereFacture = true;
                }
              "
              style="margin: 0 0 0 10px"
            >
              <img src="/assets/img/facture1.png" alt="" /><br />
              <p>Générer une facture</p>
            </div>
            <div
              class="btn-horiz"
              @click="
                () => {
                  txtConfirm =
                    'Vous allez Valider cette facture. Celle-ci ne sera plus modifiable. Voulez vous continuer ?';
                  confirmeValideFacture = true;
                }
              "
              v-if="modeAff == 'fact' && infosFactValide == ''"
            >
              <img src="/assets/img/valide2.png" alt="" /><br />
              <p>Valider la facture</p>
            </div>
            <div v-else-if="modeAff == 'fact'">
              <p
                style="
                  font-weight: 600;
                  padding: 6px;
                  border: 1px solid #0d0d0d;
                  color: #f0f0f0;
                "
              >
                {{ infosFactValide }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="alert"
          v-if="confirmGenereFacture == true || confirmeValideFacture == true"
        >
          <span>{{ txtConfirm }}</span>
          <div>
            <button
              class="btnAlert"
              @click="
                () => {
                  confirmGenereFacture = false;
                  confirmeValideFacture = false;
                }
              "
            >
              Annuler
            </button>
            <button
              v-if="confirmGenereFacture == true"
              class="btnAlert"
              @click="genereFacture()"
            >
              Générer
            </button>
            <button
              v-if="confirmeValideFacture == true"
              class="btnAlert"
              @click="valideFacture()"
            >
              Valider
            </button>
          </div>
        </div>
        <div class="infosFacture" id="infosFacture">
          <div>
            <label for="f_regle">Montant réglé</label>
            <input
              type="text"
              name="f_regle"
              v-model="f_regle"
              class="champtxt"
            />
          </div>
          <div>
            <label for="f_ech">Date Echeance</label>

            <input type="date" name="f_ech" v-model="f_ech" class="champtxt" />
          </div>
          <div>
            <label for="f_infos">Commentaire facture</label><br />
            <textarea name="f_infos" rows="4" v-model="f_infos"></textarea>
          </div>
          <div class="btn-liste">
            <div class="petit-btn" @click="annuleInfosFacture">
              <img src="/assets/img/annuler.png" alt="" /><br />
            </div>
            <div class="petit-btn" @click="valideInfosFacture">
              <img src="/assets/img/valider.png" alt="" /><br />
            </div>
          </div>
        </div>
        <div v-for="ligne in lstLignecde" :key="ligne.id" class="detail-lignes">
          <table class="table-liste-ligne">
            <tr class="entete-tr">
              <td style="width: 20%">Désignation</td>
              <td style="width: 15%">Référence</td>
              <td style="width: 12%">Code</td>
              <td>Quantité</td>
              <td>Unité</td>
              <td>P.U.H.T</td>
              <td>Total H.T.</td>
              <td v-if="modeAff != 'fact'">Date liv.</td>
            </tr>

            <tr>
              <td>{{ ligne.designation }}</td>
              <td>{{ ligne.reference }}</td>
              <td>{{ ligne.code }}</td>
              <td>{{ ligne.quantite }}</td>
              <td>{{ ligne.unite }}</td>
              <td>{{ ligne.pu }}</td>
              <td>{{ ligne.total }}</td>
              <td v-if="modeAff != 'fact'">
                {{ FormatDate(ligne.dateliv, true) }}
              </td>
            </tr>

            <tr v-if="ligne.commentaire != ''">
              <td style="font-size: 0.8em; padding: 12px 0 0 10px" colspan="8">
                <div style="white-space: pre">{{ ligne.commentaire }}</div>
              </td>
            </tr>
          </table>

          <div class="btn-liste" v-if="modeAff == 'fact' || numFacture == 0">
            <div
              class="petit-btn"
              @click="ModifLigne(ligne.id)"
              v-if="infosFactValide == ''"
            >
              <img src="/assets/img/modifier.gif" alt="" /><br />
            </div>
            <div
              class="petit-btn"
              @click="AffSupprLigne(ligne.id)"
              v-if="infosFactValide == ''"
            >
              <img src="/assets/img/supprimer.png" alt="" /><br />
            </div>
          </div>
          <div class="alert" v-if="ligne.id == delLigne">
            <span
              >Vous allez supprimer la ligne sélectionnée. Voulez vous continuer
              ?</span
            >
            <div>
              <button
                class="btnAlert"
                @click="
                  () => {
                    delLigne = -1;
                  }
                "
              >
                Annuler
              </button>
              <button class="btnAlert" @click="SupprLigne(ligne.id)">
                Confirmer
              </button>
            </div>
          </div>
        </div>
        <div v-if="totalHT > 0" style="float: right; padding: 0 20px 0 0">
          <table class="tabTotal">
            <tr>
              <td>Total HT</td>
              <td>
                <b> {{ totalHT }} </b>
              </td>
            </tr>
            <tr>
              <td>TVA ({{ fact_txtva }}%)</td>
              <td>
                <b> {{ TVA }} </b>
              </td>
            </tr>
            <tr style="background-color: lightblue">
              <td>Total TTC</td>
              <td>
                <b> {{ totalTTC }} </b>
              </td>
            </tr>
            <tr v-if="f_regle > 0" style="background-color: transparent">
              <td></td>
              <td>________</td>
            </tr>

            <tr v-if="f_regle > 0" style="background-color: lightgreen">
              <td>Réglé TTC</td>
              <td>
                <b> {{ f_regle }}</b>
              </td>
            </tr>
            <tr v-if="f_regle > 0" style="background-color: lightyellow">
              <td>Reste à payer TTC</td>
              <td>
                <b> {{ (totalTTC - f_regle).toFixed(2) }}</b>
              </td>
            </tr>
          </table>
        </div>
        <div
          class="detail_avoir"
          v-if="fact_numavoir != 0 && fact_mntavoir != 0"
        >
          <div>
            <p>Avoir sur la facture:</p>
            <table>
              <tr>
                <td>N° Avoir</td>
                <td>Montant</td>
              </tr>
              <tr>
                <td>
                  <b>{{ fact_numavoir }}</b>
                </td>
                <td>
                  <b>{{ fact_mntavoir }}</b>
                </td>
              </tr>
            </table>
          </div>

          <div class="btn-horiz" @click="imprimeAvoir">
            <img src="/assets/img/imprime.png" alt="" /><br />
            <p>Imprimer l'avoir</p>
          </div>
        </div>
      </div>

      <!-- ****************  creation lignes de commande ou de facture *********************** -->

      <div v-else style="border: none" class="cde-createmodif">
        <div class="enLigne" style="width: 100%">
          <div
            class="btn-horiz"
            @click="
              () => {
                depuisArt = true;
              }
            "
          >
            <img src="/assets/img/articles.png" alt="" /><br />
            <p>Depuis un article</p>
          </div>
          <div v-show="depuisArt == true">
            <div class="champtxt">
              <label for="ligne_des">Sélectionnez un article</label><br />
              <select
                style="width=70%"
                name="lig_art"
                id="lig_art"
                v-model="lig_art"
              >
                <option
                  v-for="art in lstArticle"
                  :key="art.id"
                  @click="AjArticle(art.id)"
                >
                  {{ art.designation }} ({{ art.reference }})
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="champtxt">
          <label for="ligne_des">Désignation</label><br />
          <input type="text" name="ligne_des" v-model="ligne_des" />
        </div>
        <div class="champtxt">
          <label for="ligne_ref">Référence</label><br />
          <input type="text" name="ligne_ref" v-model="ligne_ref" />
        </div>
        <div class="champtxt">
          <label for="ligne_code">Code</label><br />
          <input type="text" name="ligne_code" v-model="ligne_code" />
        </div>
        <div class="champtxt">
          <label for="ligne_commentaire">Commentaire</label><br />
          <textarea
            name="ligne_commentaire"
            rows="7"
            v-model="ligne_commentaire"
          ></textarea>
        </div>
        <div class="champtxt">
          <label for="ligne_qte">Quantité</label><br />
          <input
            type="text"
            name="ligne_qte"
            style="width: 30%"
            v-model="ligne_qte"
            @change="CalculTotal"
          />
        </div>
        <div class="champtxt">
          <label for="ligne_unite">Unité</label><br />
          <input
            type="text"
            name="ligne_unite"
            style="width: 30%"
            v-model="ligne_unite"
          />
        </div>
        <div class="champtxt">
          <label for="ligne_pu">Prix Unitaire HT</label><br />
          <input
            type="text"
            name="ligne_pu"
            style="width: 30%"
            v-model="ligne_pu"
            @change="CalculTotal"
          />
        </div>
        <div class="champtxt">
          <label for="ligne_total">Total HT</label><br />
          <input
            type="text"
            name="ligne_total"
            style="width: 30%"
            v-model="ligne_total"
          />
        </div>
        <div class="champtxt" v-if="modeAff != 'fact'">
          <label for="ligne_dateliv">Date de livraison prévue</label><br />
          <input
            type="date"
            name="ligne_dateliv"
            style="width: 30%"
            v-model="ligne_dateliv"
          />
        </div>
        <div class="btn-liste">
          <div class="btn-horiz" @click="EnregLigne">
            <img src="/assets/img/valider.png" alt="" /><br />
            <p>Enregistrer</p>
          </div>
          <div class="btn-horiz" @click="AnnuleLigne">
            <img src="/assets/img/annuler.png" alt="" /><br />
            <p>Annuler</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueCookies from "vue-cookies";
import * as FCT from "../js/fonction.js";

export default {
  name: "commande",
  data() {
    return {
      lstcde: [],
      lstRech: [],
      lstLignecde: [],
      lstClient: [],
      lstEtat: [],
      lstArticle: [],
      affRech: false,
      rechNumC: "",
      rechNumA: 0,
      rechNumD: 0,
      rechRS: "",
      rechNumF: 0,
      page: 1,
      nbEnreg: 0,
      lignePremId: 0,
      ligneMode: "lst",
      ligneAction: "ajout",
      idcde: 0,
      //variable des lignes de cde
      ligne_des: "",
      ligne_ref: "",
      ligne_code: "",
      ligne_qte: 0,
      ligne_unite: "",
      ligne_pu: 0,
      ligne_total: 0,
      ligne_id: 0,
      ligne_commentaire: "",
      ligne_dateliv: "",
      infos_cde: "",
      cde_mode: "lst",
      cde_action: "ajout",
      c_numcde: "",
      c_date: "",
      d_commentaire: "",
      depuisArt: false,
      login: "",
      iduser: 0,
      nbLignes: 0,
      idcde: 0,
      totalHT: 0,
      TVA: 0,
      totalTTC: 0,
      numFacture: 0,
      lignesVisible: false,
      codeEntreprise: "",
      f_regle: 0,
      f_ech: "",
      f_infos: "",
      delComm: -1,
      delLigne: -1,
      numAffaire: 0,
      msgErrCde: "",
      enCharge: false,
      modeAff: "cde",
      confirmGenereFacture: false,
      confirmeValideFacture: false,
      confirmAvoir: false,
      mntavoir: 0,
      dateavoir: new Date(),
      txtavoir: "",
      fact_numavoir: 0,
      fact_mntavoir: 0,
      modeCrateModif: "create",
      d_CdeOuFact: "cde",
      modeListe: "commandes",
      factManu_RS: "",
      factManu_idclient: 0,
      factManu_numcde: "",
      factManu_numaffaire: "",
      factManu_date: new Date(),
      factManu_comment: "",
      factManu_txtva: 20,
      factManu_valide: false,
      msgErrFact: "",
      infosFactValide: "",
      txtConfirm: "",
      fact_txtva: 20,
      fact_numModif: 0,
    };
  },
  props: ["clientCde", "devisRechId"],
  methods: {
    ChargeListAll(RS, numC, numA, numD, numF) {
      this.msgErrCde = "";
      this.lignesVisible = false;
      console.log("charge");
      this.enCharge = true;
      let url = "";
      if (this.modeListe === "commandes") {
        url = "/api/Commandes/listAll";
      } else {
        url = "/api/Factures/listAll";
      }

      axios({
        method: "post",
        url: this.$store.state.server + url,
        data: {
          page: this.page,
          RS: RS,
          numC: numC,
          numA: numA,
          numD: numD,
          numF: numF,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.lstcde = reponse.data.lst;
          this.lstRech = reponse.data.lst;
          this.lstArticle = reponse.data.lstArticle;
          this.lstClient = reponse.data.lstClient;
          this.nbEnreg = this.lstRech.length;
          console.log("chgt terminé");
          this.enCharge = false;
        })
        .catch((erreur) => {
          console.log(erreur);
          this.enCharge = false;
        });
    },
    FormatDate(d1, sansHeure) {
      if (d1 != "" && d1 != undefined) {
        let D = new Date(d1);
        D = D.toLocaleDateString();

        return D;
      } else {
        let D2 = new Date();
        D2 = D2.toLocaleString();
        return D2;
      }
    },
    FormatNombre(nb, decimales) {
      let nb1 = 0;
      nb1 = parseFloat(nb);
      nb1 = nb1.toFixed(decimales);
      return nb1;
    },
    surbrillanceListe(id) {
      let ListeDivcde = document.querySelectorAll(".cde-card");
      console.log(ListeDivcde);
      for (let dev of ListeDivcde) {
        const tmpId = dev.attributes[1].nodeValue;
        //console.log(cl.__vueParentComponent.data)
        console.log("id: " + id, "tmpid:" + tmpId);
        if (id == tmpId) {
          dev.style.border = "2px solid black";
          dev.style.backgroundColor = "#fbf895";
          console.log("ok");
        } else {
          dev.style.border = "1px solid black";
          dev.style.backgroundColor = "rgb(247, 247, 247)";
          console.log("pas ok");
        }
      }
    },
    Suivant() {
      this.page++;
      this.ChargeListAll(
        this.rechRS,
        this.rechNumC,
        this.rechNumA,
        this.rechNumD,
        this.rechNumF
      );
      this.idcde = 0;
    },
    Precedent() {
      this.page--;
      if (this.page < 1) {
        this.page = 1;
      }
      this.ChargeListAll(
        this.rechRS,
        this.rechNumC,
        this.rechNumA,
        this.rechNumD,
        this.rechNumF
      );
      this.idcde = 0;
    },
    ouvreRecherche() {
      this.affRech =
        this.affRech == true ? (this.affRech = false) : (this.affRech = true);
    },
    lanceRecherche() {
      this.ChargeListAll(
        this.rechRS,
        this.rechNumC,
        this.rechNumA,
        this.rechNumD,
        this.rechNumF
      );
      this.idcde = 0;
    },
    ajoutercde() {
      this.cde_mode = "create";
      this.idcde = 0;
      this.lignesVisible = false;
    },
    annulecde() {
      this.cde_mode = "lst";
    },
    recupClient(id, rs) {
      this.d_client = rs;
      this.d_idClient = id;
    },
    recupEtat(etat) {
      this.d_etat = etat;
      console.log(this.d_etat);
    },
    Enregcde() {
      console.log(this.codeEntreprise);
      this.msgErrCde = "";

      if (this.modeCrateModif == "create") {
        //en mode creation
        axios({
          method: "post",
          url: this.$store.state.server + "/api/Commandes/createCde",
          data: {
            idclient: this.infosDevis[0].idclient,
            nomclient: this.infosDevis[0].nomclient,
            commentaire: this.infosDevis[0].commentaire,
            iddevis: this.idDevCde,
            dateliv: this.dateLiv,
            numcde: this.numCde,
            iduser: this.iduser,
            usercde: this.login,
            lstLigne: this.lstLigneDevis,
            nbligne: this.lstLigneDevis.length,
            codeEntreprise: this.codeEntreprise,
          },
          headers: { authorization: VueCookies.get("jwtAtomGC") },
        })
          .then((reponse) => {
            console.log(reponse);
            const idcde = reponse.data.idcde;
            if (
              confirm(
                "La commande est créée. Voulez vous la consulter maintenant ?"
              ) == true
            ) {
              this.$emit("change-userpage", ["commande", idcde]);
            } else {
              this.$emit("change-userpage", ["devis", this.idDevCde]);
            }
          })
          .catch((erreur) => {
            console.log(erreur);
          });
      } else {
        axios({
          method: "post",
          url: this.$store.state.server + "/api/Commandes/createcde",
          data: {
            id_modif: this.idcde,
            des: this.d_designation,
            ref: this.d_ref,
            idclient: this.d_idClient,
            nomclient: this.d_client,
            iduser: this.iduser,
            usercde: this.login,
            datecde: this.d_date,
            montant: 0,
            commentaire: this.d_commentaire,
            commentaireclient: this.d_commentaireclient,
            etat: this.d_etat,
            delaivalide: this.d_delaiV,
            delairealisation: this.d_delaiR,
            interlocuteur: this.d_interlocuteur,
            codeEntreprise: this.codeEntreprise,
          },
          headers: { authorization: VueCookies.get("jwtAtomGC") },
        })
          .then((reponse) => {
            console.log(reponse);

            if (this.idcde != 0 && this.idcde != undefined) {
              let i = 0;
              for (let dev of this.lstcde) {
                if (dev.id == this.idcde) {
                  this.lstcde[i].designation = this.d_designation;
                  this.lstcde[i].reference = this.d_ref;
                  this.lstcde[i].idclient = this.d_idClient;
                  this.lstcde[i].nomclient = this.d_client;
                  this.lstcde[i].datecde = this.d_date;
                  this.lstcde[i].commentaire = this.d_commentaire;
                  this.lstcde[i].commentaireclient = this.d_commentaireclient;
                  this.lstcde[i].etat = this.d_etat;
                  this.lstcde[i].delaivalide = this.d_delaiV;
                  this.lstcde[i].delai_realisation = this.d_delaiR;
                  this.lstcde[i].interlocuteur = this.d_interlocuteur;
                  return;
                }
                i++;
              }
            }
            this.cde_mode = "lst";
            this.btnVisible = false;
          })
          .catch((erreur) => {
            console.log(erreur);
          });
      }
    },
    async chargecde(id) {
      this.modeCrateModif = "modif";
      for (let dev of this.lstcde) {
        if (dev.id == id) {
          this.idcde = id;
          let Da = new Date();
          //console.log(dev.nomclient, this.dateLoad(dev.datecde));
          this.d_client = dev.nomclient;
          this.d_client_modif = dev.idcleint;
          this.d_idClient = dev.idclient;
          this.c_numcde = dev.numcde;
          this.c_date = this.dateLoad(dev.datecommande);
          this.d_commentaire = dev.commentaire;
          this.f_regle = dev.mntregle;
          this.f_ech = this.dateLoad(dev.dateecheance);
          console.log(
            "idcde: " +
              this.idcde +
              " - regle: " +
              this.f_regle +
              " - des: " +
              this.d_designation
          );
          this.cde_mode = "create";
          //console.log(dev.nomclient, dev.datecde);
        }
      }
    },
    async chargeFacture(id) {
      this.modeCrateModif = "modif";
      console.log("idtemp: " + id);
      for (let dev of this.lstcde) {
        if (dev.id == id) {
          this.idcde = id;
          this.fact_numModif = id;
          let Da = new Date();
          //console.log(dev.nomclient, this.dateLoad(dev.datecde));
          this.factManu_numcde = dev.numcommande;
          this.factManu_date = this.dateLoad(dev.datefacture);
          this.factManu_comment = dev.commentaire;
          console.log("idfact: " + this.idcde);
          this.factManu_txtva = dev.txtva;
          this.factManu_numaffaire = dev.numaffaire;
          this.cde_mode = "create";
          //console.log(dev.nomclient, dev.datecde);
        }
      }
    },
    async createCdeFact() {
      this.modeCrateModif = "create";
      this.d_CdeOuFact = "cde";
      this.d_client = "";
      this.d_client_modif = 0;
      this.d_idClient = 0;
      this.factManu_numcde = "";
      this.factManu_date = this.dateLoad(new Date());
      this.factManu_comment = "";
      this.factManu_numaffaire = 0;
      this.fact_numModif = 0;
      this.cde_mode = "create";
    },
    dateLoad(d) {
      d = this.FormatDate(d);
      //console.log(d);
      d = d.split("T")[0];

      let tabD = d.split("/");

      d = tabD[2] + "-" + tabD[1] + "-" + tabD[0];
      console.log(d);
      return d;
    },
    Affdeletecde(id) {
      this.delComm = id;
    },
    affErreur(msg) {
      this.msgErrCde = msg;
      setTimeout(() => {
        this.msgErrCde = "";
      }, 2500);
    },
    async deletecde(id) {
      let nb = parseInt(
        await FCT.SelUneDonnee(
          "SELECT count(*) as result FROM commandes WHERE idcde = " + id
        )
      );

      if (nb > 0) {
        this.affErreur(
          "Cette commande est passée en facture, suppression impossible"
        );
        return;
      }

      axios({
        method: "post",
        url: this.$store.state.server + "/api/Commandes/deletecde",
        data: { id: id },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },

    ChargeLignecde(id) {
      console.log("charge cde");
      this.idcde = id;
      this.ligneMode = "lst";
      this.lignesVisible = true;
      this.enCharge = true;
      this.surbrillanceListe(id);
      this.modeAff = "cde";
      this.fact_numavoir = 0;
      this.fact_mntavoir = 0;

      for (const cde of this.lstcde) {
        //console.log(cde)
        if (cde.id == id) {
          this.infos_cde = "Lignes de la commande n° " + cde.numcde;
          this.numFacture = cde.numfacture;
          if (this.numFacture == null || this.numFacture == undefined) {
            this.numFacture = 0;
          }
          console.log("numF:" + this.numFacture);
        }
      }

      axios({
        method: "post",
        url: this.$store.state.server + "/api/Commandes/lignecde",
        data: { id: id, codeEntreprise: this.codeEntreprise },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          //console.log(reponse);
          this.lstLignecde = reponse.data;
          this.nbLignes = this.lstLignecde.length;
          console.log("cde ligne: " + this.lstLignecde);
          this.numAffaire = parseInt(reponse.data.numAffaire);

          this.totalHT = 0;
          this.TVA = 0;
          this.totalTTC = 0;

          for (let li of this.lstLignecde) {
            this.totalHT += parseFloat(li.total);
          }

          this.totalHT = this.totalHT.toFixed(2);
          this.TVA = this.totalHT * (this.fact_txtva / 100);
          this.TVA = this.TVA.toFixed(2);
          this.totalTTC = parseFloat(this.totalHT) + parseFloat(this.TVA);
          this.totalTTC = this.totalTTC.toFixed(2);
          this.enCharge = false;
        })
        .catch((erreur) => {
          console.log(erreur);
          this.enCharge = false;
        });
      this.f_regle = 0;
      /* for (let dev of this.lstcde) {
        if (dev.id == id) {
          this.f_regle = dev.mntregle.toFixed(2);
          this.f_ech = this.dateLoad(dev.dateecheance);
          this.f_infos = dev.infosfacture;
          //console.log(dev.nomclient, dev.datecde);
        }
      } */
      this.annuleInfosFacture();
    },
    ChargeLignefacture(id) {
      console.log("charge fact");
      this.idcde = id;
      this.ligneMode = "lst";
      this.lignesVisible = true;
      this.enCharge = true;
      this.surbrillanceListe(id);
      this.modeAff = "fact";

      for (const cde of this.lstcde) {
        let tmpNum = cde.numfacture > 0 ? cde.numfacture : cde.numfac;
        //console.log(cde)
        if (tmpNum == id) {
          this.infos_cde = "Lignes de la facture n° " + id;
          this.numFacture = id;
          this.infosFactValide = cde.definitive;
          this.fact_txtva = cde.txtva;
          if (this.infosFactValide == undefined) this.infosFactValide = "";
          if (this.numFacture == null || this.numFacture == undefined) {
            this.numFacture = 0;
          }
          console.log("numF:" + this.numFacture);
        }
      }

      axios({
        method: "get",
        url: this.$store.state.server + "/api/Factures/lignefacture",
        params: { id: id, codeEntreprise: this.codeEntreprise },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          this.lstLignecde = reponse.data.lst;
          this.nbLignes = this.lstLignecde.length;
          //console.log("cde ligne: " + this.lstLignecde);
          this.numAffaire = parseInt(reponse.data.numAffaire);

          this.totalHT = 0;
          this.TVA = 0;
          this.totalTTC = 0;

          for (let li of this.lstLignecde) {
            this.totalHT += parseFloat(li.total);
          }

          this.totalHT = this.totalHT.toFixed(2);
          this.TVA = this.totalHT * (this.fact_txtva / 100);
          this.TVA = this.TVA.toFixed(2);
          this.totalTTC = parseFloat(this.totalHT) + parseFloat(this.TVA);
          this.totalTTC = this.totalTTC.toFixed(2);
          this.enCharge = false;

          //recup infos avoir
          let infoF = reponse.data.infoFact;
          if (infoF != []) {
            if (infoF[0].numavoir == null) infoF[0].numavoir = 0;
            if (infoF[0].mntavoir == null) infoF[0].mntavoir = 0;
            this.fact_numavoir = infoF[0].numavoir;
            this.fact_mntavoir = infoF[0].mntavoir;
            this.f_regle = 0;
            console.log("reglé: " + infoF[0].mntregle);
            this.f_regle = infoF[0].mntregle.toFixed(2);
            this.f_ech = this.dateLoad(infoF[0].dateecheance);
            this.f_infos = infoF[0].infosfact;
          } else {
            this.fact_numavoir = 0;
            this.fact_mntavoir = 0;
            this.f_regle = 0;
          }
        })
        .catch((erreur) => {
          console.log(erreur);
          this.enCharge = false;
        });

      /* for (let dev of this.lstcde) {
        if (dev.numfac == id) {
          this.f_regle = 0;
          console.log('reglé: ' + dev.mntregle);
          this.f_regle = dev.mntregle.toFixed(2);
          this.f_ech = this.dateLoad(dev.dateecheance);
          this.f_infos = dev.infosfacture;
          //console.log(dev.nomclient, dev.datecde);
        }
      } */
      this.annuleInfosFacture();
    },
    ModifLigne(id) {
      console.log(id);

      if (id != 0 && id != undefined) {
        this.ligne_id = id;

        for (const lig of this.lstLignecde) {
          if (id == lig.id) {
            this.ligne_des = lig.designation;
            this.ligne_ref = lig.reference;
            this.ligne_code = lig.code;
            this.ligne_qte = lig.quantite;
            this.ligne_unite = lig.unite;
            this.ligne_pu = lig.pu;
            this.ligne_total = lig.total;
            this.ligne_commentaire = lig.commentaire;
            this.ligne_dateliv = this.dateLoad(lig.dateliv);
          }
        }
        this.ligneMode = "modif";
        this.ligneAction = "modif";
      }
    },
    AffSupprLigne(id) {
      this.delLigne = id;
    },
    SupprLigne(id) {
      console.log(id);

      let url = "";
      if (this.modeAff == "cde") {
        url = "/api/Commandes/SuppLignecde";
      } else {
        url = "/api/Factures/SuppLignefacture";
      }
      axios({
        method: "post",
        url: this.$store.state.server + url,
        data: { id: id },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          const l = this.lstLignecde.findIndex((li) => li.id == id);
          console.log(l);
          if (l != -1) {
            this.lstLignecde.splice(l, 1);
          }
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    ajouterLignecde() {
      if (this.idcde != 0) {
        this.depuisArt = false;
        this.ligneMode = "modif";
        this.ligneAction = "ajout";
        this.ligne_id = -1;
        this.ligne_des = "";
        this.ligne_ref = "";
        this.ligne_code = "";
        this.ligne_qte = 0;
        this.ligne_unite = "";
        this.ligne_pu = 0;
        this.ligne_total = 0;
        this.ligne_commentaire = "";
      } else {
        this.affErreur(
          "Vous devez sélectionner une commande pour ajouter une ligne"
        );
      }
    },
    AnnuleLigne() {
      this.ligneMode = "lst";
      this.ligneAction = "ajout";
    },
    CalculTotal() {
      let qte = parseFloat(this.ligne_qte);
      let pu = parseFloat(this.ligne_pu);
      let pt = qte * pu;
      pt = pt.toFixed(2);
      this.ligne_total = pt;
    },
    EnregLigne() {
      //let url = ""
      let id = this.ligne_id;
      let da1 = new Date(this.ligne_dateliv);
      da1 = this.FormatDate(da1);
      console.log(da1);
      if (id == 0 || id == undefined || id == -1) {
        //en ajout de ligne
        //url = this.$store.state.server + "/api/cde/AddModifLignecde"
        id = -1;
      } else {
        // en modification de ligne
        //url = this.$store.state.server + "/api/cde/ModifLignecde"
      }
      let url = "";
      if (this.modeAff == "cde") {
        url = "/api/Commandes/AddModifLignecde";
      } else {
        url = "/api/Factures/AddModifLignefacture";
      }

      axios({
        method: "post",
        url: this.$store.state.server + url,
        data: {
          id: id,
          des: this.ligne_des,
          ref: this.ligne_ref,
          code: this.ligne_code,
          qte: this.ligne_qte,
          unite: this.ligne_unite,
          pu: this.ligne_pu,
          total: this.ligne_total,
          commentaire: this.ligne_commentaire,
          idCde: this.idcde,
          login: this.login,
          dateL: da1,
          numaffaire: this.numAffaire,
          iduser: this.iduser,
          numF: this.numFacture,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);

          if (
            reponse.data.reqState == "ligne added OK" ||
            reponse.data.reqState == "ligne modified OK"
          ) {
            if (this.modeAff == "cde") {
              this.ChargeLignecde(this.idcde);
            } else {
              this.ChargeLignefacture(this.numFacture);
            }

            this.ligneMode = "lst";
            this.ligneAction = "ajout";
          }
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    AjArticle(id) {
      if (id != 0 && id != undefined) {
        for (let art of this.lstArticle) {
          if (id == art.id) {
            this.ligne_des = art.designation;
            this.ligne_ref = art.reference;
            this.ligne_code = art.code;
            this.ligne_commentaire = art.commentaire;
            this.ligne_pu = art.pu;
            this.ligne_unite = art.unite;
          }
        }
      }
      this.depuisArt = false;
    },
    ajEtat() {
      let etat = prompt("Saisissez le nouvel état à ajouter dans la liste", "");
      if (etat != "" && etat != undefined) {
        var select = document.getElementById("d_etat");
        select.options[select.options.length] = new Option(etat, etat);
        this.recupEtat(etat);
        this.lstEtat.push(etat);
      }
    },
    async passeEnCommande() {
      let existe = await FCT.verifExisteDansBase(
        ["commandes"],
        "idcde",
        this.idcde,
        false
      );
      console.log(existe);
      let creer = false;
      if (existe == true) {
        if (
          confirm(
            "Ce cde a déjà été passé en commande. Voulez vous créer une nouvelle commande pour ce cde ?"
          ) == true
        ) {
          creer = true;
        }
      } else {
        creer = true;
      }
      if (creer == true) {
        this.$emit("change-userpage", ["devencde", this.idcde]);
      }
    },
    genereFacture() {
      this.numfacture = 0;
      this.enCharge = true;
      this.confirmGenereFacture = false;

      axios({
        method: "post",
        url: this.$store.state.server + "/api/Factures/genereFacture",
        data: {
          id: this.idcde,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          let id = reponse.data.numfacture;
          if (id > 0) {
            setTimeout(() => {
              this.numFacture = id;
            }, 100);
            this.enCharge = false;

            let ind = this.lstcde.findIndex((el) => el.id == this.idcde);
            if (ind != -1) {
              this.lstcde[ind].numfacture = id;
            }
          }
        })
        .catch((erreur) => {
          console.log(erreur);
          this.enCharge = false;
        });
    },
    ModifEnteteFacture() {
      if (this.fact_numModif === 0) {
        this.factManu_valide = true;
        return;
      }

      axios({
        method: "post",
        url: this.$store.state.server + "/api/Factures/modifEnteteFacture",
        data: {
          id: this.fact_numModif,
          codeEntreprise: this.codeEntreprise,
          numCde: this.factManu_numcde,
          numAffaire: this.factManu_numaffaire,
          comment: this.factManu_comment,
          txTva: this.factManu_txtva,
          dateF: this.factManu_date,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          let id = reponse.data.numfacture;
          if (this.fact_numModif > 0) {
            setTimeout(() => {
              this.numFacture = this.fact_numModif;
            }, 100);
            this.enCharge = false;

            let ind = this.lstcde.findIndex(
              (el) => el.id == this.fact_numModif
            );
            if (ind != -1) {
              this.lstcde[ind].numaffaire = this.factManu_numaffaire;
              this.lstcde[ind].numcommande = this.factManu_numcde;
              this.lstcde[ind].commentaire = this.factManu_comment;
              this.lstcde[ind].txtva = this.factManu_txtva;
              this.fact_txtva = this.factManu_txtva;

              //recalcul de la TVA et du TTC
              this.TVA = this.totalHT * (this.factManu_txtva / 100);
              this.TVA = this.TVA.toFixed(2);

              this.totalTTC = parseFloat(this.totalHT) + parseFloat(this.TVA);
              this.totalTTC = this.totalTTC.toFixed(2);
            }
            this.cde_mode = "lst";
          }
        })
        .catch((erreur) => {
          console.log(erreur);
          this.enCharge = false;
        });
    },
    valideFacture() {
      this.enCharge = true;
      if (this.numFacture == 0) return;
      this.confirmeValideFacture = false;
      axios({
        method: "post",
        url: this.$store.state.server + "/api/Factures/valideFacture",
        data: {
          id: this.numFacture,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          this.infosFactValide = reponse.data.definitive;
          this.lstcde.forEach((f) => {
            if (f.numfac == this.numFacture) {
              f.definitive = reponse.data.definitive;
            }
          });
          this.enCharge = false;
        })
        .catch((erreur) => {
          console.log(erreur);
          this.enCharge = false;
        });
    },
    imprimeCde() {
      if (this.idcde == 0) {
        this.affErreur("Vous devez sélectionner une commande à imprimer.");
        return;
      }

      VueCookies.set("atom_imprime_typ", "cde", "20s");
      VueCookies.set("atom_imprime_id", this.idcde, "20s");

      let route = this.$router.resolve({ name: "impression" });
      //let route = this.$router.resolve('/link/to/page'); //This also works.
      window.open(route.href, "_blank");
    },
    imprimeFacture() {
      if (this.numFacture == 0) {
        this.affErreur("Vous devez sélectionner une facture à imprimer.");
        return;
      }
      VueCookies.set("atom_imprime_typ", "facture", "20s");
      VueCookies.set("atom_imprime_id", this.numFacture, "20s");

      let route = this.$router.resolve({ name: "impression" });
      //let route = this.$router.resolve('/link/to/page'); //This also works.
      window.open(route.href, "_blank");
    },
    imprimeAvoir() {
      if (this.fact_numavoir == 0) {
        this.affErreur("Vous devez sélectionner un avoir à imprimer.");
        return;
      }

      VueCookies.set("atom_imprime_typ", "avoir", "20s");
      VueCookies.set("atom_imprime_id", this.fact_numavoir, "20s");

      let route = this.$router.resolve({ name: "impression" });
      //let route = this.$router.resolve('/link/to/page'); //This also works.
      window.open(route.href, "_blank");
    },
    infosFacture() {
      const infoFact = document.getElementById("infosFacture");
      let xFact = infoFact.style.right;

      infoFact.style.left = "200px";
    },
    annuleInfosFacture() {
      const infoFact = document.getElementById("infosFacture");
      if (infoFact) {
        let xFact = infoFact.style.right;
        infoFact.style.left = "-500px";
      }
    },
    deletecde(id) {
      if (id == 0 || id == undefined) {
        return;
      }

      let numeroCde = "";
      let numF = 0;
      for (const cde of this.lstcde) {
        if (cde.id == id) {
          numeroCde = cde.numcde;
          numF = cde.numfacture;
        }
      }

      if (numeroCde == "" || numeroCde == undefined) {
        return;
      }

      if (numF != 0 && numF != "" && numF != undefined) {
        this.affErreur("Cette commande est facturée, suppression impossible");
        return;
      }

      axios({
        method: "delete",
        url: this.$store.state.server + "/api/Commandes/deleteCde",
        data: {
          id: this.idcde,
          codeEntreprise: this.codeEntreprise,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          const l = this.lstcde.findIndex((li) => li.id == id);
          if (l != -1) {
            this.lstcde.splice(l, 1);
          }
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    valideInfosFacture() {
      axios({
        method: "post",
        url: this.$store.state.server + "/api/Commandes/ModifInfosFacture",
        data: {
          id: this.numFacture,
          codeEntreprise: this.codeEntreprise,
          mntregle: this.f_regle,
          dateech: this.f_ech,
          infofacture: this.f_infos,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          let i = 0;
          for (let dev of this.lstcde) {
            if (dev.id == this.idcde) {
              this.lstcde[i].mntregle = this.f_regle;
              this.lstcde[i].dateecheance = this.f_ech;
              this.lstcde[i].infosfacture = this.f_infos;
              return;
            }
            i++;
          }
        })
        .catch((erreur) => {
          console.log(erreur);
        });
      const infoFact = document.getElementById("infosFacture");
      let xFact = infoFact.style.right;
      infoFact.style.left = "-500px";
    },
    affAvoir(mode) {
      // affiche ou masque l'avoir
      let avoir = document.getElementById("avoir");
      if (mode == true) {
        //affiche
        this.mntavoir = 0;
        this.dateavoir = this.dateLoad(new Date());
        this.txtavoir = "";
        this.confirmAvoir = false;
        avoir.style.top = "150px";
        avoir.style.opacity = "1";
      } else {
        avoir.style.top = "-550px";
        avoir.style.opacity = "0";
      }
    },
    genereAvoir() {
      if (parseFloat(this.mntavoir) != 0 && this.txtavoir != "") {
        console.log("numF pour avoir: " + this.numFacture);

        axios({
          method: "post",
          url: this.$store.state.server + "/api/Factures/createAvoir",
          data: {
            id: this.numFacture,
            codeEntreprise: this.codeEntreprise,
            mntavoir: this.mntavoir,
            dateavoir: this.dateavoir,
            txtavoir: this.txtavoir,
          },
          headers: { authorization: VueCookies.get("jwtAtomGC") },
        })
          .then(() => {
            this.ChargeLignefacture(this.numFacture);
          })
          .catch((erreur) => {
            console.log(erreur);
          });
      }
    },
    change_modeListe(typ) {
      this.modeListe = typ == "F" ? "factures" : "commandes";
      this.ChargeListAll("", "", 0, 0, 0);
    },
    CreateFactureManuelle() {
      this.msgErrFact = "";
      console.log("create facture manuelle");
      if (this.d_client == "" || this.d_idClient == 0) {
        this.msgErrFact = "Le client est obligatoire en création de facture";
        return;
      }

      axios({
        method: "post",
        url: this.$store.state.server + "/api/Factures/FactureManuelle",
        data: {
          codeEntreprise: this.codeEntreprise,
          RS: this.d_client,
          idClient: this.d_idClient,
          numCde: this.factManu_numcde,
          numAffaire: this.factManu_numaffaire,
          dateF: this.factManu_date,
          comment: this.factManu_comment,
        },
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then(() => {
          this.modeListe = "factures";
          this.cde_mode = "lst";
          (this.factManu_valide = false), (this.msgErrFact = "");
          this.ChargeListAll("", "", 0, 0, 0);
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
  },
  async mounted() {
    this.codeEntreprise = await VueCookies.get("atom_code_entreprise");
    this.login = VueCookies.get("atom_user_login");
    this.iduser = VueCookies.get("atom_user_id");
    console.log("id:" + this.iduser, "user: " + this.login);
    this.ligneMode = "lst";
    this.ligneAction = "ajout";

    if (this.clientCde != "" && this.clientCde != undefined) {
      this.rechRS = this.clientCde;
    }

    console.log("iddevis: " + this.devisRechId);
    if (this.devisRechId != 0 && this.devisRechId != undefined) {
      this.rechNumD = this.devisRechId;
    }

    this.ChargeListAll(this.rechRS, "", 0, this.rechNumD, 0);
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.cde {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background: url("/assets/fond/fond11.jpg");
  background-size: cover;
  margin: 110px 0 0 0;

  .lst-cde {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: calc(100vh - 118px);
    min-height: calc(100vh - 118px);
    border: gray 1px solid;
    border-radius: 5px;
    margin: 5px 0 0 5px;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.2);

    .lstcde_container {
      width: 98%;

      .cde-liste {
        width: 98%;
        border: lightgray 1px solid;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 2px;

        .cde-card {
          margin: 5px 0 0 0;
          width: 95%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border: lightgray 1px solid;
          /*border-radius: 5px;*/
          padding: 5px;
          background-color: rgb(247, 247, 247);
          transition: 0.2s ease-in;

          &:hover {
            /*background: linear-gradient(azure, rgb(206, 248, 248));*/
            background: rgb(253, 255, 158);
            /* background: linear-gradient(
              40deg,
              rgba(253, 255, 158, 1) 0%,
              rgba(255, 255, 255, 1) 50%,
              rgba(253, 255, 158, 1) 100%
            ); */
            box-shadow: 3px 3px 5px 0px #808080;
            scale: 1.01;
          }

          .cde-titre {
            margin: 5px 0;
            font-size: 1.2em;
            cursor: pointer;
          }

          .cde-card-detail {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 0.8em;

            .d1 {
              margin: 0 5px 0 0;
              flex-basis: 48%;
              padding: 4px;
              overflow: hidden;
              border: lightgray 1px solid;
              border-radius: 2px;
              td {
                padding: 4px;
              }
            }
            .d2 {
              flex-basis: 48%;
              padding: 4px;
              overflow: hidden;
              border: lightgray 1px solid;
              border-radius: 2px;

              td {
                padding: 4px;
              }
            }
            .d3 {
              flex-basis: 33%;
            }
          }

          .cde-detail-commentaire {
            font-size: 0.7em;
            margin: 5px 0;
          }

          .detail_fact {
            font-weight: bold;
            color: white;
            padding: 5px 0 5px 20px;
            background-color: #6f6f6f;
            cursor: pointer;
            transition: 0.2s ease-in;

            &:hover {
              background-color: rgb(138, 249, 253);
              color: #0f0f0f;
            }
          }
        }
      }
    }
  }

  .lignes-cde {
    flex-basis: 69%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: calc(100vh - 110px);
    border: gray 1px solid;
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 5px 5px 0 0;
    position: relative;
    background-color: rgba(0, 0, 0, 0.2);
    min-height: 350px;

    .detail-lignes {
      width: 98%;
      border: lightgray 1px solid;
      margin: 5px 0 5px 0;
      padding: 2px;
      transition: 0.1s ease;
      background-color: rgb(242, 242, 242);

      &:hover {
        /*background: linear-gradient(azure, rgb(206, 248, 248));*/
        background: rgb(253, 255, 158);
        /* background: linear-gradient(
          40deg,
          rgba(253, 255, 158, 1) 0%,
          rgba(255, 255, 255, 1) 50%,
          rgba(253, 255, 158, 1) 100%
        ); */
        box-shadow: 3px 3px 5px 0px #808080;
        scale: 1.01;
      }
    }

    .cde-titre-ligne {
      padding: 5px 15px;
      vertical-align: center;
      font-size: 1.2em;
      font-weight: bold;
      text-shadow: #a0a0a0 1px 1px 1px;
      letter-spacing: 1.2px;
      border: 1px solid #b0b0b0;
      background-color: rgba(255, 255, 255, 0.3);
    }

    .tabTotal {
      border: 1px solid lightgray;
      text-align: center;

      tr {
        border-bottom: 1px solid lightgray;
        background-color: rgb(242, 242, 242);

        td {
          padding: 2px 15px;
        }
      }
    }

    .facture {
      font-size: 1.2em;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .infosFacture {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: absolute;
      top: 75px;
      left: -500px;
      border: black 1px solid;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      padding: 15px 30px;
      z-index: 10;
    }
  }
}

.btn-liste {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 3px 0;
}

.table-liste-ligne {
  width: 98%;
  font-size: 0.8em;
  .entete-tr {
    color: rgb(155, 155, 155);
  }
}

.cde-createmodif {
  width: 95%;
  border: lightgray 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 7px;
  margin: 0 5px 0 0;
  background-color: rgba(37, 37, 37, 0.85);
  color: white;

  input {
    margin: 0 0 10px 0;
  }
  select {
    margin: 0 0 10px 0;
  }
}

.avoir {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  width: 300px;
  left: 50%;
  transform: translateX(-50%);
  top: -550px;
  opacity: 0;
  transition: 0.5s ease-out;
  z-index: 50;

  label {
    color: #f0f0f0;
    margin: 0 0 2px 0;
  }

  input {
    width: 50%;
    text-align: center;
    margin: 0 0 8px 0;
  }

  textarea {
    width: 90%;
    margin: 0 0 8px 0;
  }
}

.detail_avoir {
  background-color: rgb(253, 194, 194);
  border: red solid 1px;
  padding: 5px 10px;
  max-width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 10px;
  table {
    border: 1px solid red;
    margin: 10px 15px 5px 0;
    tr,
    td {
      padding: 3px 20px;
      text-align: center;
    }
  }
}
</style>
